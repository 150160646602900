const manualPostFooterStyle = {
  footerMain_Box: {
    background: '',
    display: 'flex',
    justifyContent: 'end',
    gap: '8px',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    paddingX: '20px',
    mt: 'auto',
  },
  schedule_Text: (theme) => ({
    fontSize: theme.typography.paragraph.fontSize,
    lineHeight: '18px',
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.text.light,
    fontWeight: theme.typography.h5.fontWeight,
  }),
  scheduleDateTIme_Text: (theme) => ({
    fontSize: theme.typography.paragraph.fontSize,
    lineHeight: '18px',
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.text.main,
    fontWeight: theme.typography.h5.fontWeight,
  }),
  scheduleDisplayStyles: (ScheduleTime, selectedOption) => ({
    display: ScheduleTime && selectedOption === 'Schedule' ? 'flex' : 'none',
    gap: '20px',
    background: '',
    alignItems: 'center',
    mr: 'auto',
  }),
  edit_Button: (loading, theme) => ({
    textTransform: 'capitalize',
    boxSizing: 'border-box',
    padding: '9px 12px',
    width: loading ? '64px' : 'fit-content',
    height: '38px',
    fontSize: theme.typography.h5.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h2.fontWeight,
    lineHeight: '20px',
    color: theme.palette.button.dark,
  }),
  loadingBox: (loading, theme) => ({
    display: 'flex',
    gap: '0px',
    height: loading ? '38px' : 'fit-content',
    width: loading ? '112.2px' : 'fit-content',
    background: theme.palette.button.dark,
    borderRadius: '8px',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingRight: '0px',
    justifyContent: loading ? 'center' : 'normal',
  }),

  selectText_Button: (theme) => ({
    display: 'flex',
    textTransform: 'capitalize',
    color: theme.palette.customWhite.main,
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '10px 12px',
    width: 'fit-content',
    height: '38px',
    fontSize: theme.typography.h5.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h2.fontWeight,
    lineHeight: '20px',
  }),
  keyboardArrowDownOutlined: (theme) => ({
    height: '18px',
    width: '18px',
    fontWeight: theme.typography.h1.fontWeight,
    background: '',
    ml: '2px',
    color: theme.palette.customWhite.main,
  }),
}
export default manualPostFooterStyle
export const scheduleEditFooter = {
  parent_Box: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    px: '20px',
    mt: 'auto',
  }),
  editAndSchedule_Box: () => ({
    display: 'flex',
    gap: '20px',
    background: '',
    alignItems: 'center',
    mr: 'auto',
  }),
  schedule_Text: (theme) => ({
    fontSize: theme.typography.paragraph.fontSize,
    lineHeight: '18px',
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.light,
    fontWeight: theme.typography.h5.fontWeight,
  }),
  scheduleDateTIme_Text: (theme) => ({
    fontSize: theme.typography.paragraph.fontSize,
    lineHeight: '18px',
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.main,
    fontWeight: theme.typography.h5.fontWeight,
  }),
  loadingBox: (loading, theme) => ({
    display: 'flex',
    gap: '0px',
    height: loading ? '38px' : 'fit-content',
    width: loading ? '112.2px' : 'fit-content',
    background: theme.palette.button.dark,
    borderRadius: '8px',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingRight: '0px',
    justifyContent: loading ? 'center' : 'normal',
  }),
  edit_Button: (loading, theme) => ({
    textTransform: 'capitalize',
    boxSizing: 'border-box',
    padding: '9px 12px',
    width: loading ? '64px' : 'fit-content',
    height: '38px',
    fontSize: theme.typography.h5.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h2.fontWeight,
    lineHeight: '20px',
    color: theme.palette.button.dark,
  }),
  save_Button_Style: (theme) => ({
    height: '38px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    px: '12px',
    fontSize: theme?.typography.menuItems.fontSize,
    fontWeight: theme?.typography.h3.fontWeight,
    fontFamily: theme?.typography.h2.fontFamily,
    background: theme.palette.button.dark,
    fontStyle: 'normal',
    lineHeight: '20px',
    width: '77px',
    textTransform: 'capitalize',
    color: 'white',
    '&.Mui-disabled': {
      color: 'white', // Text color for disabled state
    },
    ':hover': {
      background: theme.palette.button.dark,
    },
  }),
}
export const schedulePublishFooter = {
  parent_Box: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    px: '20px',
    mt: 'auto',
  }),

  schedule_Text: (theme) => ({
    fontSize: theme.typography.paragraph.fontSize,
    lineHeight: '18px',
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.light,
    fontWeight: theme.typography.h5.fontWeight,
  }),
  scheduleDateTIme_Text: (theme) => ({
    fontSize: theme.typography.paragraph.fontSize,
    lineHeight: '18px',
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.black.main,
    fontWeight: theme.typography.h5.fontWeight,
  }),
  loadingBox: (loading, theme) => ({
    display: 'flex',
    gap: '0px',
    height: loading ? '38px' : 'fit-content',
    width: loading ? '112.2px' : 'fit-content',
    background: theme.palette.button.dark,
    borderRadius: '8px',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingRight: '0px',
    justifyContent: loading ? 'center' : 'normal',
  }),

  publish_Button_Style: (theme) => ({
    height: '38px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    px: '12px',
    fontSize: theme?.typography.menuItems.fontSize,
    fontWeight: theme?.typography.h3.fontWeight,
    fontFamily: theme?.typography.h2.fontFamily,
    background: theme.palette.button.dark,
    fontStyle: 'normal',
    lineHeight: '20px',
    width: '77px',
    textTransform: 'capitalize',
    color: 'white',
    '&.Mui-disabled': {
      color: 'white', // Text color for disabled state
    },
    ':hover': {
      background: theme.palette.button.dark,
    },
  }),
}
export const draftEditFooterStyle = {
  parent_Box: () => ({
    display: 'flex',
    justifyContent: 'end',
    gap: '8px',
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    px: '20px',
    mt: 'auto',
  }),
  loadingBox: (loading, theme) => ({
    display: 'flex',
    gap: '0px',
    height: loading ? '38px' : 'fit-content',
    width: loading ? '112.2px' : 'fit-content',
    background: theme.palette.button.dark,
    borderRadius: '8px',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingRight: '0px',
    justifyContent: loading ? 'center' : 'normal',
  }),

  save_Button_Style: (theme) => ({
    height: '38px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    px: '12px',
    fontSize: theme?.typography.menuItems.fontSize,
    fontWeight: theme?.typography.h3.fontWeight,
    fontFamily: theme?.typography.h2.fontFamily,
    background: theme.palette.button.dark,
    fontStyle: 'normal',
    lineHeight: '20px',
    width: '77px',
    textTransform: 'capitalize',
    color: 'white',
    '&.Mui-disabled': {
      color: 'white', // Text color for disabled state
    },
    ':hover': {
      background: theme.palette.button.dark,
    },
  }),
}

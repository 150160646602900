import React, { useCallback, useContext } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import selectImage from '../../../../../../Assets/Images/image.png'
import { useTheme } from '@emotion/react'
import { useDropzone } from 'react-dropzone'
import { DeleteOutline, EditOutlined } from '@mui/icons-material'
import UserContext from '../../../../../../Context/userContext'

const Thumbnail = ({ thumbnail, setThumbnail, setEditModal, setImageSrc }) => {
  const theme = useTheme()
  const { postData } = useContext(UserContext)
  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log('Accepted Files:', acceptedFiles)
      let file = acceptedFiles[0]
      const objectUrl = URL.createObjectURL(file)
      console.log('objectUrl', objectUrl)
      return setThumbnail({ file, url: objectUrl })
    },
    [setThumbnail]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'image/png': [],
      // 'image/jpeg': [],
    },
  })

  const handleEdit = (thumbnail) => {
    if (thumbnail?.url) {
      setImageSrc(thumbnail.url)
      setEditModal(true)
    }
  }

  const handleDelete = () => {
    setThumbnail(null)
  }
  return (
    <Box
      sx={{
        width: '550px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Typography
          sx={{
            color: theme.palette.text.main,
            fontFamily: theme.typography.h2.fontFamily,
            fontSize: theme.typography.h4.fontSize,
            fontWeight: theme.typography.h4.fontWeight,
            lineHeight: '24px',
          }}
        >
          Thumbnail
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.light,
            fontFamily: theme.typography.h2.fontFamily,
            fontSize: theme.typography.paragraph.fontSize,
            fontWeight: theme.typography.h5.fontWeight,
            lineHeight: '20px',
          }}
        >
          Add your video to one or more playlists to organize your content for
          viewers.{' '}
          <a
            href='https://support.google.com/youtube/answer/2797468?hl=en'
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'underline' }}
          >
            Learn more
          </a>{' '}
        </Typography>
      </Box>

      {/* Dropzone Wrapper */}
      <Box {...getRootProps()} sx={{ width: '130px', cursor: 'pointer' }}>
        <input {...getInputProps()} />
        <label
          htmlFor='upload-input'
          style={{ cursor: 'pointer', background: '', width: '130px' }}
        >
          {thumbnail ? (
            <Box
              sx={{
                width: '130px',
                height: '75px',
                position: 'relative',
                '&:hover .overlay': {
                  opacity: 1,
                },
              }}
            >
              <img
                src={thumbnail.url || postData?.thumbnailUrl}
                alt='Thumbnail Preview'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '4px',
                }}
              />
              <Box
                className='overlay'
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '4px',
                  opacity: 0,
                  transition: 'opacity 0.3s ease',
                  gap: '8px',
                }}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation() // Prevent triggering the dropzone or file input
                    handleEdit(thumbnail)
                  }}
                  sx={{
                    color: '#fff',
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    },
                  }}
                >
                  <EditOutlined sx={{ height: '20px', width: '20px' }} />
                </IconButton>
                <IconButton
                  onClick={handleDelete}
                  sx={{
                    color: '#fff',
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    },
                  }}
                >
                  <DeleteOutline sx={{ height: '20px', width: '20px' }} />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '130px',
                borderRadius: '4px',
                border: '0.41px dashed #858585',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                boxSizing: 'border-box',
                padding: '16px 32px',
              }}
            >
              <img
                src={selectImage}
                alt='select img'
                style={{ width: '35px', height: '20px', cursor: 'pointer' }}
              />
              <Typography
                sx={{
                  color: theme.palette.black.light,
                  fontFamily: theme?.typography.h2.fontFamily,
                  fontSize: theme?.typography.paragraph.fontSize,
                  fontWeight: theme?.typography.h5.fontWeight,
                  lineHeight: '16px',
                  whiteSpace: 'nowrap',
                }}
              >
                Upload File
              </Typography>
            </Box>
          )}
        </label>
      </Box>
    </Box>
  )
}

export default Thumbnail

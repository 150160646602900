export const logOutPopupStyle = {
  item_Box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '',
  },
  mainBox: (theme) => ({
    width: '100%',
    backgroundColor: theme.palette.background.menu,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding:
      'var(--Overlays-Dropdowns-List-Spacing-Y, 0px) var(--Overlays-Dropdowns-List-Spacing-X, 8px) ',
  }),
}
export const popup_MenuStyle = (theme) => ({
  backgroundColor: theme.palette.menu.background,
  ml: '50px',
  padding: '0px',
  boxSizing: 'border-box',
  borderRadius: ' var(--Numbers-Number-10, 16px)',
})
export const main_Box_LogoutPopup = (open, drawer, theme) => ({
  width: '100%',
  backgroundColor: theme.palette.background.menu,
  border: `1px solid ${theme.palette.border.extralight}`,
  display: drawer ? 'flex' : 'none',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '12px',
  borderRadius: ' var(--Numbers-Number-10, 20px)',
  position: 'absolute',
  maxHeight: '168px',
  bottom: '120%',
  right: '0%',
  transition: 'opacity 1s ease',
  opacity: open ? 1 : 0,
  overflow: 'hidden',
})
export const subscriptionBoxStyles = (currentUser, theme) => ({
  width: '100%',
  display:
    currentUser?.Subscription?.planType !== 'basic' && currentUser?.Subscription
      ? 'flex'
      : 'none',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: 'var(--Numbers-Number-6, 12px)',
  color: theme?.palette.text.light,
  gap: '12px',
  borderRadius: 'var(--Numbers-Number-6, 12px)',
  cursor: 'pointer',
  background: '',
  '&:hover': {
    backgroundColor: theme?.palette.menu.menuitems,
    borderRadius: 'var(--Numbers-Number-6, 12px)',
    color: theme?.palette.text.main,
    transition: 'background-color 0.2s ease-in-out',
  },
})
export const upgradePlan_BoxStyles = (currentUser, theme) => ({
  width: '100%',
  display: currentUser?.Subscription ? 'flex' : 'none',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: 'var(--Numbers-Number-6, 12px)',
  gap: '12px',
  color: theme?.palette.text.light,
  borderRadius: 'var(--Numbers-Number-6, 12px)',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme?.palette.menu.menuitems,
    borderRadius: 'var(--Numbers-Number-6, 12px)',
    transition: 'background-color 0.2s ease-in-out',
    color: theme?.palette.text.main,
  },
})
export const signout_BoxStyle = (theme) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: 'var(--Numbers-Number-6, 12px)',
  gap: '12px',
  color: theme?.palette.text.light,
  borderRadius: 'var(--Numbers-Number-6, 12px)',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme?.palette.menu.menuitems,
    borderRadius: 'var(--Numbers-Number-6, 12px)',
    transition: 'background-color 0.2s ease-in-out',
    color: theme?.palette.text.main,
  },
})
export const popupText_Style = (theme) => ({
  lineHeight: '1',
  fontSize: theme?.typography.h4.fontSize,
  fontFamily: theme?.typography.h3.fontFamily,
  fontWeight: theme?.typography.h3.fontWeight,
  display: { xs: 'none', lg: 'flex' },
})

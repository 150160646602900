import { useTheme } from '@emotion/react'
import { Box, Switch, Typography } from '@mui/material'
import React from 'react'

const ToggleButton = ({ generateMediaButton, setGenerateMediaButtton }) => {
  const handleChange = (event) => {
    setGenerateMediaButtton(event.target.checked)
  }
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 'fit-content',
        background: '',
      }}
    >
      <Typography
        sx={{
          fontSize: theme?.typography.h5.fontSize,
          fontWeight: theme?.typography.h1.fontWeight,
          fontFamily: theme?.typography.h2.fontFamily,
          color: theme.palette.text.main,
          fontStyle: 'normal',
          lineHeight: '20px',
        }}
      >
        Generate media
      </Typography>
      <Switch
        checked={generateMediaButton}
        onChange={handleChange}
        sx={{
          '& .MuiSwitch-switchBase': {
            color: '',
            '&.Mui-checked': {
              color: '#000464',
            },
            '&.Mui-checked + .MuiSwitch-track': {
              backgroundColor: '#000464',
            },
          },
        }}
      />
    </Box>
  )
}

export default ToggleButton

import { Box, Typography, LinearProgress } from '@mui/material'
import React from 'react'
import { useTheme } from '@emotion/react'
import { AiIcon, CalenderSvg } from '../PasswordStartAdornment'
import {
  calenderBox,
  creditsTypography,
  linerProgressStyle,
  progressMainBox,
} from './sidebarStyle'
const SidebarlinerProgress = ({ drawer, currentUser }) => {
  const theme = useTheme()

  function formatCount(count) {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(count % 1000000 === 0 ? 0 : 1) + 'M' // For millions
    } else if (count >= 1000) {
      return (count / 1000).toFixed(count % 1000 === 0 ? 0 : 1) + 'K' // For thousands
    } else {
      return count?.toString() // For values less than 1000
    }
  }

  const calculateDaysLeft = (start, end) => {
    const startDateObj = new Date(start)
    const endDateObj = new Date(end)
    const timeDiff = endDateObj - new Date()
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) // Convert milliseconds to days
    return daysLeft > 0 ? daysLeft : 0 // If negative, return 0 days left
  }
  const daysLeft = calculateDaysLeft(
    currentUser?.Subscription?.startDate,
    currentUser?.Subscription?.endDate
  )

  return (
    <Box sx={progressMainBox(drawer, currentUser)}>
      <Box sx={calenderBox(drawer)}>
        <CalenderSvg style={{ color: theme.palette.text.main }} />
        <Typography sx={creditsTypography(drawer, theme)}>
          {daysLeft} Days left
        </Typography>
      </Box>
      <LinearProgress
        variant='determinate'
        value={
          currentUser?.Subscription?.planType === 'basic' &&
          currentUser?.Subscription?.aiPosts > 0
            ? 100
            : currentUser?.Subscription?.planType === 'basic' &&
              currentUser?.Subscription?.aiPosts < 0
            ? 1
            : currentUser?.Subscription?.planType !== 'basic'
            ? (currentUser?.Subscription?.credits?.remaining /
                currentUser?.Subscription?.credits?.total) *
              100
            : null
        }
        // value={ (currentUser?.Subscription.credits.remaining / currentUser?.Subscription.credits.lastPurchasedAmount) * 100}
        sx={linerProgressStyle(drawer)}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          background: '',
        }}
      >
        <Box
          sx={{
            justifyContent: 'start',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            background: '',
            visibility: `${drawer ? 'visible' : 'hidden'}`,
            // display: { xs: 'none', lg: 'flex' },
          }}
        >
          <AiIcon sx={{ display: { xs: 'none', lg: 'flex' } }} />
          <Typography sx={creditsTypography(drawer, theme)}>
            AI Credits
          </Typography>
        </Box>
        {currentUser?.Subscription?.planType === 'basic' ? (
          <Typography sx={creditsTypography(drawer, theme)}>
            {currentUser?.Subscription?.aiPosts > 0
              ? `${currentUser?.Subscription?.aiPosts} Ai Post Remaining`
              : '0 Ai Post Remaining'}
          </Typography>
        ) : (
          <Typography sx={creditsTypography(drawer, theme)}>
            {formatCount(currentUser?.Subscription?.credits?.remaining)}{' '}
            Remaining
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default SidebarlinerProgress

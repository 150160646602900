import { WorkspacesOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React, { useContext } from 'react'
import {
  HomeLogo,
  homeTypography,
  sidebarAllWorkspaces,
  sidebarHome,
} from './sidebarStyle'
import { useTheme } from '@emotion/react'
import UserContext from '../../Context/userContext'

const SideBarAllWorkSpaces = ({ drawer, navigation }) => {
  const { activeSidebarOption, setActiveSidebarOption } =
    useContext(UserContext)
  const theme = useTheme()
  // console.log('url',activeSidebarOption);
  const handleAllWorkspaces = (e) => {
    setActiveSidebarOption(e)
    // window.sessionStorage.setItem('activeSidebarOption','workspaces')
    // setActiveSidebarOption('/dashboard')
    navigation('/dashboard')
  }
  return (
    <Box
      onClick={() => handleAllWorkspaces('/dashboard')}
      sx={sidebarAllWorkspaces(theme, activeSidebarOption)}
    >
      {/* <Box sx={HomeLogo(drawer)}> */}
        <WorkspacesOutlined sx={{ height: '24px', width: '24px' }} />
      {/* </Box> */}
      <Typography sx={homeTypography(drawer, theme, activeSidebarOption)}>
        Workspaces
      </Typography>
    </Box>
  )
}

export default SideBarAllWorkSpaces

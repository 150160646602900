import React, { useContext, useRef, useState } from 'react'
import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  TextField,
  Select,
  Typography,
} from '@mui/material'
import {
  firstnameValidator,
  firstnameResolver,
  lastnameValidator,
  lastnameResolver,
  phoneValidator,
  phoneResolver,
  countryResolver,
  categoryResolver,
  companyValidator,
  companyResolver,
  workplaceDescriptionValidator,
  workplaceDescriptionResolver,
} from '../../../../Validators/authValidator'
import 'firebase/firestore'
import userEditProfileStyle, {
  cancelChangeButton,
  labelOfTextfield,
  saveChangeButton,
  commonSelectStyle,
  commonTextFieldStyle,
  descriptionTextFieldStyle,
  main_Box_Of_companyTextfield,
  main_Box_Of_phoneTextfield,
  disable_GenrateButton,
  userFirstWord_Text,
  parentBox,
} from './userEditProfileStyle'
import { countries } from 'countries-list'
import { useForm } from 'react-hook-form'
import { updateProfile } from 'firebase/auth'
import { Loader } from '@infostacks/ui-components'
import { useNavigate } from 'react-router-dom'
import { uploadImageToFirebase } from '../../../../Firebase/uploadImagesToFirebase'
import UserContext from '../../../../Context/userContext'
import { Modal } from 'antd'
import AvatarEditor from 'react-avatar-editor'
import AddIcon from '@mui/icons-material/Add'
import { AiWhiteIcon } from '../../../PasswordStartAdornment'
import { useTheme } from '@emotion/react'
import { CustomFilterButton } from '../../../ReusableComponents/customButtons'
import UserAiImage from './userAiImage'
import newEditWorkspaceStyle, {
  image,
  image_MainBox,
  label_Style,
} from '../../Workspace/NewEditWorkspaceForm/newEditWorkspaceStyle'

const UserEditProfile = () => {
  const { currentUser, updateUserContext, userAuth } = useContext(UserContext)
  const [selectedValue, setSelectedValue] = useState(
    currentUser?.country || 'Pakistan'
  )
  const [category, setCategory] = useState(
    currentUser?.category || 'Influencer'
  )
  const [selectedImage, setSelectedImage] = useState()
  const [selectedImageURL, setSelectedImageURL] = useState()
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)
  const [openModel, setOpenModel] = useState(false)
  const navigate = useNavigate()
  const editorRef = useRef()
  const fileInputRef = useRef()
  let name = currentUser.fullName.split(' ')
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
  } = useForm({
    defaultValues: {
      firstname: name[0],
      lastname: name[1],
      email: currentUser?.email,
      phoneNumber: currentUser?.phoneNumber,
      company: currentUser?.company,
      country: currentUser?.country,
      category: currentUser?.category,
      description: currentUser?.description,
    },
  })

  const handleCountryChange = (event) => {
    setSelectedValue(event.target.value)
  }
  const imageUpload = (event) => {
    const file = event.target.files[0]
    setSelectedImage(file)
    setSelectedImageURL(URL.createObjectURL(file))
    setEdit(true)
  }

  const handleClose = () => {
    setSelectedImage(null)
    setSelectedImageURL(null)
    setEdit(false)
  }

  const handleSave = async () => {
    const canvas = editorRef.current.getImage().toDataURL()
    const blob = await fetch(canvas).then((res) => res.blob())
    setSelectedImage(blob)
    setSelectedImageURL(canvas)
    setEdit(false)
  }

  const handleModelOpen = () => {
    setOpenModel(true)
  }

  const handleModelClose = () => {
    setOpenModel(false)
  }

  const handleAiImageSelection = (image) => {
    console.log('imageee', image)
    if (image) {
      setEdit(true)
    }
    setSelectedImage(image)
    setSelectedImageURL(image)
  }

  const onSubmit = async (formData) => {
    console.log('formData before', formData)

    setLoading(true)
    const firstname = formData.firstname
    const lastname = formData.lastname
    const username = `${firstname} ${lastname}`
    delete formData.firstname
    delete formData.lastname
    if (
      currentUser?.country === selectedValue &&
      currentUser?.category === category
    ) {
      formData.country = currentUser.country
      formData.category = currentUser.category
    } else {
      formData.country = selectedValue
      formData.category = category
    }
    try {
      let imageUrl = currentUser.imageUrl

      if (selectedImage) {
        imageUrl = await uploadImageToFirebase(
          selectedImage,
          currentUser._id,
          'Profile',
          'User Profile'
        )
        await updateProfile(userAuth, {
          displayName: username,
          photoURL: imageUrl,
        })
      }

      const updatedFormData = {
        ...formData,
        imageUrl,
        email: userAuth.email,
        fullName: username,
      }
      console.log('formData final', updatedFormData)

      if (userAuth && currentUser) {
        const response = await updateUserContext(
          userAuth,
          updatedFormData,
          currentUser._id
        )
        if (response.user.fullName) navigate('/dashboard/userprofile')
      }
    } catch (error) {
      console.error('Error updating profile:', error.message)
    }
    setLoading(false)
  }
  const theme = useTheme()
  const countryList = Object.values(countries)
    .map((country) => country.name)
    .sort((a, b) => a.localeCompare(b))
  return (
    <Box sx={parentBox(theme)}>
      {loading ? (
        <Box sx={userEditProfileStyle.loaderBox}>
          <Loader thickness={6} sx={{ color: 'blue' }} />
        </Box>
      ) : (
        <>
          {currentUser ? (
            <form
              className='editProfileForm'
              onSubmit={handleSubmit(onSubmit)}
              style={userEditProfileStyle.editUser_Form}
            >
              <Box sx={image_MainBox(theme)}>
                <label
                  className='ImageMainBox'
                  style={label_Style(theme, selectedImage)}
                >
                  {selectedImage ? (
                    <img
                      src={selectedImageURL}
                      alt='Selected'
                      style={image(theme)}
                    />
                  ) : currentUser?.imageUrl ? (
                    <img
                      src={currentUser?.imageUrl}
                      alt='currentUser-Img'
                      style={image(theme)}
                    />
                  ) : (
                    <div style={userFirstWord_Text(theme)}>
                      {currentUser?.fullName[0]}
                    </div>
                  )}
                </label>
                <span style={{ display: 'flex', gap: '8px' }}>
                  <CustomFilterButton
                    buttonText={'Edit Logo'}
                    startIcon={<AddIcon />}
                    fontWeight={theme.typography.h4.fontWeight}
                    onClick={() => fileInputRef.current.click()}
                  />
                  <Button
                    disabled={!currentUser?.Subscription}
                    onClick={handleModelOpen}
                    startIcon={<AiWhiteIcon />}
                    sx={disable_GenrateButton(theme)}
                  >
                    Generate Logo
                  </Button>
                </span>
                <Modal
                  open={edit}
                  onCancel={handleClose}
                  onOk={handleSave}
                  okText='Save'
                  style={{ background: 'white', borderRadius: '12px' }}
                  okButtonProps={{
                    style: {
                      background: theme.palette.button.dark,
                    },
                  }}
                >
                  <Box sx={newEditWorkspaceStyle.avatar_Box}>
                    {selectedImage ? (
                      <AvatarEditor
                        ref={editorRef}
                        image={selectedImageURL}
                        width={150}
                        height={150}
                        backgroundColor='red'
                        scale={1}
                        rotate={0}
                        borderRadius={20}
                        border={0}
                      />
                    ) : currentUser?.imageUrl ? (
                      <img
                        src={selectedImageURL}
                        alt='Preview'
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          borderRadius: '14px',
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Box>
                </Modal>
                <input
                  type='file'
                  accept='image/*'
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={imageUpload}
                />
              </Box>
              <Box sx={userEditProfileStyle.main_Box_Of_Textfield}>
                <label
                  className='textfield-label'
                  style={labelOfTextfield(theme)}
                >
                  First Name
                </label>
                <div style={{ position: 'relative' }}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    autoComplete='off'
                    {...register('firstname', firstnameValidator)}
                    sx={commonTextFieldStyle(errors.firstname, theme)}
                  />
                  <FormHelperText sx={userEditProfileStyle.helperText}>
                    {errors.firstname && touchedFields.firstname
                      ? firstnameResolver[errors.firstname.type]
                      : ''}
                  </FormHelperText>
                </div>
              </Box>
              <Box sx={userEditProfileStyle.main_Box_Of_Textfield}>
                <label
                  className='textfield-label'
                  style={labelOfTextfield(theme)}
                >
                  Last Name
                </label>
                <div style={{ position: 'relative' }}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    autoComplete='off'
                    sx={commonTextFieldStyle(errors.lastname, theme)}
                    {...register('lastname', lastnameValidator)}
                  />
                  <FormHelperText sx={userEditProfileStyle.helperText}>
                    {errors.lastname && touchedFields.lastname
                      ? lastnameResolver[errors.lastname.type]
                      : ''}
                  </FormHelperText>
                </div>
              </Box>
              <Box sx={userEditProfileStyle.email_Box}>
                <label
                  className='textfield-label'
                  style={labelOfTextfield(theme)}
                >
                  Email
                </label>
                <div style={{ position: 'relative' }}>
                  <Typography
                    sx={{
                      borderRadius: '14px',
                      background: theme.palette.background.light,
                      fontFamily: theme?.typography?.h5?.fontFamily,
                      display: 'flex',
                      width: '100%',
                      boxSizing: 'border-box',
                      padding: '10px 15px',
                      border: `1px solid ${theme?.palette.border.extralight}`,
                      color: theme?.palette.text.light,
                    }}
                  >
                    {currentUser?.email}
                  </Typography>
                  {/* <TextField
                    fullWidth
                    disabled
                    autoComplete='off'
                    variant='outlined'
                    {...register('email')}
                    sx={commonTextFieldStyle(errors.email, theme)}
                    InputProps={{
                      style: {
                        color: 'red',
                      },
                    }}
                  /> */}
                </div>
              </Box>
              <Box sx={main_Box_Of_phoneTextfield(currentUser)}>
                <label
                  className='textfield-label'
                  style={labelOfTextfield(theme)}
                >
                  Phone Number
                </label>
                <div style={{ position: 'relative' }}>
                  <TextField
                    {...register('phoneNumber', phoneValidator)}
                    sx={commonTextFieldStyle(errors.phoneNumber, theme)}
                    fullWidth
                    type='phone'
                    autoComplete='off'
                    variant='outlined'
                    placeholder='Enter Number'
                  />
                  <FormHelperText sx={userEditProfileStyle.helperText}>
                    {errors.phoneNumber && touchedFields.phoneNumber
                      ? phoneResolver[errors.phoneNumber?.type]
                      : ''}
                  </FormHelperText>
                </div>
              </Box>
              <Box sx={main_Box_Of_companyTextfield(currentUser)}>
                <label
                  className='textfield-label'
                  style={labelOfTextfield(theme)}
                >
                  Company
                </label>
                <div style={{ position: 'relative' }}>
                  <TextField
                    sx={commonTextFieldStyle(errors.company, theme)}
                    fullWidth
                    variant='outlined'
                    autoComplete='off'
                    {...register('company', companyValidator)}
                  />
                  <FormHelperText sx={userEditProfileStyle.helperText}>
                    {errors.company && touchedFields.company
                      ? companyResolver[errors.company?.type]
                      : ''}
                  </FormHelperText>
                </div>
              </Box>

              <Box sx={userEditProfileStyle.main_Box_Of_Textfield}>
                <label
                  className='textfield-label'
                  style={labelOfTextfield(theme)}
                >
                  Country
                </label>
                <Box sx={{ position: 'relative' }}>
                  <Select
                    sx={commonSelectStyle(errors.country, theme)}
                    fullWidth
                    value={selectedValue}
                    onChange={handleCountryChange}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          background: theme.palette.background.light,
                          borderRadius: '16px',
                          borderColor: theme?.palette.border.extralight,
                          width: '350px',
                          height: '400px',

                          '& .MuiMenuItem-root': {
                            background: theme.palette.background.light,
                            color: theme.palette.text.main,
                            fontFamily: theme?.typography?.h5?.fontFamily,

                            '&:hover': {
                              background: theme.palette.background.table,
                            },
                          },
                        },
                      },
                    }}
                  >
                    {countryList.map((country, index) => (
                      <MenuItem key={index} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value={'Pakistan'}>Pakistan</MenuItem>
                    <MenuItem value={'USA'}>USA</MenuItem>
                    <MenuItem value={'Canada'}>Canada</MenuItem>
                    <MenuItem value={'France'}>France</MenuItem> */}
                  </Select>
                  <FormHelperText sx={userEditProfileStyle.helperText}>
                    {errors.country && touchedFields.country
                      ? countryResolver[errors.country?.type]
                      : ''}
                  </FormHelperText>
                </Box>
              </Box>
              <Box sx={userEditProfileStyle.main_Box_Of_Textfield}>
                <label
                  className='textfield-label'
                  style={labelOfTextfield(theme)}
                >
                  User Category
                </label>
                <Box sx={{ position: 'relative', bgcolor: '' }}>
                  <Select
                    sx={commonSelectStyle(errors.country, theme)}
                    fullWidth
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          background: theme.palette.background.light,
                          borderRadius: '16px',
                          borderColor: theme?.palette.border.extralight,

                          '& .MuiMenuItem-root': {
                            background: theme.palette.background.light,
                            color: theme.palette.text.main,
                            fontFamily: theme?.typography?.h5?.fontFamily,

                            '&:hover': {
                              background: theme.palette.background.table,
                            },
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={'Influencer'}>Influencer</MenuItem>
                    <MenuItem value={'Blogger'}>Blogger</MenuItem>
                    <MenuItem value={'Fashionista'}>Fashionista</MenuItem>
                    <MenuItem value={'Fitness Enthusiast'}>
                      Fitness Enthusiast
                    </MenuItem>
                    <MenuItem value={'Travelista'}>Travelista</MenuItem>
                  </Select>
                  <FormHelperText sx={userEditProfileStyle.helperText}>
                    {errors.category && touchedFields.category
                      ? categoryResolver[errors.category?.type]
                      : ''}
                  </FormHelperText>
                </Box>
              </Box>
              <Box sx={userEditProfileStyle.email_Box}>
                <label
                  className='textfield-label'
                  style={labelOfTextfield(theme)}
                >
                  Description
                </label>
                <div style={{ position: 'relative' }}>
                  <TextField
                    multiline
                    fullWidth
                    placeholder='Write Your description here'
                    autoComplete='off'
                    variant='outlined'
                    {...register('description', workplaceDescriptionValidator)}
                    sx={descriptionTextFieldStyle(errors.description, theme)}
                    InputProps={{
                      rows: 7,
                      multiline: true,
                      inputComponent: 'textarea',
                    }}
                  />
                  <FormHelperText sx={userEditProfileStyle.helperText}>
                    {errors.description && touchedFields.description
                      ? workplaceDescriptionResolver[errors.description?.type]
                      : ''}
                  </FormHelperText>
                </div>
              </Box>
              <Box sx={userEditProfileStyle.button_Box}>
                <Button
                  onClick={() => navigate('/dashboard/userprofile')}
                  variant='outlined'
                  style={cancelChangeButton(theme)}
                >
                  Cancel Changes
                </Button>
                <Button
                  variant='outlined'
                  type='submit'
                  style={saveChangeButton(theme)}
                >
                  Save Changes
                </Button>
              </Box>
            </form>
          ) : (
            <Loader thickness={6} sx={{ color: 'blue' }} />
          )}
          {openModel && (
            <UserAiImage
              open={openModel}
              onClose={handleModelClose}
              selectedImage={handleAiImageSelection}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default UserEditProfile

import React, { useContext, useState } from 'react'
import { CustomButton } from '../../../../ReusableComponents/customButtons'

import {
  Box,
  TextField,
  Button,
  InputAdornment,
  Typography,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import youtubeStyle from '../youtubeStyle'
import UserContext from '../../../../../Context/userContext'

const YoutubePostHeader = ({ setYoutubeModal }) => {
  const { setPostData } = useContext(UserContext)
  const theme = useTheme()
 
  const handleUpload = ()=>{
    setPostData(null)
    setYoutubeModal(true)
  }
  return (
    <Box sx={youtubeStyle.ytPostHeaderParentBox(theme)}>
      <Typography sx={youtubeStyle.ytPostHeaderTypography(theme)}>
        Youtube
      </Typography>
      <Box sx={youtubeStyle.ytPostHeaderFilterParentBox(theme)}>
        <TextField
          variant='outlined'
          placeholder='Search posts'
          size='small'
          sx={youtubeStyle.ytPostHeaderFilterTextField(theme)}
          InputProps={{
            style: {
              marginRight: '-15px',
            },
            endAdornment: (
              <InputAdornment position='end'>
                <Button
                  variant='contained'
                  sx={{
                    height: '36px',
                    background: theme.palette.button.dark,
                    borderRadius:
                      'var(--Basic-Forms-Input-Border-Radius, 0px) var(--Numbers-Number, 8px) var(--Number, 8px) var(--Basic-Forms-Input-Border-Radius, 0px)',
                    boxShadow: 'none',
                    fontSize: {
                      xs: theme.typography.paragraph.fontSize,
                      sm: theme.typography.h5.fontSize,
                    },
                    fontWeight: theme?.typography.h2.fontWeight,
                    fontFamily: theme?.typography.h4.fontFamily,
                    textTransform: 'none',
                    '&:hover': { background: theme.palette.button.dark },
                  }}
                >
                  Search
                </Button>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: 'none' }}>
          <CustomButton buttonText={'Filters'} />
        </Box>
        <CustomButton
          buttonText={'Upload Video'}
          onClick={() => handleUpload()}
        />
      </Box>
    </Box>
  )
}

export default YoutubePostHeader

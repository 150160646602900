import { styled, Switch, Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../../../Context/userContext'
import ThemeContext from '../../ThemeProvider/themeContext'

const MaterialUISwitch = styled(Switch)(() => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#241BFF',
      },
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#fff',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><circle cx="10" cy="10" r="4.86" fill="${encodeURIComponent(
        'gold'
      )}"/><path fill="${encodeURIComponent(
        'gold'
      )}" d="M9.305 1.2V3.75h1.389V1.2h-1.39zm-4.707 1.5l-1.2 1.2L5.09 6.072l1.2-1.2-1.692-1.692zm10.802 0L13.927 5.09l1.2 1.2 1.692-1.692-1.2-1.2zM1.2 9.305v1.39h2.083v-1.39H1.2zm15.517 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.2 15.8l1.2 1.2 1.692-1.692-1.2-1.2zm9.82 0l-1.2 1.2 1.692 1.692 1.2-1.2-1.692-1.692zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#DADAFE',
    borderRadius: 20 / 2,
  },
}))

const ColorMode = () => {
  const { currentUser, updateUserContext, userAuth } = useContext(UserContext)
  const { isDarkMode, setIsDarkMode } = useContext(ThemeContext)

  const handleSwitchChange = () => {
    setIsDarkMode(!isDarkMode)
  }

  useEffect(() => {
    const fetch = async () => {
      if (userAuth) {
        const res = await updateUserContext(
          userAuth,
          { darkMode: isDarkMode },
          currentUser?._id
        )
      }
    }
    fetch()
  }, [isDarkMode])

  return <MaterialUISwitch checked={isDarkMode} onChange={handleSwitchChange} />
}

export default ColorMode

import React, { useContext, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import UserContext from '../../../../../../Context/userContext'

const MediaSection = ({ file, setMedia, loading }) => {
  const theme = useTheme()
  const { postData } = useContext(UserContext)
  const getEmbedUrl = (url) => {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/
    )
    return match ? `https://www.youtube.com/embed/${match[1]}` : null
  }

  const isVideo = file?.type?.startsWith('video') || false
  const embedUrl = postData?.videoUrl ? getEmbedUrl(postData?.videoUrl) : null

  useEffect(() => {
    if (file && (file instanceof Blob || file instanceof File)) {
      const objectUrl = URL.createObjectURL(file)
      setMedia([{ file, url: objectUrl }])
      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [file, setMedia])
  return (
    <Box
      sx={{
        position: 'relative',
        width: '360px',
        height: '319px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        background: theme.palette.background.light,
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    >
      {embedUrl ? (
        <Box
          component='iframe'
          src={embedUrl}
          allow='autoplay; encrypted-media'
          title='YouTube Video'
          sx={{
            width: '100%',
            height: postData ? '100%' : '250px',
            border: 'none',
            borderRadius: '10px',
          }}
        />
      ) : file && isVideo ? (
        <Box
          component='video'
          controls
          sx={{
            width: '100%',
            height: '250px',
            objectFit: 'cover',
            borderRadius: '10px',
          }}
        >
          <source src={URL.createObjectURL(file)} type={file.type} />
          Your browser does not support the video tag.
        </Box>
      ) : (
        <Typography>No video available</Typography>
      )}

      {/* File name or postData title */}
      <Box
        sx={{
          display: postData ? 'none' : 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: '8px',
          background: '#F8F8F8',
          boxSizing: 'border-box',
          padding: '4px',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.border.dark,
            fontFamily: theme.typography.h2.fontFamily,
            fontSize: theme.typography.paragraph.fontSize,
            fontWeight: theme.typography.h5.fontWeight,
            fontStyle: 'normal',
            lineHeight: '18px',
          }}
        >
          File Name
        </Typography>
        <Typography
          sx={{
            color: theme.palette.black.light,
            fontFamily: theme.typography.h2.fontFamily,
            fontSize: theme.typography.paragraph.fontSize,
            fontWeight: theme.typography.h5.fontWeight,
            fontStyle: 'normal',
            lineHeight: '18px',
          }}
        >
          {file?.name}
        </Typography>
      </Box>
    </Box>
  )
}

export default MediaSection

const activeSectionStyle = {
  button_main_Box: {
    display: 'flex',
    justifyContent: { xs: 'space-between', sm: '' },
    gap: '8px',
    background: '',
  },
  menu_Style: {
    display: 'flex',
    borderRadius: '12px',
    backgroundColor: '#F8F9FF',
    paddingY: '6px',
    paddingX: '10px',
    boxSizing: 'border-box',
    boxShadow:
      '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
  },
  unarchive_Space_Text: {
    borderRadius: '4px',
    padding: '6px',
    boxSizing: 'border-box',
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#DADAFF' },
  },
  text_Style: {
    background: '',
    color: '#000',
    fontWeight: '600',
    fontSize: { xs: '12px', sm: '14px' },
  },
  textGroup_Style: {
    background: '',
    color: '#000',
    fontWeight: '600',
    fontSize: { xs: '12px', sm: '14px' },
  },
  MapContainerStyle: (theme) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    background: theme.palette.background.default,
    boxSizing: 'border-box',
  }),

  img_Box: (theme) => ({
    display: 'flex',
    background: '',
    alignItems: 'center',
    gap: '10px',
  }),

  cardFooter: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  pagination_Box: {
    width: '96%',
    background: '',
    display: 'flex',
    justifyContent: 'center',
  },
  pagination_Style: {
    '& .MuiPaginationItem-root': {
      color: 'blue',
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#DADAFF',
    },
    '& .MuiPaginationItem-page:hover': {
      backgroundColor: '#DADAFF',
    },
  },
}
export default activeSectionStyle
export const main_Container = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  background: theme.palette.background.default,
  padding: '20px',
  boxSizing: 'border-box',
  height: '100%',
})
export const second_Box = (theme) => ({
  background: theme.palette.background.default,

  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
})
export const filterBox = (theme) => ({
  display: {
    xs: 'flex',
  },
  justifyContent: 'space-between',
  background: theme.palette.background.default,
  boxSizing: 'border-box',
  width: {
    xs: '100%',
  },
  gap: '10px',
})
export const card_Main = (theme) => ({
  width: {
    xs: 'auto',
    sm: '360px',
  },
  display: 'flex',
  flexDirection: 'column',
  padding: ' var(--Numbers-Number-9, 18px) 24px',
  gap: { xs: '16px' },
  borderRadius: '16px',
  border: `1px solid ${theme.palette.sky.light}`,
  backgroundColor: theme?.palette?.gray.main,
  boxSizing: 'border-box',
  boxShadow:
    '0px 0px 1px 0px rgba(82, 82, 137, 0.08), 0px 2px 2px 0px rgba(82, 82, 137, 0.07), 0px 4px 2px 0px rgba(82, 82, 137, 0.04), 0px 7px 3px 0px rgba(82, 82, 137, 0.01), 0px 11px 3px 0px rgba(82, 82, 137, 0.00)',
})
export const name = (theme) => ({
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  color: theme?.palette.black.main,
  lineHeight: '27px',
  fontStyle: 'normal',
})
export const description = (theme) => ({
  width: '100%',
  height: '70px',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  background: '',
  alignItems: 'center',
  gap: 'var(--Components-Badge-Placeholder-Gap-Between, 7px)',
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  fontSize: theme.typography.h4.fontSize,
  color: theme?.palette.black.light,
  lineHeight: '24px',
  fontStyle: 'normal',
})
export const footerText = (theme) => ({
  fontFamily: theme?.typography.h4.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  fontSize: theme.typography.h4.fontSize,
  color: theme?.palette.black.light,
  lineHeight: '24px',
  fontStyle: 'normal',
})
export const addPost_Button_Style = (
  theme,
  postedAndScheduledCount,
  currentUser
) => ({
  lineHeight: '20px',
  boxSizing: 'border-box',
  textTransform: 'capitalize',
  width: 'fit-content',
  borderRadius: ' var(--Numbers-Number-2, 4px)',
  padding:
    ' var(--Components-Button-Spacing-Y-SM, 10px) var(--Components-Button-Spacing-X-SM, 12px)',
  fontWeight: theme?.typography.h2.fontWeight,
  fontSize: theme?.typography.h5.fontSize,
  background: theme.palette.button.dark,
  color: 'white',
  ':hover': { backgroundColor: theme.palette.button.dark },
})
export const custom_Button = (activeButton, theme) => ({
  backgroundColor: activeButton ? theme.palette.button.dark : '',
  textTransform: 'capitalize',
  color: theme?.palette.text.main,

  '&.Mui-disabled': {
    color: theme?.palette.text.main,
  },
  display: 'flex',
  boxSizing: 'border-box',
  paddingX: { xs: '4px', sm: '10px' },
  paddingY: { xs: '4px', sm: '4px' },
  gap: { xs: '0px', sm: '10px' },
  alignItems: 'center',
  borderRadius: '8px',
  '&:hover': { backgroundColor: theme.palette.button.dark },
})
export const text_Style = (theme) => ({
  fontWeight: theme?.typography.h2.fontWeight,
  color: theme?.palette.customWhite.main,
  fontFamily: theme?.typography.h4.fontFamily,
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
})
export const icon_Style = (theme) => ({
  color: theme?.palette.customWhite.main,
  fontSize: 'large',
  display: { xs: 'none', sm: 'flex' },
  height: '20px',
  widh: '20px',
})
export const icon_Group_Style = (theme) => ({
  color: theme?.palette.text.light,
  display: { xs: 'none', sm: 'flex' },
  height: '20px',
  widh: '20px',
})

export const postViewModalStyle = {
  modal_Style: (theme) => ({
    width: '100%',
    height: '50px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    background: theme.palette.background.light,
  }),

  date_Text: (theme) => ({
    fontStyle: 'normal',
    lineHeight: '24px',
    display: 'flex',
    gap: '4px',
    color: theme.palette.text.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
  }),

  header_Text: (theme) => ({
    fontStyle: 'normal',
    lineHeight: '24px',
    textDecoration: 'underline',
    color: theme.palette.text.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
  }),
  main_Box: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0px',
    background: '',
  },
  image_Box: (theme) => ({
    width: '100%',
    padding: '12px',
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    gap: '12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.sky.light}`,
    borderRadius: '16px',
  }),
  desc_TextStyle: (theme) => ({
    color: theme.palette.text.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    width: '100%',
    lineHeight: '20px',
    textAlign: 'start',
  }),
  swiper_Style: {
    width: '100%',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '',
  },
  swiperSlide_Style: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  backgroundImage_Box: {
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  footer_Box: {
    boxSizing: 'border-box',
    paddingX: '4px',
    paddingY: '6px',
    width: '100%',
    display: 'flex',
    gap: '20px',
  },
  customBox: {
    display: 'flex',
    gap: '3px',
  },
  reactionCount_Style: (theme) => ({
    color: theme.palette.text.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h1.fontWeight,
    lineHeight: '20px',
    textAlign: 'center',
  }),
  text_Style: (theme) => ({
    color: theme.palette.text.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    lineHeight: '20px',
    textAlign: 'center',
  }),
}
export const tableDialogStyle = {
  dialog_Style: (theme) => ({
    borderRadius: '16px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    background: theme.palette.background.light,
    border: `1px solid ${theme.palette.border.extralight}`,
    padding: {
      sm: 'var(--Components-Static-Icons-Icon-Height-LG, 20px) 50px',
    },
  }),
  header_Text: (theme) => ({
    fontStyle: 'normal',
    lineHeight: '24px',
    color: theme.palette.text.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
  }),
  dialogAction: { padding: '0px', gap: '10px', mt: '20px' },
  text_Style: (theme) => ({
    color: theme.palette.text.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    lineHeight: '20px',
    textAlign: 'center',
  }),
  cancelButton: (theme) => ({
    fontFamily: theme?.typography.h4.fontFamily,
    fontWeight: theme?.typography.h4.fontWeight,
    fontSize: theme.typography.h5.fontSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '4px 12px',
    textTransform: 'capitalize',
    background: theme.palette.button.dark,
    color: theme?.palette.customWhite.main,
    borderRadius: 'var(--Components-Button-Border-Radius, 8px)',
    '&:hover': {
      backgroundColor: theme.palette.button.dark,
      color: theme?.palette.customWhite.main,
    },
  }),
  deleteButton: (theme) => ({
    fontFamily: theme?.typography.h4.fontFamily,
    fontWeight: theme?.typography.h5.fontWeight,
    fontSize: theme.typography.h5.fontSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '4px 12px',
    textTransform: 'capitalize',
    background: theme.palette.button.dark,
    color: theme?.palette.customWhite.main,
    borderRadius: 'var(--Components-Button-Border-Radius, 8px)',
    '&:hover': {
      backgroundColor: theme.palette.button.dark,
      color: theme?.palette.customWhite.main,
    },
  }),
}

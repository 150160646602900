import { background } from '../../NewLandingPage/src/assets'

const archivedSpacesStyle = {
  parentDiv: (theme) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px',
    background: theme.palette.background.default,
    boxSizing: 'border-box',
    padding: '20px',
    overflowY: 'hidden',
  }),
  archivedSpaceHeader: {
    display: { xs: '', sm: 'flex' },
    justifyContent: 'space-between',
    background: '',
    boxSizing: 'border-box',
    width: { xs: '100%', sm: '99%' },
    height: '36px',
    alignItems: 'center',
  },
  space_Main_Box: (theme) => ({
    width: { xs: 'auto', sm: '320px', md: '358px', lg: '358px', xl: '358px' },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingY: '16px',
    gap: '10px',
    borderRadius: '16px',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.sky.light}`,
    background: theme.palette.background.light,
    boxShadow:
      '0px 0px 1px 0px rgba(82, 82, 137, 0.08), 0px 2px 2px 0px rgba(82, 82, 137, 0.07), 0px 4px 2px 0px rgba(82, 82, 137, 0.04), 0px 7px 3px 0px rgba(82, 82, 137, 0.01), 0px 11px 3px 0px rgba(82, 82, 137, 0.00)',
  }),

  first_section_of_space_main_box: {
    display: 'flex',
    background: '',
    width: '90%',
  },
  spaceImageStyle: {
    height: '48px',
    width: '48px',
    borderRadius: '32px',
    objectFit: 'cover',
  },
  space_detail_box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ml: '16px',
    background: '',
  },

  space_name: (theme) => ({
    background: '',
    fontSize: '20px',
    fontWeight: '600',
    fontFamily: theme?.typography?.h2?.fontFamily,
    color: theme?.palette?.text?.main,
    lineHeight: '1',
  }),
  space_website_and_category: (theme) => ({
    background: '',
    fontSize: '13px',
    fontFamily: theme?.typography?.h2?.fontFamily,
    color: theme?.palette?.text?.main,
  }),
  space_description: (theme) => ({
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    background: '',
    width: '90%',
    height: '55px',
    lineHeight: '1.3',
    fontSize: theme?.typography?.h5?.fontSize,
    fontFamily: theme?.typography?.h2?.fontFamily,
    color: theme?.palette?.text?.main,
  }),
  avtarBox: {
    width: '90%',
    display: 'flex',
    background: '',
    justifyContent: 'start',
  },
  avtarGroup: {
    display: 'flex',
    background: '',
  },
  avtarStyle: {
    width: 30,
    height: 30,
    background: '',
  },
  iconButtonStyle: {
    height: '30px',
    width: '30px',
    mt: '2px',
    borderRadius: '50%',
    backgroundColor: 'darkgray',
    '&:hover': { backgroundColor: 'darkgray' },
  },
  date_and_seeMore_box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '',
    width: '90%',
  },

  space_seeMore_button: { height: '20px', width: '25px' },

  workspace_date: (theme) => ({
    fontSize: theme?.typography?.menuItems?.fontSize,
    fontWeight: theme?.typography?.menuItems?.fontWeight,
    fontFamily: theme?.typography?.h2?.fontFamily,
    color: theme?.palette?.text?.main,
  }),
  pagination_Box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '',
  },
}
export default archivedSpacesStyle

export const space_favourite_button = (val, theme) => ({
  background: '',
  height: '25px',
  width: '25px',
  ml: 'auto',
  color: `${val.isFavourite === true ? 'gold' : theme?.palette.border.star}`,
})
export const menuItem_Edit_UnArchive = (theme) => ({
  fontSize: theme.typography.menuItems.fontSize,
  fontWeight: theme?.typography.menuItems.fontWeight,
  color: theme?.palette.black.main,
  fontFamily: theme?.typography.menuItems.fontFamily,
  '&:hover': {
    backgroundColor: theme?.palette?.gray.hover,
    borderRadius: 'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)',
  },
})
export const archivedSpaceHeaderText = (theme) => ({
  fontSize: {
    xs: theme.typography.h3.fontSize,
    sm: theme.typography.large.fontSize,
  },
  fontFamily: theme?.typography.menualText.fontFamily,
  fontWeight: theme?.typography.menualText.fontWeight,
  color: theme?.palette.text.main,
  lineHeight: 'normal',
  background: '',
  height: 'fit-content',
  letterSpacing: ' -1.2px',
})
export const style_Pagination = (theme) => ({
  '& .MuiPaginationItem-root': {
    color: theme.plaette.black.hover,
    border: `1px solid ${theme.plaette.gray.light}`,
    borderRadius: '999px',
    width: '38px',
    height: '38px',
  },
  '& .MuiPaginationItem-page.Mui-selected': {
    backgroundColor: theme.plaette.gray.light,
    border: `1px solid ${theme.plaette.gray.light}`,
  },
  '& .MuiPaginationItem-page:hover': {
    backgroundColor: theme.plaette.customWhite.main,
  },
  '& .MuiPaginationItem-ellipsis': {
    color: theme.plaette.black.hover,
    textAlign: 'center',
    fontFamily: theme?.typography.menualText.fontFamily,
    fontWeight: theme?.typography.h4.fontWeight,
    fontSize: theme.typography.h4.fontSize,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.08px',
  },
  '& .MuiPaginationItem-icon': {
    color: theme.plaette.black.hover,
  },
})

const youtubePostModelStyle = {
  parent_Box: (media, theme) => ({
    width: '928px',
    maxHeight: media ? 'auto' : '748px',
    borderRadius: '20px',
    background: theme.palette.background.light,
  }),
  mainBox: (media) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '20px',
    boxSizing: 'border-box',
    gap: media ? '16px' : '165px',
    padding: '16px 24px',
    width: '100%',
  }),
  Box_Header: (theme) => ({
    display: 'flex',
    width: '100%',
    height: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '700px',
  }),
  uploadVideos_Text: (theme) => ({
    color: theme.palette.text.main,
    fontFamily: theme?.typography.h2.fontFamily,
    fontSize: theme?.typography.mediumText.fontSize,
    fontWeight: theme?.typography.h2.fontWeight,
    lineHeight: '30px',
    width: '100%',
  }),
  Box_Center: {
    display: 'flex',
    width: '343px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
  },
  img_Style: {
    width: '160px',
    height: '160px',
  },
  middle_Box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    gap: '8px',
  },
  dragAndDrop_Text: (theme) => ({
    color: theme.palette.text.main,
    textAlign: 'center',
    fontFamily: theme?.typography.h2.fontFamily,
    fontSize: theme?.typography.h4.fontSize,
    fontWeight: theme?.typography.h4.fontWeight,
    lineHeight: '24px',
  }),
  text_Style: (theme) => ({
    alignSelf: 'stretch',
    color: theme.palette.text.light,
    fontFamily: theme?.typography.h2.fontFamily,
    fontSize: theme?.typography.h5.fontSize,
    fontWeight: theme?.typography.h5.fontWeight,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
  }),
  button_Box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
  },
  genrate_Button: (theme) => ({
    height: '38px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '11px var(--Numbers-Number-7, 14px)',
    fontSize: theme?.typography.h5.fontSize,
    fontWeight: theme?.typography.h3.fontWeight,
    fontFamily: theme?.typography.h2.fontFamily,
    background: theme.palette.primary.aiColor,
    fontStyle: 'normal',
    lineHeight: '20px',
    width: 'fit-content',
    textTransform: 'capitalize',
    color: theme.palette.customWhite.main,
    '&.Mui-disabled': {
      background: theme.palette.button.disable,
      color: theme.palette.text.disable,
    },
    ':hover': {
      background: theme.palette.primary.aiColor,
    },
  }),
  uplodee_Button: (theme) => ({
    height: '38px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '11px var(--Numbers-Number-7, 14px)',
    fontSize: theme?.typography.menuItems.fontSize,
    fontWeight: theme?.typography.h3.fontWeight,
    fontFamily: theme?.typography.h2.fontFamily,
    background: theme.palette.button.dark,
    fontStyle: 'normal',
    lineHeight: '20px',
    width: 'fit-content',
    textTransform: 'capitalize',
    color: 'white',
    '&.Mui-disabled': {
      color: theme.palette.customWhite.main,
    },
    ':hover': {
      background: theme.palette.button.dark,
    },
  }),
  footer_Text: (theme) => ({
    color: theme.palette.text.main,
    textAlign: 'center',
    fontFamily: theme?.typography.h2.fontFamily,
    fontSize: theme?.typography.paragraph.fontSize,
    fontWeight: theme?.typography.h5.fontWeight,
    lineHeight: '18px',
    width: '740px',
    height: '54px',
  }),
}
export default youtubePostModelStyle

const styleAddMembersForm = {
  modal_Box: (theme) => ({
    width: '650px',
    backgroundColor: theme.palette.background.light,
    border: `1px solid ${theme.palette.border.extralight}`,
    height: 'fit-content',
    mx: 'auto',
    my: 'auto',
    borderRadius: '20px',
  }),
  loader_Box: {
    position: 'fixed',
    color: '',
    top: '60%',
    left: '60%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },
  parent_Box: (theme) => ({
    backgroundColor: theme.palette.background.light,
    border: `1px solid ${theme.palette.border.extralight}`,
    width: '100%',
    boxSizing: 'border-box',
    padding: '20px',
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '20px',
  }),
  inviteWorkspace_Box: {
    display: 'flex',
    width: '100%',
    background: '',
    justifyContent: 'space-between',
  },
  formStyle: {
    background: '',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  textField_Main_Box: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '6px',
    background: '',
  },
  helper_Text: {
    position: 'absolute',
    bottom: '-20px',
    color: 'red',
  },
  dialogAction: {
    display: 'flex',
    justifyContent: 'end',
    gap: '12px',
    alignItems: 'end',
    boxSizing: 'border-box',
    background: '',
  },
  addIcon_Box: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    ml: '4px',
  },
}
export default styleAddMembersForm
export const inviteWorkspace_Text = (theme) => ({
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.h1.fontWeight,
  fontFamily: theme.typography.h2.fontFamily,
  lineHeight: 'normal',
  letterSpacing: '-0.96px',
  color: theme.palette.text.main,
})
export const desc_Workspace_Text = (theme) => ({
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  fontFamily: theme.typography.h2.fontFamily,
  lineHeight: '24px',
  color: theme.palette.text.light,
})
export const labelTextfield = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  color: theme.palette.text.main,
  width: 'auto',
  marginRight: 'auto',
  lineHeight: '20px',
})
export const button_Style = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '12px',
  border: '1px solid var(--Light-Hover, #DADAFF)',
  zIndex: 5,
  color: theme?.palette.customWhite.main,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
  lineHeight: 'normal',
  letterSpacing: '0.075px',
  textTransform: 'capitalize',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding:
    ' var(--Components-Button-Spacing-Y-SM, 10px) var(--Components-Button-Spacing-X-SM, 12px)',

  background: theme.palette.button.dark,
  '&:hover': {
    backgroundColor: theme.palette.button.dark,
    transition: 'background-color 0.2s ease-in-out',
    color: 'white',
  },
})
export const commonSelectStyle = (error, theme) => ({
  borderRadius: '8px',
  backgroundColor: theme.palette.background.default,
  height: '42px',
  paddingLeft: '0px',
  // color:'#a0a0a4',
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme?.palette.border.textfield,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: error ? 'red' : theme?.palette.border.textfield,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme?.palette.border.textfield,
  },
  '& .MuiSelect-select': {
    color: theme.palette.text.main,
  },
  '& .MuiSelect-icon': {
    color: theme?.palette.text.main,
  },
})

export const emailFieldStyles = (error, theme) => ({
  borderRadius: '8px',
  backgroundColor: theme.palette.background.default,
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    gap: '12px',
    '&:hover fieldset': {
      borderColor: theme?.palette.border.textfield,
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'red' : theme?.palette.border.textfield,
    },
    '& fieldset': {
      borderColor: theme?.palette.border.textfield,
    },
    '& .MuiOutlinedInput-input': {
      height: { xs: '15px', sm: '10px', md: '10px' },
      color: theme.palette.text.main,
    },
  },
})
export const role_MenuItem = (theme) => ({
  height: '44px',
  mb: '6px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.gray.light}`,
  color: '#1F2937',
  fontStyle: 'normal',
  lineHeight: '24px',
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  fontFamily: theme.typography.h2.fontFamily,
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.background.default,
  },
})

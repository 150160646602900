import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import { tableDialogStyle } from './activeSectionStyle'
import { useTheme } from '@emotion/react'
const TableDialog = ({
  openDialog,
  setOpenDialog,
  deletePost,
  feedDataForDialog,
}) => {
  // console.log('feedDataForDialogs', feedDataForDialog)
  const theme = useTheme()
  return (
    <Dialog
      PaperProps={{
        sx: tableDialogStyle.dialog_Style(theme),
      }}
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle sx={tableDialogStyle.header_Text(theme)}>
        Delete Post
      </DialogTitle>
      <DialogContentText sx={tableDialogStyle.text_Style(theme)}>
        Are you sure want to delete this post?
      </DialogContentText>
      <DialogActions sx={tableDialogStyle.dialogAction}>
        <Button
          onClick={() => setOpenDialog(false)}
          sx={tableDialogStyle.cancelButton(theme)}
        >
          Cancel
        </Button>
        <Button
          onClick={() =>
            deletePost(
              feedDataForDialog.pageId,
              feedDataForDialog._id,
              feedDataForDialog.workspaceId
            )
          }
          sx={tableDialogStyle.deleteButton(theme)}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TableDialog

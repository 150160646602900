import React from 'react'
import { Box, Skeleton } from '@mui/material'
import { useTheme } from '@emotion/react'

const TableSkeleton = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 16px',
        background: theme.palette.skeleton.background,
        borderRadius: '12px',
        boxSizing: 'border-box',
        gap: '10px',
      }}
    >
      <Skeleton variant='circular' width={30} height={30} animation='wave' />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          gap: '10px',
        }}
      >
        <Skeleton variant='text' width='100%' height={30} animation='wave' />
        <Skeleton variant='text' width='100%' height={30} animation='wave' />
        <Skeleton variant='text' width='100%' height={30} animation='wave' />
        <Skeleton variant='text' width='100%' height={30} animation='wave' />
      </Box>
    </Box>
  )
}

export default TableSkeleton

export const homePage = {
  main: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    width: '100%',
    height: '100%',
    background: theme.palette.background.default,
    // background: 'green',
    transition: 'all 0.3s ease',
  }),

  searchTextTypo: (theme) => ({
    display: 'flex',
    maxWidth: '800px',
    width: 'fit-content',
    padding: '10px 20px',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    borderRadius: 'var(--Numbers-Number-8, 16px)',
    background: theme?.palette?.border?.extralight,
    color: theme?.palette?.text?.main,
    fontFamily: theme?.typography?.h5?.fontFamily,
    fontSize: theme?.typography?.h5?.fontSize,
    fontStyle: 'normal',
    fontWeight: theme?.typography?.h5?.fontWeight,
    lineHeight: '20px',
    marginBottom: '24px',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  }),

  searchBarTextField: (theme) => ({
    boxSizing: 'border-box',
    padding: '6px 10px 6px 16px',
    borderRadius: 'var(--Numbers-Number-13, 26px)',
    background: theme?.palette.background.searchField,
  }),
  emptyScreen: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  chatPageMain: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    width: '100%',
    height: 'calc(100vh - 81px)',
    paddingX: '20px',
    paddingTop: '24px',
    paddingBottom: '16px',
  },
  chatPagePost: {
    height: 'calc(100vh - 270px)',
    width: '100%',
  },
  chatPageSearch: {
    display: 'flex',
    maxWidth: '720px',
    width: 'fit-content',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '12px',
  },

  emptyScreenPage: {
    display: 'flex',
    height: 'calc(100vh - 81px)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyScrMain: {
    display: 'flex',
    width: '100%',
    maxWidth: '621px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '28px',
  },
  emptySrcSearch: {
    display: 'flex',
    position: 'relative',
    alignItems: 'end',
    width: '100%',
  },
  emptyScrHoverImg: { width: '20px', height: '20px', marginLeft: '-10px' },
  emptyScruploaded: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
  },
  emptyScrUploadedMedia: {
    position: 'relative',
    width: '56px',
    height: '56px',
    paddingBottom: '10px',
    borderRadius: '12px',
    '&:hover .close-icon': { opacity: 1 },
  },

  emptyScrMediaStyle: {
    width: '100%',
    height: '100%',
    borderRadius: '12px',
    objectFit: 'cover',
  },
  emptyScrMediaclose: {
    position: 'absolute',
    top: -6,
    right: -6,
    padding: '2px',
    cursor: 'pointer',
    opacity: 0,
  },
  emptyScrSendIconImg: {
    width: '20px',
    height: '20px',
    filter: 'invert(100%)',
    paddingLeft: '3px',
  },

  emptyScrButtonSection: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  postMenuList: (theme) => ({
    display: 'inline-flex',
    boxSizing: 'border-box',
    padding: '0px 8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '16px',
    border: `1px solid ${theme?.palette?.customWhite?.hover}`,
    backgroundColor: '#8E938F',
    backdropFilter: 'blur(28px)',
    boxShadow: 'none',
  }),
  postMenuListItem: (theme) => ({
    display: 'flex',
    boxSizing: 'border-box',
    padding: ' 6px 8px',
    alignItems: 'center',
    gap: '4px',
    alignSelf: 'stretch',
    cursor: 'pointer',
    color: theme?.palette?.customWhite?.hover,
    fontFamily: theme?.typography?.h5?.fontFamily,
    fontSize: theme?.typography?.h5?.fontSize,
    fontWeight: theme?.typography?.h5?.fontWeight,
    lineHeight: '20px',
  }),

  postSocialIconAvatar: (theme) => ({
    '& .MuiAvatar-root': {
      height: 32,
      width: 32,
      fontSize: 'small',
      backgroundColor: '#9B9D98',
      border: 'none',
      pointerEvents: 'none',
      color: theme.palette.sky.light,
      fontFamily: theme?.typography?.h5?.fontFamily,
      fontWeight: theme?.typography?.h5?.fontWeight,
      margin: '0 5px',
      '& img': {
        width: '18px',
        height: '18px',
      },
    },
    '& .MuiAvatarGroup-avatar': {
      pointerEvents: 'auto',
      cursor: 'pointer',
    },
  }),

  postSocialIconMenu: (theme) => ({
    display: 'inline-flex',
    boxSizing: 'border-box',
    padding: '0px 8px',
    maxHeight: '120px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '16px',
    border: `1px solid ${theme?.palette?.customWhite?.hover}`,
    backgroundColor: 'rgba(255, 255, 255, 0.40)',
    backdropFilter: 'blur(32px)',
    boxShadow: 'none',
  }),

  postSocialIconMenuItem: (theme) => ({
    display: 'flex',
    boxSizing: 'border-box',
    padding: ' 6px 8px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    cursor: 'pointer',
    color: theme?.palette?.customWhite?.hover,
    fontFamily: theme?.typography?.h5?.fontFamily,
    fontSize: theme?.typography?.h5?.fontSize,
    fontWeight: theme?.typography?.h5?.fontWeight,
    lineHeight: '20px',
  }),

  trendingNewsSwiper: {
    display: 'flex',
    background: '',
    boxSizing: 'border-box',
    gap: '12px',
    width: '100%',
    paddingX: '10px',
    justifyContent: 'start',
    marginLeft: '0',
    position: 'relative',
    zIndex: 0,
  },
  trendingNewsSwiperSlide: {
    display: 'flex',
    justifyContent: 'start',
    width: 'auto',
    background: '',
  },

  trendingNews: (theme) => ({
    display: 'flex',
    padding: '6px 14px 6px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: 'var(--Numbers-Number-15, 30px)',
    border: ' 1px solid #7215CF',
    background: theme.palette.sky.light,
    '&:hover': {
      background: theme.palette.background.searchField,
    },
    color: theme?.palette?.text?.light,
    fontFamily: theme?.typography?.h5?.fontFamily,
    fontSize: theme?.typography?.h5?.fontSize,
    fontWeight: theme?.typography?.h5?.fontWeight,
    fontStyle: 'normal',
    lineHeight: '20px',
    textTransform: 'capitalize',
  }),

  trendingPostBlurLeft: (theme) => ({
    width: '100px',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    background: ` linear-gradient(90deg, ${theme.palette.background.default} 5%, rgba(255, 255, 255, 0.00) 100%)`,
    zIndex: 1,
  }),
  trendingPostBlurRight: (theme) => ({
    width: '100px',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    background: `linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, ${theme.palette.background.default} 95%)`,
    zIndex: 1,
  }),
}
export const emptyScrButtonStyle = (theme) => ({
  display: 'flex',
  boxSizing: 'border-box',
  padding: '8px 12px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '26px',
  border: `1px solid ${theme?.palette?.border?.main}`,
  background: theme.palette.background.default,
  color: theme?.palette?.text?.main,
  fontFamily: theme?.typography?.h5?.fontFamily,
  fontSize: theme?.typography?.h5?.fontSize,
  fontWeight: theme?.typography?.h5?.fontWeight,
  textTransform: 'none',
  lineHeight: '20px',
  height: '36px',
  '&:hover': {
    background: theme.palette.background.default,
  },
})
export const emptyScrSearchUploadicon = (theme) => ({
  display: 'flex',
  boxSizing: 'border-box',
  padding: '4px',
  borderRadius: '12px',
  alignSelf: 'end',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.hover.svg,
  },
})
export const emptyScrSearchSendicon = (theme, inputValue) => ({
  boxSizing: 'border-box',
  paddingY: '6px',
  paddingleft: '9px',
  paddingRight: '6px',
  alignSelf: 'end',
  alignItems: 'center',
  minWidth: '0',
  width: '36px',
  height: '32px',
  marginLeft: '10px',
  borderRadius: '50%',
  opacity: inputValue.trim() ? 1 : 0.18,
  backgroundColor: theme?.palette?.text?.main,
  cursor: inputValue ? 'pointer' : 'default',
  '&:hover': {
    backgroundColor: theme?.palette?.text?.main,
  },
})
export const emptyScrSearch = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  width: '100%',
  borderRadius: 'var(--Numbers-Number-13, 26px)',
  background: theme?.palette?.customWhite?.hover,
})
export const emptyScrHeading = (theme) => ({
  alignSelf: 'stretch',
  color: theme?.palette?.text?.main,
  textAlign: 'center',
  fontFamily: theme?.typography?.h5?.fontFamily,
  fontSize: theme?.typography?.large?.fontSize,
  fontWeight: theme?.typography?.h1?.fontWeight,
  letterSpacing: '-1.2px',
})
export const emptyScrSeachHover = (isHovered) => ({
  display: isHovered ? 'flex' : 'none',
  alignItems: 'center',
  marginLeft: '-105px',
  marginBottom: '6px',
  position: 'absolute',

  zIndex: 2,
})
export const emptyScrHoverText = (theme) => ({
  boxSizing: 'border-box',
  padding: '6px 8px',
  background: theme?.palette?.black?.main,
  borderRadius: 'var(--Overlays-Tooltips-Spacing-Y, 8px)',
  color: theme?.palette?.customWhite?.main,
  textAlign: 'center',
  fontFamily: theme?.typography?.h5?.fontFamily,
  fontSize: theme?.typography?.h5?.fontSize,
  fontWeight: theme?.typography?.h4?.fontWeight,
  fontStyle: 'normal',
  whiteSpace: 'nowrap',
  lineHeight: '20px',
})
export const emptyScrTextField = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: 0,
  color: theme?.palette?.text?.searchText,

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    padding: '0',
    alignSelf: 'end',
    marginLeft: '6px',
    paddingBottom: '4px',
    fontFamily: theme?.typography?.h5?.fontFamily,
    fontSize: theme?.typography?.h4?.fontSize,
    fontWeight: theme?.typography?.h5?.fontWeight,
  },
  '& .MuiInputBase-input::placeholder': {
    flex: '1 0 0',
    color: theme?.palette?.text?.searchText,
    boxSizing: 'border-box',
    lineHeight: '24px',
    fontStyle: 'normal',
    fontFamily: theme?.typography?.h5?.fontFamily,
    fontSize: theme?.typography?.h4?.fontSize,
    fontWeight: theme?.typography?.h5?.fontWeight,
  },
})

export const platformtext = (theme) => ({
  lineHeight: '20px',
  fontSize: theme?.typography?.menuItems?.fontSize,
  fontWeight: theme?.typography?.menuItems?.fontWeight,
  color: theme?.palette?.black?.main,
  fontFamily: theme?.typography?.menuItems?.fontFamily,
  marginLeft: '8px',
})

export const trendingPostStyle = {
  divider_Style: {
    boxSizing: 'border-box',
    paddingTop: '18px',
  },
  main: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    gap: '16px ',
    flexWrap: 'wrap',
  },

  postSkeleton: (theme) => ({
    display: 'flex',
    boxSizing: 'border-box',
    padding: '12px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '26px',
    width: '266px',
    border: `1px solid ${theme?.palette?.sky?.light}`,
    height: '266px',
    backgroundColor: theme?.palette?.gray?.main,
  }),
  postSkeletonhead: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  skeletonBottom: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-start',
  },
  post: {
    display: 'flex',
    boxSizing: 'border-box',
    padding: '12px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '26px',
    backgroundColor: 'lightgray',
    width: '266px',
    height: '266px',
    position: 'relative',
    cursor: 'pointer',
  },

  postContent: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    borderRadius: '16px',
  },

  followerData: (theme) => ({
    width: 'fit-content',
    height: '28px',
    boxSizing: 'border-box',
    padding: '4px 8px',
    borderRadius: '38px',
    background: theme?.palette?.customWhite?.opacity50,
    backdropFilter: 'blur(28px)',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: '20px',
    cursor: 'pointer',
    color: theme.palette.sky.light,
    fontSize: theme?.typography?.h5?.fontSize,
    fontWeight: theme?.typography?.h5?.fontWeight,
    fontFamily: theme?.typography?.menuItems?.fontFamily,
  }),

  detail: (theme) => ({
    lineHeight: '18px',
    alignSelf: 'flex-end',
    color: '#C2C2C2',
    fontSize: theme?.typography?.paragraph?.fontSize,
    fontWeight: theme?.typography?.h5?.fontWeight,
    fontFamily: theme?.typography?.menuItems?.fontFamily,
  }),
  description: (theme) => ({
    background:
      'linear-gradient(273deg, #4D91FF -2.79%, #B14BF4 63.06%, #FA5560 83.15%)',
    lineHeight: '20px',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: theme?.typography?.h5?.fontSize,
    fontWeight: theme?.typography?.h1?.fontWeight,
    fontFamily: theme?.typography?.menuItems?.fontFamily,
  }),
}

import React from 'react'
import workspaceMainSectionStyle from './dashboardMainSectionStyle'
import { Box, Skeleton } from '@mui/material'
import { useTheme } from '@emotion/react'

const SpaceSkeleten = ({ flexDirection }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: {
          xs: 'fit-content',
          sm: '320px',
          md: '358px',
        },
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        padding: '16px',
        gap: '12px',
        color: 'red',
        borderRadius: '16px',
        background: theme?.palette.skeleton.background,
        border: `1px solid ${theme?.palette.skeleton.border}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Skeleton
          variant='circular'
          width='48px'
          height='48px'
          animation='wave'
          sx={{ borderRadius: '50%' }}
        />
        <Skeleton variant='text' width='70%' animation='wave' />
      </Box>

      <Skeleton variant='rounded' width='100%' height={'50px'} />
      <Box sx={{ width: '90%' }}>
        <Skeleton
          variant='circular'
          width='25px'
          height={'25px'}
          animation='wave'
          sx={{ borderRadius: '50%' }}
        />
      </Box>
      <Skeleton variant='text' width='100%' animation='wave' />
    </Box>
  )
}

export default SpaceSkeleten

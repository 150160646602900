import { useTheme } from '@emotion/react'
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import UserContext from '../../../../../../Context/userContext'

const AgeRestriction = ({
  audience,
  ageRestriction,
  setAgeRestriction,
  loading,
}) => {
  const handleChange = (event) => {
    setAgeRestriction(event.target.value)
  }
  const { postData } = useContext(UserContext)

  const theme = useTheme()
  return (
    <Box
      sx={{
        width: '550px',
        display: postData ? 'none' : 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Typography
        sx={{
          color: theme.palette.text.main,
          fontFamily: theme?.typography.h2.fontFamily,
          fontSize: theme?.typography.h4.fontSize,
          fontWeight: theme?.typography.h4.fontWeight,
          fontStyle: 'normal',
          lineHeight: '24px',
        }}
      >
        Age restriction (advance){' '}
      </Typography>

      <>
        <span style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <Typography
            sx={{
              color: theme.palette.text.main,
              fontFamily: theme?.typography.h2.fontFamily,
              fontSize: theme?.typography.paragraph.fontSize,
              fontWeight: theme?.typography.h4.fontWeight,
              fontStyle: 'normal',
              lineHeight: '20px',
            }}
          >
            Do you want to restrict your video to an adult audience?
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.light,
              fontFamily: theme?.typography.h2.fontFamily,
              fontSize: theme?.typography.paragraph.fontSize,
              fontWeight: theme?.typography.h5.fontWeight,
              fontStyle: 'normal',
              lineHeight: '20px',
            }}
          >
            Age-restricted videos are not shown in certain areas of YouTube.
            These videos may have limited or no ads monetization.
            <a
              href='https://support.google.com/youtube/answer/2797468?hl=en'
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'underline' }}
            >
              Learn more
            </a>
          </Typography>
        </span>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '16px',
          }}
        >
          <RadioGroup
            aria-labelledby='restriction-radio-group-label'
            name='restriction-radio-group'
            value={ageRestriction}
            onChange={handleChange}
            sx={{
              '& .MuiFormControlLabel-root': {
                marginBottom: '-8px', // Reduce the bottom margin
              },
            }}
          >
            <FormControlLabel
              disabled={audience || loading}
              sx={{ color: theme.palette.text.light }}
              value='true'
              control={
                <Radio
                  sx={{
                    color: theme.palette.text.main,
                    '&.Mui-checked': {
                      color: theme.palette.text.main,
                    },
                  }}
                />
              }
              label='Yes, restrict my video to viewers over 18'
              componentsProps={{
                typography: {
                  sx: {
                    fontFamily: theme.typography.h2.fontFamily,
                    color: theme.palette.text.light,
                    fontSize: theme?.typography.h5.fontSize,
                    fontWeight: theme?.typography.h5.fontWeight,
                  },
                },
              }}
            />
            <FormControlLabel
              disabled={audience === 'kids' || loading}
              value='false'
              sx={{ color: theme.palette.text.light }}
              control={
                <Radio
                  sx={{
                    color: theme.palette.text.main,
                    '&.Mui-checked': {
                      color: theme.palette.text.main,
                    },
                  }}
                />
              }
              label="No, don't restrict my video to viewers over 18 only"
              componentsProps={{
                typography: {
                  sx: {
                    fontFamily: theme.typography.h2.fontFamily,
                    color: theme.palette.text.light,
                    fontSize: theme?.typography.h5.fontSize,
                    fontWeight: theme?.typography.h5.fontWeight,
                  },
                },
              }}
            />
          </RadioGroup>
        </Box>
      </>
    </Box>
  )
}

export default AgeRestriction

import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import {
  changeRole_Button_Style,
  manageCredits_Button_Style,
  membersSectionStyle,
  switcher_Button_Style,
} from '../workspaceProfilePageStyle'
import UserContext from '../../../../../Context/userContext'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import ReusableTable from '../../../../ReusableComponents/customTable'
import { MoreVertRounded } from '@mui/icons-material'
import { formatDateString } from '../../../../../API'
import WorkSpaceMemberModal from './workSpaceMemberModal'
import ChangeRoleModal from './changeRoleModal'
import MembersCreditsModel from './memberCreditsModel'
import { menuRole_Style } from './workSpaceMemberModalStyle'
const WorkspaceMemberTable = ({
  acceptedMembers,
  roles,
  setRender,
  render,
  setApiError,
  setActionDetect,
  spaceData,
}) => {
  const [permissions, setPermissions] = useState([])
  console.log('object', permissions)
  const { currentUser } = useContext(UserContext)
  const [permissionModel, setPermissionModel] = useState(false)
  const [roleChangeModel, setRoleChangeModel] = useState(false)
  const [currentRowData, setCurrentRowData] = useState()
  // console.log('currentRowData',currentRowData);
  const spaceId = window.sessionStorage.getItem('spaceId')
  const [anchorEl, setAnchorEl] = useState(null)
  const [openCreditsModel, setOpenCreditsModel] = useState(false)

  const handleMenuOpen = (event, row) => {
    setCurrentRowData(row)
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handlePermissionModal = (state, permissionsWorkspaces) => {
    // console.log('permissionsWorkspaces', permissionsWorkspaces)
    setPermissions(permissionsWorkspaces)
    setPermissionModel(state)
  }

  const handleChangeRoleModel = (state) => {
    setRoleChangeModel(state)
    handleMenuClose()
  }
  const handleCreditsModelOpen = () => {
    setOpenCreditsModel(true)
    handleMenuClose()
  }
  const handleCreditsModelClose = () => {
    setOpenCreditsModel(false)
  }
  function formatCount(count) {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(count % 1000000 === 0 ? 0 : 1) + 'M' // For millions
    } else if (count >= 1000) {
      return (count / 1000).toFixed(count % 1000 === 0 ? 0 : 1) + 'K' // For thousands
    } else {
      return count?.toString() // For values less than 1000
    }
  }
  const data = acceptedMembers
  const columns = [
    {
      field: 'fullName',
      headerName: 'Profile',
      renderCell: (row) => (
        <div style={membersSectionStyle.map_Div_tableCell}>
          {row.imageUrl ? (
            <img
              src={row.imageUrl}
              alt='profile-Avatar'
              style={membersSectionStyle.image_Style}
            />
          ) : (
            <Box
              sx={{
                height: '40px',
                width: '40px',
                borderRadius: '50%',
                background: 'lightgrey',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
              }}
            >
              {row.fullName ? row.fullName[0] : 'N/A'}
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0px',
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.h3.fontSize,
                fontWeight: theme.typography.h5.fontWeight,
                fontFamily: theme.typography.h2.fontFamily,
                color: theme.palette.text.main,
                lineHeight: '27px',
              }}
            >
              {row.fullName}
            </Typography>
            <Typography
              sx={{
                fontSize: theme.typography.paragraph.fontSize,
                fontWeight: theme.typography.h5.fontWeight,
                fontFamily: theme.typography.h2.fontFamily,
                color: theme.palette.text.light,
                lineHeight: '18px',
              }}
            >
              {row.email}
            </Typography>
          </Box>
        </div>
      ),
    },
    {
      field: 'roleName',
      headerName: 'Role',
      renderCell: (row) => (
        <Chip
          label={row.roleName}
          sx={{
            padding: '0px',
            width: '100px',
            boxSizing: 'border-box',
            fontFamily: theme.typography.h4.fontFamily,
            borderRadius: '8px',
            backgroundColor:
              row.roleName === 'Owner'
                ? '#CCFBF1'
                : row.roleName === 'Admin'
                ? '#CCE0FF'
                : row.roleName === 'Manager'
                ? '#FEF9C3'
                : null,
            color:
              row.roleName === 'Owner'
                ? '#115E59'
                : row.roleName === 'Admin'
                ? '#1F2937'
                : row.roleName === 'Manager'
                ? '#854D0E'
                : null,
          }}
        />
      ),
    },
    {
      field: 'workspaces',
      headerName: 'Permissions',
      renderCell: (row) => (
        <Button
          onClick={() => handlePermissionModal(true, row.workspaces)}
          variant='text'
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
            },
            fontFamily: theme.typography.h5.fontFamily,
            fontWeight: theme.typography.h5.fontWeight,
            color: theme.palette.text.light,
            lineHeight: '20px',
            textTransform: 'capitalize',
            ':hover': { textDecorationLine: 'underline', background: 'none' },
          }}
        >
          {row.workspaces.length} workspaces
        </Button>
      ),
    },
    {
      field: 'credits',
      headerName: 'Credits',
      renderCell: (row) => (
        <Typography
          variant='text'
          sx={{
            fontSize: theme.typography.h5.fontSize,
            fontFamily: theme.typography.h5.fontFamily,
            fontWeight: theme.typography.h5.fontWeight,
            color: theme.palette.text.light,
            lineHeight: '20px',
          }}
        >
          {row.roleName !== 'Owner'
            ? formatCount(row.credits.totalReceived)
            : formatCount(currentUser?.Subscription?.credits?.remaining)}
        </Typography>
      ),
    },

    {
      field: 'acceptedDate',
      headerName: 'Accepted Date',
      renderCell: (row) => (
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
            },
            fontFamily: theme.typography.h5.fontFamily,
            fontWeight: theme.typography.h5.fontWeight,
            color: theme.palette.text.light,
            lineHeight: '20px',
          }}
        >
          {formatDateString(row.acceptedDate)}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (row) => (
        <IconButton
          disabled={
            row.roleName === 'Owner' ||
            spaceData?.creatorId !== currentUser?._id ||
            (spaceData?.Permissions?.workSpace['Manage Credits'] === false &&
              spaceData?.Permissions?.workSpace['Change Role'] === false)
              ? true
              : false
          }
          onClick={(event) => handleMenuOpen(event, row)}
        >
          <MoreVertRounded sx={{ color: theme.palette.text.light }} />
        </IconButton>
      ),
    },
  ]

  const isMenuOpen = Boolean(anchorEl)
  const navigate = useNavigate()
  const theme = useTheme()
  return (
    <>
      <ReusableTable columns={columns} data={data} width={'20%'} />
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
        PaperProps={{
          sx: menuRole_Style(theme),
        }}
      >
        <MenuItem
          onClick={() => handleChangeRoleModel(true)}
          sx={changeRole_Button_Style(theme, spaceData)}
        >
          Change Role
        </MenuItem>
        <MenuItem
          onClick={handleCreditsModelOpen}
          sx={manageCredits_Button_Style(theme, currentRowData, spaceData)}
        >
          Manage Credits
        </MenuItem>
        {/* <MenuItem onClick={handleMenuClose} sx={switcher_Button_Style(theme)}>
          Remove
        </MenuItem> */}
      </Menu>

      <WorkSpaceMemberModal
        permissions={permissions}
        handlePermissionModal={handlePermissionModal}
        permissionModel={permissionModel}
      />
      <ChangeRoleModal
        roleChangeModel={roleChangeModel}
        handleChangeRoleModel={handleChangeRoleModel}
        currentRowData={currentRowData}
        roles={roles}
        setRender={setRender}
        render={render}
      />
      <MembersCreditsModel
        currentRowData={currentRowData}
        open={openCreditsModel}
        onClose={handleCreditsModelClose}
        setApiError={setApiError}
        setActionDetect={setActionDetect}
      />
    </>
  )
}

export default WorkspaceMemberTable

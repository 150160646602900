const workspaceProfilePageStyle = {
  parentDiv: (theme) => ({
    width: '100%',
    display: 'flex',
    gap: '23px',
    paddingX: '15px',
    boxSizing: 'border-box',
    flexDirection: 'column',
    padding: '15px',
    overflowX: 'hidden',
    background: theme.palette.background.default,
  }),
  circularProgressBox: {
    display: 'flex',
    background: '',
    justifyContent: 'center',
    alignItems: 'end',
    height: '400px',
    width: '100%',
  },
  img_Box: (theme) => ({
    height: '150px',
    width: '170px',
    display: 'flex',
    borderRadius: '16px',
    border: `1px solid ${theme?.palette.sky.light}`,
  }),
  imageProfile_Style: {
    backgroundColor: 'lightgray',
    width: '100%',
    height: '100%',
    borderRadius: '16px',
    objectFit: 'cover',
  },
  workspace_Info_Box: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: '',
  },
  text_Name_Category_UserBox: {
    background: '',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  spanStyle: {
    display: 'flex',
    background: '',
    alignItems: 'center',
    gap: '6px',
  },
  ancorTag_Box: {
    display: 'flex',
    width: 'fit-content',
    background: '',
    gap: '4px',
    justifyContent: 'center',
  },
  userData_Card: {
    width: { xs: '290px', sm: '100%' },
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '12px',
    borderRadius: '16px',
    background: '',
    boxSizing: 'border-box',
  },
  button_Box: {
    width: '100%',
    background: '',
    paddingY: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
  },
  span_BtnStyle: {
    display: 'flex',
    gap: '8px',
    height: '38px',
    background: '',
  },
}
export default workspaceProfilePageStyle
export const userWorkspaceName = (theme) => ({
  fontWeight: {
    xs: theme.typography.h4.fontWeight,
    md: theme.typography.menualText.fontWeight,
  },
  fontFamily: theme.typography.h2.fontFamily,
  color: theme.palette.text.main,
  fontSize: {
    xs: theme.typography.h3.fontSize,
    sm: theme.typography.large.fontSize,
  },
  lineHeight: '1',
  letterSpacing: '-1.2px',
})
export const websiteText = (theme) => ({
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h4.fontSize,
  },
  fontFamily: theme.typography.h4.fontFamily,
  color: theme.palette.text.main,
  fontWeight: theme.typography.h5.fontWeight,
  lineHeight: '20px',
  textDecoration: 'underLine',
})
export const categoryText = (theme) => ({
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h4.fontSize,
  },
  fontFamily: theme.typography.h4.fontFamily,
  color: theme.palette.text.main,
  fontWeight: theme.typography.h5.fontWeight,
  lineHeight: '20px',
})
export const main_Box = (theme) => ({
  width: { xs: '290px', sm: '100%' },
  display: 'flex',
  height: '180px',
  borderRadius: '16px',
  backgroundColor: theme.palette.gray.main,
  gap: '16px',
  boxSizing: 'border-box',
  padding: '16px',
  border: `1px solid ${theme?.palette.sky.light}`,
  alignItems: 'center',
})
export const editWorkspaceText = (theme) => ({
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h4.fontSize,
    md: theme.typography.h5.fontSize,
    lg: theme.typography.h4.fontSize,
  },
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h5.fontWeight,
  lineHeight: '1',
  textTransform: 'capitalize',
  color: theme.palette.text.light,
})
export const descriptionText = (theme) => ({
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: {
    xs: theme.typography.paragraph.fontWeight,
    sm: theme.typography.h5.fontWeight,
  },
  lineHeight: 'normal',
  letterSpacing: '-0.56px',
  textTransform: 'capitalize',
  color: theme.palette.text.light,
  width: '100%',
  background: '',
})
export const spaceDate = (theme) => ({
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: {
    xs: theme.typography.h3.fontWeight,
  },
  lineHeight: 'normal',
  letterSpacing: '-0.56px',
  textTransform: 'capitalize',
  color: theme.palette.text.light,
  width: '100%',
  background: '',
})

export const editButtonStyle = (theme) => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  cursor: 'pointer',
  gap: { sm: '12px' },
  border: `1px solid ${theme?.palette.sky.light}`,
  borderRadius: '8px',
  background: theme.palette.background.default,
  boxSizing: 'border-box',
  padding: { xs: '4px', sm: '8px' },
  mt: { xs: '32px', sm: '44px', md: '0px' },
  zIndex: 5,
})
export const custom_Button = (activeButton, section, theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  padding:
    ' var(--Components-Button-Spacing-Y-SM, 10px) var(--Components-Button-Spacing-X-SM, 12px)',
  boxSizing: 'border-box',
  background:
    activeButton === section
      ? theme.palette.button.dark
      : theme.palette.gray.extraLight,
  color:
    activeButton === section
      ? theme.palette.customWhite.main
      : theme.palette.primary.light,
  textTransform: 'capitalize',
  borderRadius: 'var(--Overlays-Tooltips-Indicator-Border-Radius, 999px)',
  lineHeight: 'normal',
  letterSpacing: '0.075px',
  '&:hover': {
    background: theme.palette.button.dark,
    color: theme.palette.customWhite.main,
    '& .MuiDivider-root': {
      background: theme.palette.customWhite.main,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.customWhite.main,
    },
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
})
export const switcherButton = (switchButton, optionBtn, theme) => ({
  background:
    switchButton === optionBtn
      ? theme.palette.button.dark
      : theme.palette.gray.extraLight,
  color:
    switchButton === optionBtn
      ? theme.palette.customWhite.main
      : theme.palette.primary.light,
  width: 'fit-content',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 'var(--Overlays-Tooltips-Indicator-Border-Radius, 999px)',
  boxSizing: 'border-box',
  paddingX: '10px',
  gap: '4px',
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.button.dark,
    color: theme.palette.customWhite.main,

    '& .MuiSvgIcon-root': {
      color: theme.palette.customWhite.main,
    },
  },
})

export const switcherText_Style = (switchButton, optionBtn, theme) => ({
  color: switchButton === optionBtn ? theme.palette.customWhite.main : '',
  textTransform: 'capitalize',
  borderTopLeftRadius:
    'var(--Overlays-Tooltips-Indicator-Border-Radius, 999px)',
  borderBottomLeftRadius:
    'var(--Overlays-Tooltips-Indicator-Border-Radius, 999px)',
  boxSizing: 'border-box',
  paddingRight: '4px',
  width: 'fit-content',
  lineHeight: '1',
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  letterSpacing: '0.075px',
  '&:hover': {
    color: theme.palette.customWhite.main,
  },
})

export const editButonBox = () => ({
  width: '100%',
  height: '32%',
  background: '',
  display: 'flex',
  justifyContent: 'space-between',
})

export const space_favourite_button = (isFavourite) => ({
  background: '',
  height: '25px',
  width: '25px',
  ml: 'auto',
  color: `${isFavourite === true ? 'gold' : ''}`,
})
export const menu_Style = (theme) => ({
  borderRadius: '12px',
  background: theme.palette.menu.background,
  paddingX: { xs: '8px', sm: '8px' },
  border: `1px solid ${theme.palette.border.extralight}`,
  color: theme?.palette.text.main,
  boxShadow:
    '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
})
export const spaceSectionStyle = {
  parent_Box: {
    width: '100%',
    background: '',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  Card_Box: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    background: '',
  },
  main_Box: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    background: '',
  },
  img_Box: {
    display: 'flex',
    background: '',
    gap: '16px',
    height: '50px',
  },
  date_Box: {
    display: 'flex',
    flexDirection: 'column',
    background: '',
    justifyContent: 'center',
    gap: '6px',
  },
}
export const desc_Text = (theme) => ({
  width: '100%',
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  color: theme.palette.text.light,
  lineHeight: '24px',
})
export const connectPlatforms_Btn = (theme) => ({
  textTransform: 'capitalize',
  paddingY: { xs: '5px', sm: '10px' },
  paddingX: { xs: '6px', sm: '12px' },
  boxSizing: 'border-box',
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  background: theme.palette.primary.light,
  borderRadius: 'var(--Overlays-Tooltips-Indicator-Border-Radius, 8px)',
  lineHeight: '1',
  letterSpacing: '0.075px',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.customWhite.main,
    '& .MuiDivider-root': {
      background: theme.palette.background.default,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.customWhite.main,
    },
  },
})
export const socialText = (theme) => ({
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    md: theme.typography.mediumText.fontSize,
  },
  fontWeight: theme.typography.h2.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  lineHeight: '30px',
  textTransform: 'capitalize',
  color: theme.palette.text.main,
})
export const dateText = (theme) => ({
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
  fontWeight: theme.typography.h4.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  lineHeight: '20px',
  textTransform: 'capitalize',
  color: theme.palette.text.light,
})
export const card_Style = (theme) => ({
  width: { xs: 'auto', sm: '360px' },
  // height: '170px',
  boxSizing: 'border-box',
  padding: ' var(--Numbers-Number-9, 18px) 24px',
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme?.palette.sky.light}`,
  gap: '8px',
  borderRadius: '16px',
  backgroundColor: theme.palette.gray.main,
  boxShadow:
    '0px 23px 6px 0px rgba(0, 0, 0, 0.00), 0px 15px 6px 0px rgba(0, 0, 0, 0.01), 0px 8px 5px 0px rgba(0, 0, 0, 0.03), 0px 4px 4px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
})

export const membersSectionStyle = {
  parent_Box: {
    height: '',
    width: '100%',
    background: '',
    mt: '10px',
  },
  button_Box: {
    display: 'flex',
    justifyContent: 'end',
    background: '',
  },
  map_Div_tableCell: {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    gap: '20px',
    background: '',
  },
  image_Style: {
    height: '40px',
    width: '40px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
}
export const tableCellNameText = (theme) => ({
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  width: '30%',
  background: '',
})
export const tableHeading = (theme) => ({
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  padding: '10px 16px',
  boxSizing: 'border-box',
})
export const addButtonStyle = (
  theme,
  currentUser,
  acceptedMembers,
  pendingMembers,
  spaceData
) => ({
  display:
    acceptedMembers?.length + pendingMembers?.length <
      currentUser?.Subscription?.membersCount &&
    spaceData?.Permissions?.workSpace['Add Members'] === true
      ? 'flex'
      : 'none',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '12px',
  border: `1px solid ${theme?.palette.sky.light}`,
  zIndex: 5,
  color: theme?.palette.customWhite.main,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
  lineHeight: 'normal',
  letterSpacing: '0.075px',
  textTransform: 'capitalize',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding:
    ' var(--Components-Button-Spacing-Y-SM, 10px) var(--Components-Button-Spacing-X-SM, 12px)',

  background: theme.palette.button.dark,
  '&:hover': {
    backgroundColor: theme.palette.button.dark,
    transition: 'background-color 0.2s ease-in-out',
    color: 'white',
  },
})
export const allRolesBtn = (theme, spaceData) => ({
  display:
    spaceData?.Permissions?.workSpace['All Roles'] === true ? 'flex' : 'none',
  alignItems: 'center',
  cursor: 'pointer',
  gap: '12px',
  border: `1px solid ${theme?.palette.sky.light}`,
  zIndex: 5,
  color: theme?.palette.customWhite.main,
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
  lineHeight: 'normal',
  letterSpacing: '0.075px',
  textTransform: 'capitalize',
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding:
    ' var(--Components-Button-Spacing-Y-SM, 10px) var(--Components-Button-Spacing-X-SM, 12px)',

  background: theme.palette.button.dark,
  '&:hover': {
    backgroundColor: theme.palette.button.dark,
    transition: 'background-color 0.2s ease-in-out',
    color: 'white',
  },
})
export const addTextStyle = (theme) => ({
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h4.fontSize,
    md: theme.typography.h5.fontSize,
    lg: theme.typography.h4.fontSize,
  },
  fontWeight: theme.typography.h5.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  lineHeight: '1',
  textTransform: 'capitalize',
  color: theme.palette.customWhite.main,
})

export const switcher_Button_Style = (theme, spaceData) => ({
  display: 'flex',
  fontSize: theme.typography.menuItems.fontSize,
  fontWeight: theme?.typography.menuItems.fontWeight,
  color: theme?.palette.text.main,
  fontFamily: theme?.typography.menuItems.fontFamily,
  lineHeight: '20px',
  letterSpacing: '0.07px',
  '&:hover': {
    backgroundColor: theme?.palette?.menu.menuitems,
    borderRadius: 'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)',
  },
})

export const manageCredits_Button_Style = (
  theme,
  currentRowData,
  spaceData
) => ({
  display:
    spaceData?.Permissions?.workSpace['Manage Credits'] === true
      ? 'flex'
      : 'none',
  fontSize: theme.typography.menuItems.fontSize,
  fontWeight: theme?.typography.menuItems.fontWeight,
  color: theme?.palette.text.main,
  fontFamily: theme?.typography.menuItems.fontFamily,
  lineHeight: '20px',
  letterSpacing: '0.07px',
  '&:hover': {
    backgroundColor: theme?.palette?.menu.menuitems,
    borderRadius: 'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)',
  },
})

export const changeRole_Button_Style = (theme, spaceData) => ({
  display:
    spaceData?.Permissions?.workSpace['Change Role'] === true ? 'flex' : 'none',
  fontSize: theme.typography.menuItems.fontSize,
  fontWeight: theme?.typography.menuItems.fontWeight,
  color: theme?.palette.text.main,
  fontFamily: theme?.typography.menuItems.fontFamily,
  lineHeight: '20px',
  letterSpacing: '0.07px',
  '&:hover': {
    backgroundColor: theme?.palette?.menu.menuitems,
    borderRadius: 'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)',
  },
})

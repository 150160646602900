import { check, curve } from '../assets'
import { collabApps, collabContent, socialCollabTextTwo } from '../constants'
import Button from './Button'
import { LeftCurve, RightCurve } from './design/Collaboration'
import Section from './Section'
import ailatics from '../../../../Assets/Images/aiLatics-Logo.png'
import { useState } from 'react'

const Collaboration = () => {
  const [hoveredItem, setHoveredItem] = useState(false)
  return (
    <Section crosses>
      <div className='container lg:flex'>
        <div className='max-w-[25rem]'>
          <h2 className='h2 mb-4 md:mb-8'>
            Ailatics Revolutionizing Social Media{' '}
            <span className='inline-block relative font-semibold'>
              Management
              <img
                src={curve}
                className='absolute top-full left-0 w-full xl:-mt-2 pointer-events-none select-none'
                width={624}
                height={28}
                alt='Curve'
              />
            </span>
          </h2>
          <h6 className='body-2 text-n-4'>
            Seamlessly manage, create, and optimize your digital presence with
            cutting-edge AI-powered tools.
          </h6>
          <ul className='max-w-[30rem] mb-10 md:mb-14'>
            {collabContent.map((item) => (
              <li className='mb-3 py-3' key={item.id}>
                <div
                  onMouseEnter={() => setHoveredItem(item.id)}
                  onMouseLeave={() => setHoveredItem(false)}
                  className={`flex items-center text-white cursor-pointer text-center transition-opacity duration-700 delay-300 ${
                    hoveredItem === item.id ? 'opacity-100' : 'opacity-70'
                  }`}
                >
                  <img
                    src={check}
                    width={24}
                    alt='check'
                    height={24}
                    className='pointer-events-none select-none'
                  />
                  <h6 className='body-2 ml-2'>{item.title}</h6>
                </div>
                <div
                  className={`overflow-hidden transition-all duration-500 ease-in-out ${
                    hoveredItem === item.id ? 'max-h-[200px]' : 'max-h-0'
                  }`}
                >
                  {item.text && (
                    <p
                      className={`body-2 mt-2 text-n-4 transition-all duration-500 ease-in-out  ${
                        hoveredItem === item.id
                          ? 'opacity-100 translate-y-0'
                          : 'opacity-0 translate-y-2'
                      }`}
                    >
                      {item.text}
                    </p>
                  )}
                </div>
              </li>
            ))}
          </ul>
          <Button>Try it now</Button>
        </div>
        <div className='lg:ml-auto xl:w-[38rem] mt-4'>
          <p className='body-2 mb-4 text-n-4 md:mb-16 lg:mb-32 lg:w-[32rem] lg:mx-auto'>
            {socialCollabTextTwo}
          </p>
          <div className='relative left-1/2 flex w-[22rem] aspect-square border border-n-6 rounded-full -translate-x-1/2 scale-75 md:scale-100'>
            <div className='flex w-60 aspect-square m-auto border border-n-6 rounded-full'>
              <div className='w-[6rem] aspect-square m-auto p-[0.2rem] bg-conic-gradient rounded-full'>
                <div className='flex items-center justify-center w-full h-full bg-n-8 rounded-full'>
                  <img src={ailatics} width={52} height={52} alt='ailatics' />
                </div>
              </div>
            </div>
            <ul>
              {collabApps.map((app, i) => (
                <li
                  key={app.id}
                  className={`absolute top-0 left-1/2 h-1/2 -ml-[1.6rem] origin-bottom rotate-${
                    i * 45
                  }`}
                >
                  <div
                    className={`relative -top-[1.6rem] flex w-[3.2rem] h-[3.2rem] bg-n-7 border border-n-1/15 rounded-xl -rotate-${
                      i * 45
                    } animate-pulse`}
                  >
                    <img
                      src={app.icon}
                      alt={app.title}
                      width={app.height}
                      height={app.height}
                      className='m-auto'
                    />
                  </div>
                </li>
              ))}
            </ul>
            <LeftCurve />
            <RightCurve />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Collaboration

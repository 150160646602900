import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Menu, MenuItem, Typography, Divider } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import UserContext from '../../../../Context/userContext'
import image from '../../../../Assets/Images/rectangle.png'
import {
  archiveSpacesAPI,
  getAllRolesAPI,
  getSpecificSpacesAPI,
  workspaceMembersAPI,
} from '../../../../API'
import { Loader } from '@infostacks/ui-components'
import PlatformsSectionWorkspaceProfilePage from './platformsSectionWorkspaceProfilePage'
import InvitedMembersTable from './invitedMembersTable'
import CustomMoreVertRoundedButton from '../../../ReusableComponents/customMoreVertRoundedButton'
import AddMembersFormDialog from './addMemberFormDialog'
import { ArrowDropDown } from '@mui/icons-material'
import { useTheme } from '@emotion/react'
import workspaceProfilePageStyle, {
  addButtonStyle,
  categoryText,
  custom_Button,
  descriptionText,
  editButonBox,
  main_Box,
  userWorkspaceName,
  switcher_Button_Style,
  spaceDate,
  switcherButton,
  switcherText_Style,
  menu_Style,
  websiteText,
  allRolesBtn,
} from './workspaceProfilePageStyle'
import WorkspaceMemberTable from './WorkSpaceMemberTable/workspaceMemberTable'
import CustomMenu from '../../../ReusableComponents/customMenu'
import CustomSnackbar from '../../../ReusableComponents/customSnackbar'
import CustomDialog from '../../DashboardMainSection/dialog'
import { message } from 'antd'

const WorkspaceProfilePage = () => {
  const [switchButton, setSwitchButton] = useState('Workspace Members')
  const [activeButton, setActiveButton] = useState('members')
  const [optionBtn, setOptionBtn] = useState('Workspace Members')
  const [spaceData, setSpaceData] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showButton, setShowButton] = useState(true)
  const [workspaceMembers, setWorkspaceMembers] = useState([])
  const [acceptedMembers, setAcceptedMembers] = useState([])
  const [pendingMembers, setPendingMembers] = useState([])
  console.log(
    'acceptedMembers',
    acceptedMembers,
    'pendingMembers',
    pendingMembers
  )
  const [apiError, setApiError] = useState()
  const [actionDetect, setActionDetect] = useState(false)
  const [render, setRender] = useState(false)
  const [renderInviteTable, setRenderInviteTable] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()
  // console.log('workspaceMembers',workspaceMembers);
  // console.log('acceptedMembers', acceptedMembers)
  const { userAuth, currentUser, stateCheck } = useContext(UserContext)
  const spaceId = window.sessionStorage.getItem('spaceId')
  const [roles, setRoles] = useState([])
  const [archiveDialog, setArchiveDialog] = useState(false)
  const [currentIndexId, setCurrentIndexId] = useState()
  let menuItems = []

  // console.log('spaceData._id', spaceData?._id)

  const getSpaceData = async () => {
    setLoading(true)
    try {
      const fetchSpace = await getSpecificSpacesAPI(
        userAuth,
        currentUser,
        spaceId
      )
      setSpaceData(fetchSpace)
      setCurrentIndexId(fetchSpace._id)
      const allRoles = await getAllRolesAPI(userAuth, spaceId)
      // console.log('fetchSpace', fetchSpace)
      setRoles(allRoles)
      const workspaceMemberResponse = await workspaceMembersAPI(
        userAuth,
        spaceId
      )
      console.log('workspaceMemberResponse', workspaceMemberResponse)
      const acceptStatusMember = workspaceMemberResponse.filter(
        (val) => val.status === 'accepted'
      )
      const pendingStatusMember = workspaceMemberResponse.filter(
        (val) => val.status === 'pending'
      )
      setWorkspaceMembers(workspaceMemberResponse)
      setAcceptedMembers(acceptStatusMember)
      setPendingMembers(pendingStatusMember)
    } catch (error) {
      console.error('Error fetching space data', error)
    } finally {
      setLoading(false)
    }
  }

  const archiveSpace = async (option, id, deleteObject) => {
    // console.log('option', option, 'id', id,'deleteObject',deleteObject)
    if (option === 'Archive' && userAuth) {
      try {
        const resp = await archiveSpacesAPI(userAuth, id, deleteObject)
               console.log('archive',resp)
        // getAllSpaces()
        setArchiveDialog(!archiveDialog)
        message.success('Workspace has been archived successfully')
        navigate('/dashboard')
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  const formatDateString = (dateString) => {
    // const options = { year: 'numeric', month: 'short', day: 'numeric',hour: 'numeric', minute: 'numeric',second: 'numeric' }
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    )
    return formattedDate
  }

  useEffect(() => {
    const fetchData = async () => {
      if (userAuth) {
        await getSpaceData()
      }
    }
    fetchData()
  }, [userAuth, render, stateCheck, renderInviteTable])

  const editWorkspace = () => {
    navigate('/edit-workspace', { state: { spaceData: spaceData } })
  }
  const handleClickArchived = () => {
    navigate('/dashboard/archived-spaces')
  }
  const handleMoreMenuClick = (event) => {
    setMoreMenuAnchorEl(event.currentTarget)
  }

  const handleMoreMenuClose = () => {
    setMoreMenuAnchorEl(null)
  }

  const handleSectionMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSectionMenuClose = () => {
    setAnchorEl(null)
  }

  const handleClickDialog = (state) => {
    setOpenDialog(state)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleNavigate = () => {
    navigate(`/dashboard/roles`)
  }

  if (spaceData?.Permissions?.workSpace['Edit'] === true) {
    menuItems.push({
      label: 'Edit',
      onClick: editWorkspace,
    })
  }

  // Check for 'Delete' permission
  if (spaceData?.Permissions?.workSpace['Delete'] === true) {
    menuItems.push({
      label: 'Archived',
      onClick: () => setArchiveDialog(true),
    })
  }

  console.log('spaceData', spaceData)
  // const menuItems = [
  //   {
  //     label: 'Edit',
  //     onClick: editWorkspace,
  //   },
  //   {
  //     label: 'Archived',
  //     onClick: ()=>setArchiveDialog(true),
  //   },

  // ]
  const theme = useTheme()

  return (
    <Box sx={workspaceProfilePageStyle.parentDiv(theme)}>
      {actionDetect && <CustomSnackbar status={apiError} />}
      {loading ? (
        <Box sx={workspaceProfilePageStyle.circularProgressBox}>
          <Loader thickness={6} sx={{ color: 'blue' }} />
        </Box>
      ) : (
        <>
          <Box sx={main_Box(theme)}>
            <Box sx={workspaceProfilePageStyle.img_Box(theme)}>
              <img
                src={spaceData?.imageUrl || image}
                alt='space-img'
                style={workspaceProfilePageStyle.imageProfile_Style}
              />
            </Box>
            <Box sx={workspaceProfilePageStyle.workspace_Info_Box}>
              <Box sx={editButonBox()}>
                <Box sx={workspaceProfilePageStyle.text_Name_Category_UserBox}>
                  <Typography sx={userWorkspaceName(theme)}>
                    {spaceData?.name || 'Workspace Name'}
                  </Typography>
                  <Box sx={workspaceProfilePageStyle.ancorTag_Box}>
                    <a
                      href={spaceData?.websiteLink}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={websiteText(theme)}
                    >
                      {spaceData?.websiteLink || 'Website Link'}
                    </a>
                    <Typography>-</Typography>
                    <Typography sx={categoryText(theme)}>
                      {spaceData?.category || 'Category'}
                    </Typography>
                  </Box>
                </Box>
                {spaceData?.Permissions?.workSpace['Edit'] === true ||
                spaceData?.Permissions?.workSpace['Delete'] === true ? (
                  <CustomMoreVertRoundedButton onClick={handleMoreMenuClick} />
                ) : null}
              </Box>
              <Typography sx={descriptionText(theme)}>
                {spaceData?.description || 'No description available'}
              </Typography>
              <Typography sx={spaceDate(theme)}>
                {formatDateString(spaceData?.createdAt)}
              </Typography>
            </Box>
          </Box>
          <Box sx={workspaceProfilePageStyle.userData_Card}>
            <Box sx={workspaceProfilePageStyle.button_Box}>
              <span style={workspaceProfilePageStyle.span_BtnStyle}>
                <Button
                  onClick={() => {
                    setSwitchButton('integrated')
                    setActiveButton('platforms')
                    setShowButton(false)
                  }}
                  sx={custom_Button(activeButton, 'platforms', theme)}
                >
                  Integrated Platforms
                </Button>
                <Box
                  sx={switcherButton(switchButton, optionBtn, theme)}
                  onClick={() => {
                    setSwitchButton(optionBtn)
                    setActiveButton('members')
                    setShowButton(true)
                  }}
                >
                  <Typography
                    sx={switcherText_Style(switchButton, optionBtn, theme)}
                  >
                    {optionBtn}
                  </Typography>
                  <Divider
                    orientation='vertical'
                    sx={{
                      height: '50%',
                      backgroundColor: '#2563EB',

                      ...(activeButton === 'members' && {
                        backgroundColor: '#FFF',
                      }),
                    }}
                  />
                  <ArrowDropDown
                    onClick={handleSectionMenuClick}
                    sx={{
                      color:
                        switchButton === optionBtn
                          ? theme.palette.customWhite.main
                          : '#2563EB',
                      '&:hover': {
                        color: theme.palette.customWhite.main,
                      },
                      ...(activeButton === 'members' && {
                        color: theme.palette.customWhite.main,
                      }),
                    }}
                  />
                </Box>
              </span>
              {showButton && (
                <Box sx={{ display: 'flex', gap: '8px', backgroundColor: '' }}>
                  <Button
                    onClick={handleNavigate}
                    sx={allRolesBtn(theme, spaceData)}
                  >
                    All Roles
                  </Button>
                  <Button
                    onClick={() => handleClickDialog(true)}
                    sx={addButtonStyle(
                      theme,
                      currentUser,
                      acceptedMembers,
                      pendingMembers,
                      spaceData
                    )}
                  >
                    Add Members
                  </Button>
                </Box>
              )}
            </Box>

            {switchButton === 'integrated' ? (
              <PlatformsSectionWorkspaceProfilePage
                spaceDataPlatforms={spaceData?.platforms}
              />
            ) : switchButton === 'members' ? (
              <WorkspaceMemberTable spaceData={spaceData} />
            ) : null}
            {switchButton === 'Workspace Members' ? (
              <WorkspaceMemberTable
                acceptedMembers={acceptedMembers}
                roles={roles}
                setRender={setRender}
                render={render}
                setApiError={setApiError}
                setActionDetect={setActionDetect}
                spaceData={spaceData}
              />
            ) : switchButton === 'Invited Members' ? (
              <InvitedMembersTable
                renderInviteTable={renderInviteTable}
                setRenderInviteTable={setRenderInviteTable}
                spaceId={spaceId}
                spaceData={spaceData}
              />
            ) : null}
          </Box>
        </>
      )}

      <CustomMenu
        anchorEl={moreMenuAnchorEl}
        menuItems={menuItems}
        open={moreMenuAnchorEl}
        onClose={handleMoreMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSectionMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: '8px' }}
        PaperProps={{
          sx: menu_Style(theme),
        }}
      >
        <MenuItem
          onClick={() => {
            setOptionBtn('Workspace Members')
            setSwitchButton('Workspace Members')
            handleSectionMenuClose()
          }}
          sx={switcher_Button_Style(theme)}
        >
          Workspace Members
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOptionBtn('Invited Members')
            setSwitchButton('Invited Members')
            handleSectionMenuClose()
          }}
          sx={switcher_Button_Style(theme)}
        >
          Invited Members
        </MenuItem>
      </Menu>
      <AddMembersFormDialog
        open={openDialog}
        rolesName={roles?.map((val) => val.roleName)}
        roles={roles}
        setRoles={setRoles}
        close={handleCloseDialog}
        spaceData={spaceData}
        renderInviteTable={renderInviteTable}
        setRenderInviteTable={setRenderInviteTable}
      />
      {archiveDialog ? (
        <CustomDialog
          open={archiveDialog}
          close={setArchiveDialog}
          archiveSpace={archiveSpace}
          id={currentIndexId}
        />
      ) : null}
    </Box>
  )
}

export default WorkspaceProfilePage

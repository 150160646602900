import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Pagination,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { MoreHoriz, StarRounded } from '@mui/icons-material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import google from '../../../Assets/Images/google.png'
import facebook from '../../../Assets/Images/facebook.png'
import linkdin from '../../../Assets/Images/linkedin.png'
import instagram from '../../../Assets/Images/instagram.png'
import x from '../../../Assets/Images/x.png'
import youtube from '../../../Assets/Images/ytcircle.png'
import pinterest from '../../../Assets/Images/pinterest.png'
import tiktok from '../../../Assets/Images/tiktok.png'
import ArchiveMenuOptions from './archiveMenuOptions'
import ArchiveDialog from './archiveDialog'
import { message } from 'antd'
import UserContext from '../../../Context/userContext'
import { archiveSpacesAPI, getArchiveSpacesAPI } from '../../../API'
import SpaceSkeleten from '../DashboardMainSection/spaceSkeleten'
import archivedSpacesStyle, {
  archivedSpaceHeaderText,
  space_favourite_button,
  style_Pagination,
} from './archivedSpacesStyle'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@emotion/react'

const ArchivedSpacesSection = () => {
  const {
    userAuth,
    isSkelton,
    setIsSkelton,
    currentUser,
    setActiveSidebarOption,
  } = useContext(UserContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const [dialog, setDialog] = useState(false)
  const open = Boolean(anchorEl)
  const [archivedSpaces, setArchivedSpaces] = useState([])
  const [currentIndexId, setCurrentIndexId] = useState(null)
  const [page, setPage] = useState(1)
  const [itemsPerPage] = useState(8)
  const location = useLocation()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const currentPageData = archivedSpaces.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  )
  const selectMenu = (option, index) => {
    console.log('option', option)
    if (option === 'unarchive') {
      setDialog(true)
      setAnchorEl(null)
    } else {
      setAnchorEl(null)
    }
  }

  const UnarchiveSpace = async (id, obj, option) => {
    // console.log('id', id, 'obj', obj)
    if (option === 'unarchive' && userAuth) {
      try {
        const resOfReady = await archiveSpacesAPI(userAuth, id, obj)
        console.log('ready', resOfReady)
        getArchivedSpaces()
        setDialog(!dialog)
        message.success('Workspace has been active successfully')
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  const formatDateString = (dateString) => {
    // const options = { year: 'numeric', month: 'short', day: 'numeric',hour: 'numeric', minute: 'numeric',second: 'numeric' }
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    )
    return formattedDate
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const captureCurrentId = (id) => {
    setCurrentIndexId(id)
  }

  const getArchivedSpaces = async () => {
    setIsSkelton(true)
    if (currentUser && userAuth) {
      try {
        const response = await getArchiveSpacesAPI(userAuth, currentUser)
        setArchivedSpaces(response)
        setIsSkelton(false)
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  useEffect(() => {
    setActiveSidebarOption(location.pathname)

    const fetchData = async () => {
      if (userAuth && currentUser) {
        await getArchivedSpaces()
      }
    }
    fetchData()
  }, [userAuth, currentUser])
  const theme = useTheme()

  const color = theme.palette.background.workspace
  const svgBackground = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='334' height='220' viewBox='0 0 334 220' fill='none'><g filter='url(%23filter0_f_13590_13517)'><circle cx='351' cy='-6' r='75' fill='${encodeURIComponent(
    color
  )}' /></g><defs><filter id='filter0_f_13590_13517' x='0.5' y='-356.5' width='701' height='701' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'><feFlood flood-opacity='0' result='BackgroundImageFix' /><feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' /><feGaussianBlur stdDeviation='137.75' result='effect1_foregroundBlur_13590_13517' /></filter></defs></svg>")`

  return (
    <Box sx={archivedSpacesStyle.parentDiv(theme)}>
      <Box sx={archivedSpacesStyle.archivedSpaceHeader}>
        <Typography sx={archivedSpaceHeaderText(theme)}>
          Archived Spaces
        </Typography>
      </Box>
      {isSkelton
        ? Array.from({ length: 4 }).map((_, index) => (
            <SpaceSkeleten key={index} />
          ))
        : archivedSpaces &&
          archivedSpaces.map((val, ind) => (
            <Box
              key={ind}
              sx={{
                ...archivedSpacesStyle.space_Main_Box(theme),
                position: 'relative',
                overflow: 'hidden',
                background: svgBackground,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <Box sx={archivedSpacesStyle.first_section_of_space_main_box}>
                <img
                  src={val.imageUrl}
                  alt='space-logo'
                  style={archivedSpacesStyle.spaceImageStyle}
                />
                <Box sx={archivedSpacesStyle.space_detail_box}>
                  <Typography sx={archivedSpacesStyle.space_name(theme)}>
                    {val.name}
                  </Typography>
                  <Typography
                    sx={archivedSpacesStyle.space_website_and_category(theme)}
                  >
                    {val.websiteLink} - {val.category}
                  </Typography>
                </Box>
                <IconButton sx={space_favourite_button(val, theme)}>
                  <StarRounded />
                </IconButton>
              </Box>
              <Typography sx={archivedSpacesStyle.space_description(theme)}>
                {val.description}
              </Typography>

              <Box sx={archivedSpacesStyle.avtarBox}>
                <AvatarGroup max={5} sx={archivedSpacesStyle.avtarGroup}>
                  {val.platforms.map((item, index) => {
                    const iconSrc =
                      item.name === 'google'
                        ? google
                        : item.name === 'facebook'
                        ? facebook
                        : item.name === 'linkdin'
                        ? linkdin
                        : item.name === 'instagram'
                        ? instagram
                        : item.name === 'x'
                        ? x
                        : item.name === 'youtube'
                        ? youtube
                        : item.name === 'pinterest'
                        ? pinterest
                        : item.name === 'tiktok'
                        ? tiktok
                        : 'null'
                    return (
                      <Avatar
                        key={index}
                        src={iconSrc}
                        alt='platform'
                        sx={archivedSpacesStyle.avtarStyle}
                      />
                    )
                  })}
                </AvatarGroup>
                <IconButton sx={archivedSpacesStyle.iconButtonStyle}>
                  <AddRoundedIcon />
                </IconButton>
              </Box>
              <Box sx={archivedSpacesStyle.date_and_seeMore_box}>
                <Typography sx={archivedSpacesStyle.workspace_date(theme)}>
                  {formatDateString(val.createdAt)}
                </Typography>
                <IconButton
                  onClick={(event) => {
                    handleClick(event)
                    captureCurrentId(val._id)
                  }}
                  sx={archivedSpacesStyle.space_seeMore_button}
                >
                  <MoreHoriz sx={{ color: theme.palette.text.main }} />
                </IconButton>
                <ArchiveMenuOptions
                  anchorEl={anchorEl}
                  selectMenu={selectMenu}
                  open={open}
                  id={currentIndexId}
                />
                {dialog ? (
                  <ArchiveDialog
                    open={dialog}
                    close={setDialog}
                    UnarchiveSpace={UnarchiveSpace}
                    id={currentIndexId}
                  />
                ) : null}
              </Box>
            </Box>
          ))}
      {archivedSpaces && archivedSpaces.length > 8 && (
        <Box sx={archivedSpacesStyle.pagination_Box}>
          <Pagination
            count={Math.ceil(archivedSpaces.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            sx={style_Pagination(theme)}
          />
        </Box>
      )}
    </Box>
  )
}

export default ArchivedSpacesSection

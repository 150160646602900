import { useTheme } from '@emotion/react';
import { AddOutlined, ExpandMoreOutlined, GridView, SupportAgent } from '@mui/icons-material'
import { Box, Collapse, Typography } from '@mui/material'
import React, { useState } from 'react'
import agent from './../../../Assets/Images/content.png'
import support from './../../../Assets/Images/elements.png'
import ticket from './../../../Assets/Images/ticket.png'


const SideBarAiAgents = () => {
     const [isExpanded, setIsExpanded] = useState(false);
     const theme = useTheme()
     const toggleExpand = () => {
        setIsExpanded((prev) => !prev);
      };

      const options = [
        { name: 'Support', src: support },
        // { name: 'Tickets', src: ticket },
      ]
  return (
    <>
    <Box
        onClick={toggleExpand}
        sx={{
          background:isExpanded ? '#F5F6F8' : '',
          width: '100%',
          height:'48px',
          display: 'flex',
          alignItems: 'center',
          boxSizing: 'border-box',
          padding: '12px 16px',
          borderRadius: '12px',
          cursor: 'pointer',
          gap: 'var(--Components-Badge-Spacing-Y-LG, 12px)',
          border: isExpanded ? '1px solid #DFE0E7' : '1px solid white',
          transition: 'background-color 0.3s ease',
          '&:hover': {
            background: '#E1E4EA',
            border:'1px solid #E6E7EC',
          
  },
        }}
      >
        <img src={agent} alt='' style={{height: '24px',width: '24px',objectFit: 'cover',}} />
        <Typography
          sx={{
            color:isExpanded ? '#0C0C0C' : '#494949',
            lineHeight: '24px',
            fontSize: '16px',
            fontWeight: isExpanded ? 500 : 400,
            width: 'auto',
            display: 'flex',
            fontFamily:theme.typography.h4.fontFamily
          }}
        >
          Ai Agent
        </Typography>

        <AddOutlined
          sx={{
            color:'#0C0C0C',
            height: '24px',
            width: '24px',
            ml: 'auto',
            display: 'flex',
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      </Box>

      {/* Expandable Content */}
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            paddingLeft: '26px',
            backgroundColor: 'white',
            borderRadius: '8px',
            marginTop: '8px',
            gap:'4px',
            display:'flex',
            flexDirection:'column'
          }}
        >
          {options.map((val,ind)=>(
          <Box key={ind} sx={{ color: '#494949',height:'40px',cursor:'pointer', fontSize: '14px',boxSizing:'border-box',padding:'8px 10px',display:'flex',justifyContent:'',alignItems:'center',gap:'16px',borderRadius:'12px',":hover":{borderRadius:'12px',background:'#E1E4EA'} }}>
         <img src={val.src} alt='' style={{height: '24px',width: '24px',objectFit: 'cover',}} />
          <Typography sx={{color:'#0C0C0C',lineHeight: '24px',fontSize: '14px',fontWeight: 400,fontFamily:theme.typography.h4.fontFamily,textTransform:'capitalize'}}>{val.name}</Typography>
          </Box>
         ))}
        </Box>
      </Collapse>
      </>
  )
}

export default SideBarAiAgents
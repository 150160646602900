const navStyles = {
  navLogo: {
    height: { xs: '35px', sm: '50px' },
    width: { xs: '60px', sm: '50px' },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  NavCenterOptions: {
    gap: '26px',
    display: { xs: 'none', md: 'flex' },
    alignItems: 'center',
    visibility: { xs: 'hidden', md: 'visible' },
  },

  NavButtonsBox: {
    display: 'flex',
    background: '',
    gap: '10px',
  },
}

export default navStyles
export const NavMainBox = (theme) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#28282A',
  width: '100%',
  paddingY: { xs: '10px', sm: '10px', md: '16px' },
  paddingX: { xs: '10px', md: '16px' },
  borderRadius: { xs: '8px', md: '16px' },
  boxSizing: 'border-box',
})
export const customNavOptionButton = (theme) => ({
  fontWeight: theme.typography.h2.fontWeight,
  fontFamily: theme.typography.h3.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  color: '#fff',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: 'transparent',
  },
})

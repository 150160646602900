import React, { useContext, useState } from 'react'
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import UserContext from '../../../../../../Context/userContext'

const CommentsandRatings = ({ comments, setComments, loading }) => {
  const handleChange = (event) => {
    setComments(event.target.value)
  }
  const { postData } = useContext(UserContext)

  const theme = useTheme()

  return (
    <Box
      sx={{
        width: '550px',
        display: postData ? 'none' : 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <span style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Typography
          sx={{
            color: theme.palette.text.main,
            fontFamily: theme?.typography.h2.fontFamily,
            fontSize: theme?.typography.h4.fontSize,
            fontWeight: theme?.typography.h4.fontWeight,
            fontStyle: 'normal',
            lineHeight: '24px',
          }}
        >
          Comments and ratings
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.light,
            fontFamily: theme?.typography.h2.fontFamily,
            fontSize: theme?.typography.paragraph.fontSize,
            fontWeight: theme?.typography.h5.fontWeight,
            fontStyle: 'normal',
            lineHeight: '20px',
          }}
        >
          Choose if and how you want to show comments
        </Typography>
      </span>

      <RadioGroup
        aria-labelledby='audience-radio-group-label'
        name='audience-radio-group'
        value={comments} // Controlled value
        onChange={handleChange} // Handle state update
        sx={{
          '& .MuiFormControlLabel-root': {
            marginBottom: '-8px', // Reduce the bottom margin
          },
        }}
      >
        <FormControlLabel
          disabled={loading}
          value='true'
          sx={{
            color: theme.palette.text.light,
            fontFamily: theme.typography.h2.fontFamily,
          }}
          control={
            <Radio
              sx={{
                color: theme.palette.text.main,
                '&.Mui-checked': {
                  color: theme.palette.text.main,
                },
              }}
            />
          }
          label='On'
          componentsProps={{
            typography: {
              sx: {
                fontFamily: theme.typography.h2.fontFamily,
                color: theme.palette.text.light,
                fontSize: theme?.typography.h5.fontSize,
                fontWeight: theme?.typography.h5.fontWeight,
              },
            },
          }}
        />
        <FormControlLabel
          disabled={loading}
          value='false'
          sx={{
            color: theme.palette.text.light,
            fontFamily: theme.typography.h2.fontFamily,
          }}
          control={
            <Radio
              sx={{
                color: theme.palette.text.main,
                '&.Mui-checked': {
                  color: theme.palette.text.main,
                },
              }}
            />
          }
          label='Off'
          componentsProps={{
            typography: {
              sx: {
                fontFamily: theme.typography.h2.fontFamily,
                color: theme.palette.text.light,
                fontSize: theme?.typography.h5.fontSize,
                fontWeight: theme?.typography.h5.fontWeight,
              },
            },
          }}
        />
      </RadioGroup>
    </Box>
  )
}

export default CommentsandRatings

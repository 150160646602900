import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import SendRoundedIcon from '../../../../../Assets/Images/sendIcon.png'
import { useTheme } from '@emotion/react'
import {
  emptyScrHoverText,
  emptyScrSeachHover,
  emptyScrSearchSendicon,
  emptyScrSearchUploadicon,
  emptyScrTextField,
  homePage,
} from '../../homePageStyle'
import {
  Arrow,
  SendTextIcon,
  UploadFileIcon,
} from '../../../../PasswordStartAdornment/svgFile'

const SearchBar = ({ setValue, setShowPost, prompt }) => {
  const theme = useTheme()
  const [inputValue, setInputValue] = useState(prompt || '')
  const [isHovered, setIsHovered] = useState(false)

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleNextPage = () => {
    if (inputValue.trim() !== '') {
      setValue(inputValue)
      setShowPost(true)
      setInputValue('')
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleNextPage()
    }
  }
  useEffect(() => {
    if (prompt && prompt !== inputValue) {
      setInputValue(prompt)
    }
  }, [prompt])

  return (
    <Box sx={homePage.emptySrcSearch}>
      <Box sx={emptyScrSeachHover(isHovered)}>
        <Typography sx={emptyScrHoverText(theme)}>coming soon</Typography>
        <Box sx={{ marginLeft: '-5px' }}>
          <Arrow />
        </Box>
      </Box>
      <TextField
        multiline
        fullWidth
        autoFocus
        maxRows={3}
        sx={homePage.searchBarTextField(theme)}
        placeholder='Type your post idea or trending topic...'
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        InputProps={{
          startAdornment: (
            <Box
              sx={emptyScrSearchUploadicon(theme)}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <UploadFileIcon sx={{ color: theme.palette.text.svg }} />
            </Box>
          ),
          endAdornment: (
            <Button
              sx={emptyScrSearchSendicon(theme, inputValue)}
              onClick={handleNextPage}
            >
              <SendTextIcon color={theme.palette.background.default} />
            </Button>
          ),
          sx: emptyScrTextField(theme),
        }}
      />
    </Box>
  )
}

export default SearchBar

import { useTheme } from '@emotion/react'
import { CloseRounded } from '@mui/icons-material'
import { Box, Button, Divider, Typography } from '@mui/material'
import GenrateSection from './genrateSection'
import MediaSection from './mediaSection'
import PromptField from './promptField'
import DescriptionField from './descriptionField'
import Thumbnail from './thumbnail'
import PlaylistYoutube from './playlistYoutube'
import ShowMore from '../ShowMore'
import { Loader } from '@infostacks/ui-components'
import UserContext from '../../../../../../Context/userContext'
import { useContext } from 'react'

const FormPostModal = ({
  handleClose,
  file,
  promptField,
  setPromptField,
  descriptionField,
  setDescriptionField,
  thumbnail,
  setThumbnail,
  playListField,
  setPlayListField,
  register,
  setMedia,
  audience,
  setAudience,
  tags,
  setTags,
  ageRestriction,
  setAgeRestriction,
  category,
  setCategories,
  comments,
  setComments,
  setEditModal,
  setImageSrc,
  selectedCategory,
  setSelectedCategory,
  selectedPlaylist,
  setSelectedPlaylist,
  videoVisibility,
  setVideoVisibility,
  loading,
  handleEditPost,
}) => {
  const theme = useTheme()
  const { postData } = useContext(UserContext)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'start',
          flexDirection: 'column',
          alignItems: 'start',
          background: '',
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: '',
            gap: '14px',
          }}
        >
          <span
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '',
            }}
          >
            {' '}
            <Typography
              sx={{
                color: theme.palette.text.main,
                fontFamily: theme?.typography.h2.fontFamily,
                fontSize: theme?.typography.mediumText.fontSize,
                fontWeight: theme?.typography.h2.fontWeight,
                lineHeight: '30px',
                width: '100%',
              }}
            >
              Title of the Video
            </Typography>
            <CloseRounded
              onClick={handleClose}
              sx={{ cursor: 'pointer', color: theme.palette.text.main }}
            />
          </span>
          <Divider
            sx={{
              width: 'calc(100% + 44px)',
              marginLeft: '-22px',
              marginRight: '-22px',
              background: theme.palette.border.light,
            }}
          />
        </Box>
        <GenrateSection />
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            background: '',
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: '24px',
              background: '',
            }}
          >
            <PromptField
              promptField={promptField}
              setPromptField={setPromptField}
              register={register}
              loading={loading}
            />
            <DescriptionField
              descriptionField={descriptionField}
              setDescriptionField={setDescriptionField}
              register={register}
              loading={loading}
            />
          </span>
          <MediaSection file={file} setMedia={setMedia} loading={loading} />
        </Box>
        <Thumbnail
          thumbnail={thumbnail}
          setThumbnail={setThumbnail}
          register={register}
          setEditModal={setEditModal}
          setImageSrc={setImageSrc}
        />
        <PlaylistYoutube
          playListField={playListField}
          setPlayListField={setPlayListField}
          register={register}
          selectedPlaylist={selectedPlaylist}
          setSelectedPlaylist={setSelectedPlaylist}
          loading={loading}
        />
        <ShowMore
          audience={audience}
          setAudience={setAudience}
          tags={tags}
          setTags={setTags}
          register={register}
          ageRestriction={ageRestriction}
          setAgeRestriction={setAgeRestriction}
          category={category}
          setCategories={setCategories}
          comments={comments}
          setComments={setComments}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          videoVisibility={videoVisibility}
          setVideoVisibility={setVideoVisibility}
          loading={loading}
        />
        <Box
          sx={{
            display: file ? 'flex' : 'none',
            width: '100%',
            gap: '10px',
            justifyContent: 'end',
            alignItems: 'center',
            background: '',
          }}
        >
          <Button
            disabled={loading}
            onClick={handleClose}
            sx={{
              display: 'flex',
              textTransform: 'capitalize',
              color: theme.palette.customWhite.main,
              borderRadius: '8px',
              boxSizing: 'border-box',
              padding: '9px 12px',
              width: 'fit-content',
              height: '40px',
              fontSize: theme.typography.h5.fontSize,
              fontFamily: theme.typography.h2.fontFamily,
              fontWeight: theme?.typography.h3.fontWeight,
              background: theme.palette.button.dark,
              '&.Mui-disabled': {
                backgroundColor: 'lightgray',
                color: theme.palette.customWhite.main,
              },
              ':hover': {
                background: theme.palette.button.dark,
                color: theme.palette.customWhite.main,
              },
            }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            sx={{
              display: postData ? 'none' : 'flex',
              textTransform: 'capitalize',
              color: theme.palette.customWhite.main,
              borderRadius: '8px',
              boxSizing: 'border-box',
              padding: '9px 12px',
              width: '80px',
              height: '40px',
              fontSize: theme.typography.h5.fontSize,
              fontFamily: theme.typography.h2.fontFamily,
              fontWeight: theme?.typography.h3.fontWeight,
              background: theme.palette.button.dark,
              ':hover': {
                background: theme.palette.button.dark,
                color: theme.palette.customWhite.main,
              },
            }}
          >
            {loading ? (
              <Loader thickness={6} size={30} color='white' />
            ) : (
              'Upload'
            )}
          </Button>
          <Button
            onClick={handleEditPost}
            sx={{
              display: postData ? 'flex' : 'none',
              textTransform: 'capitalize',
              color: theme.palette.customWhite.main,
              borderRadius: '8px',
              boxSizing: 'border-box',
              padding: '9px 12px',
              width: '80px',
              height: '40px',
              fontSize: theme.typography.h5.fontSize,
              fontFamily: theme.typography.h2.fontFamily,
              fontWeight: theme?.typography.h3.fontWeight,
              background: theme.palette.button.dark,
              ':hover': {
                background: theme.palette.button.dark,
                color: theme.palette.customWhite.main,
              },
            }}
          >
            {loading ? (
              <Loader thickness={6} size={30} color='white' />
            ) : (
              'Save'
            )}
          </Button>
        </Box>
      </Box>
    </>
  )
}
export default FormPostModal
//fffffffff
//fffffffff

import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Card, Typography } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import UserContext from '../../../../Context/userContext'
import {
  addPost_Button_Style,
  card_Main,
  description,
  facebookServiceStyle,
  facebookServicesHeaderText,
  footerText,
  name,
} from './facebookServicesStyle'
import fb from './../../../../Assets/Images/facebook.png'
import spotify from './../../../../Assets/Images/spotify.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/bundle'
import fbLarge from './../../../../Assets/Images/fbLarge.png'
// import required modules
import { Autoplay } from 'swiper/modules'
import CustomMenu from '../../../ReusableComponents/customMenu'
import { useTheme } from '@emotion/react'
import CustomMoreVertRoundedButton from '../../../ReusableComponents/customMoreVertRoundedButton'
import { getSpecificSpacesAPI } from '../../../../API'
import { Loader } from '@infostacks/ui-components'
import emptyScreenStyle from './emptyScreenStyle'

const FacebookServices = () => {
  const { activeSpace, currentUser, userAuth, stateCheck } =
    useContext(UserContext)
  const [fbFound, setFbFound] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const currentPath = location.pathname
  const basePath = currentPath.split('/').slice(0, 3).join('/')
  // console.log('fbFound', fbFound)
  const { id } = useParams()

  // console.log('activePlatform in fb through useeffect', activePlatform)
  // console.log('activeSpace in fb', activeSpace)
  // console.log('hello')
  const faceBookData = [
    {
      integratedText: 'Integrated',
      svg: (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='48'
          height='48'
          viewBox='0 0 58 64'
          fill='none'
        >
          <path
            d='M56.236 28L61.8 16.894C61.9465 16.5876 62.0134 16.2493 61.9947 15.9102C61.9759 15.5712 61.8721 15.2423 61.6927 14.9539C61.5134 14.6656 61.2643 14.427 60.9685 14.2603C60.6726 14.0936 60.3396 14.0041 60 14H38C37.4696 14 36.9609 14.2107 36.5858 14.5858C36.2107 14.9609 36 15.4696 36 16V28C36 28.5304 35.7893 29.0391 35.4142 29.4142C35.0391 29.7893 34.5304 30 34 30H30C29.4696 30 28.9609 30.2107 28.5858 30.5858C28.2107 30.9609 28 31.4696 28 32V36C28 37.5913 28.6321 39.1174 29.7574 40.2426C30.8826 41.3679 32.4087 42 34 42H60C60.3385 41.9938 60.67 41.9027 60.9642 41.7352C61.2584 41.5676 61.5059 41.329 61.684 41.041C61.8621 40.7531 61.9651 40.4251 61.9837 40.087C62.0022 39.749 61.9356 39.4117 61.79 39.106L56.236 28Z'
            fill='#FFCE66'
          />
          <path
            d='M34 6H12C11.4696 6 10.9609 6.21071 10.5858 6.58579C10.2107 6.96086 10 7.46957 10 8V32C10 32.5304 10.2107 33.0391 10.5858 33.4142C10.9609 33.7893 11.4696 34 12 34H34C35.5913 34 37.1174 33.3679 38.2426 32.2426C39.3679 31.1174 40 29.5913 40 28V12C40 10.4087 39.3679 8.88258 38.2426 7.75736C37.1174 6.63214 35.5913 6 34 6Z'
            fill='#FFD98C'
          />
          <path
            d='M8 2C6.41387 2.01665 4.8974 2.65414 3.77577 3.77577C2.65414 4.8974 2.01665 6.41387 2 8V56C2 57.5913 2.63214 59.1174 3.75736 60.2426C4.88258 61.3679 6.4087 62 8 62C9.58358 61.9753 11.0954 61.3352 12.2153 60.2153C13.3352 59.0954 13.9753 57.5836 14 56V8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2Z'
            fill='#D5EAFE'
          />
        </svg>
      ),
      title: 'Add a post',
      description:
        'Integrating Google boosts productivity and enhances user experience on our platform.',
      integrations: '360+ Integrations',
    },
  ]
  const swiperData = [
    { name: 'Dell', image: fb },
    { name: 'Spotify', image: spotify },
    { name: 'Microsoft', image: fb },
    { name: 'Nike', image: spotify },
  ]

  const [anchorEl, setAnchorEl] = useState(null)
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const getRecentSpace = async (spaceId) => {
    setLoading(true)
    const fetchSpace = await getSpecificSpacesAPI(
      userAuth,
      currentUser,
      spaceId
    )
    // console.log('fetchSpace', fetchSpace)
    const platforms = fetchSpace.platforms?.some(
      (val) => val.name === 'facebook'
    )
    setFbFound(platforms)
    setLoading(false)
  }

  useEffect(() => {
    const spaceId = window.sessionStorage.getItem('spaceId')
    if (id !== spaceId) {
      navigate(`${basePath}/${spaceId}`)
    }
    const fetch = async () => {
      if (userAuth) {
        getRecentSpace(spaceId)
      }
    }
    fetch()
  }, [id, basePath, navigate, stateCheck, userAuth])

  const addPost = () => {
    if (fbFound) {
      navigate(`/dashboard/facebook/posts/${id}`)
    } else {
      setOpenDialog(true)
    }
  }
  const integrateNavigate = () => {
    navigate('/social-connections')
  }
  const menuItems = [
    {
      label: ' Unarchive Spaces',
      onClick: () => navigate('/dashboard/archived-spaces'),
    },
  ]
  const slideData = [
    {
      image: fb,
      title: 'Dell',
      description:
        'Dell has integrated its Facebook to boost their engagement by 37%!',
    },
    {
      image: spotify,
      title: 'Dell',
      description:
        'Dell has integrated its Facebook to boost their engagement by 37%!',
    },
    {
      image: fb,
      title: 'Dell',
      description:
        'Dell has integrated its Facebook to boost their engagement by 37%!',
    },
    {
      image: spotify,
      title: 'Dell',
      description:
        'Dell has integrated its Facebook to boost their engagement by 37%!',
    },
  ]
  const theme = useTheme()
  return (
    <Box sx={facebookServiceStyle.parentContainer(theme)}>
      {loading ? (
        <Loader thickness={7} />
      ) : (
        <>
          {!fbFound ? (
            <>
              <Box sx={emptyScreenStyle.main_Box}>
                <img
                  src={fbLarge}
                  alt=''
                  style={emptyScreenStyle.fbImage_Style}
                />
                <Typography sx={emptyScreenStyle.boostEngagement(theme)}>
                  Boost Your Engagement
                  <br /> with AI-Driven Posts!
                </Typography>
                <Typography sx={emptyScreenStyle.descText(theme)}>
                  Connect your Facebook page to start creating and
                  <br /> scheduling posts effortlessly.
                </Typography>
                <Button
                  onClick={integrateNavigate}
                  sx={emptyScreenStyle.connectFacebook_Button(theme)}
                >
                  Connect Facebook Page
                </Button>
              </Box>
              <Box sx={emptyScreenStyle.footer_Box}>
                <Typography sx={emptyScreenStyle.engagement_Text(theme)}>
                  See Who's Already Boosting Their Engagement
                </Typography>
                <Swiper
                  spaceBetween={16}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  slidesPerView={3}
                  modules={[Autoplay]}
                  fadeEffect={{ crossFade: false }}
                  className='fbSwiper'
                  style={emptyScreenStyle.swiper_Style}
                >
                  {slideData.map((slide, index) => (
                    <SwiperSlide
                      key={index}
                      style={emptyScreenStyle.swiperSlide_Style(theme)}
                    >
                      <Box sx={{ display: 'flex', gap: '16px' }}>
                        <img
                          src={slide.image}
                          alt={slide.title}
                          style={emptyScreenStyle.image_Style}
                        />
                        <Typography
                          sx={emptyScreenStyle.swiperTitle_Text(theme)}
                        >
                          {slide.title}
                        </Typography>
                      </Box>
                      <Typography sx={emptyScreenStyle.swiperDesc_Text(theme)}>
                        {slide.description}
                      </Typography>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            </>
          ) : (
            <>
              <Box sx={facebookServiceStyle.facebookServicesHeader(theme)}>
                <Typography sx={facebookServicesHeaderText(theme)}>
                  Facebook Services
                </Typography>
                <CustomMoreVertRoundedButton onClick={handleMenuOpen} />
                <CustomMenu
                  anchorEl={anchorEl}
                  onClose={handleMenuClose}
                  open={anchorEl}
                  menuItems={menuItems}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                />
              </Box>
              <Box sx={facebookServiceStyle.facebook_Map_Container(theme)}>
                {faceBookData.map((value, index) => (
                  <Card key={index} sx={card_Main(theme)}>
                    <Box sx={facebookServiceStyle.header(theme)}>
                      {value.svg}
                      <Typography sx={name(theme)}>{value.title}</Typography>
                    </Box>
                    <Typography sx={description(theme)}>
                      {value.description}
                    </Typography>
                    <Box sx={facebookServiceStyle.card_footer}>
                      <Typography sx={footerText(theme)}>
                        {value.integrations}
                      </Typography>
                      <Button
                        onClick={addPost}
                        sx={addPost_Button_Style(theme)}
                      >
                        Add post
                      </Button>
                    </Box>
                  </Card>
                ))}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  )
}
export default FacebookServices

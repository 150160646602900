import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import {
  AiIconSvgIcon,
  CustaiIconomSvgIcon,
} from '../../../../../PasswordStartAdornment'
import { useTheme } from '@emotion/react'

const GenrateSection = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        width: '550px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '',
        height: '42px',
      }}
    >
      <Typography
        sx={{
          color: theme.palette.text.main,
          fontFamily: theme?.typography.h2.fontFamily,
          fontSize: theme?.typography.h2.fontSize,
          fontWeight: theme?.typography.h2.fontWeight,
          fontStyle: 'normal',
          lineHeight: '18px',
          letterSpacing: '-0.96px',
        }}
      >
        Details
      </Typography>
      <Button
        disabled
        startIcon={
          <AiIconSvgIcon
            sx={{
              color: theme.palette.customWhite.main,

              '&.Mui-disabled': {
                color: theme.palette.text.disable,
              },
            }}
          />
        }
        sx={{
          height: '38px',
          borderRadius: 'var(--Numbers-Number-32, 64px)',
          boxSizing: 'border-box',
          padding: '11px var(--Numbers-Number-7, 14px)',
          fontSize: theme?.typography.h5.fontSize,
          fontWeight: theme?.typography.h3.fontWeight,
          fontFamily: theme?.typography.h2.fontFamily,
          background: theme.palette.primary.aiColor,
          fontStyle: 'normal',
          lineHeight: '20px',
          width: 'fit-content',
          textTransform: 'capitalize',
          color: theme.palette.customWhite.main,
          '&.Mui-disabled': {
            background: theme.palette.button.disable,
            color: theme.palette.text.disable,
          },
          ':hover': {
            background: theme.palette.primary.aiColor,
          },
        }}
      >
        Generate
      </Button>
    </Box>
  )
}

export default GenrateSection

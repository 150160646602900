import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Pagination,
  Typography,
  useMediaQuery,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import google from '../../../Assets/Images/google.png'
import facebook from '../../../Assets/Images/facebook.png'
import youtube from '../../../Assets/Images/ytcircle.png'
import instagram from '../../../Assets/Images/instagram.png'
import StarRounded from '@mui/icons-material/StarRateRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import SpaceSkeleten from './spaceSkeleten'
import MenuOptions from './menuOptions'
import CustomDialog from './dialog'
import { message } from 'antd'
import FilterOfSpaces from './filterOfSpaces'
import DashboardMainSectionStyle, {
  parentDiv,
  space_Main_Box,
  space_description,
  space_favourite_button,
  space_name,
  space_website_and_category,
  span_BoxStyle,
  style_Pagination,
  userWelcome_Text,
  welcomMain_Box,
  welcomeParagraph_Text,
  workspace_date,
} from './dashboardMainSectionStyle'
import UserContext from '../../../Context/userContext'
import {
  archiveSpacesAPI,
  getAllSpacesAPI,
  updateSpacesAPI,
} from '../../../API'
import { useTheme } from '@emotion/react'
import CustomSnackbar from '../../ReusableComponents/customSnackbar'
import { CustomFilterButton } from '../../ReusableComponents/customButtons'
import UpdateCompanyAndAccountTypeForm from './updateCompanyAndAccountTypeForm'

const formatDateString = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  )
  return formattedDate
}

const DashboardMainSection = () => {
  // const action = window.localStorage.getItem('action')
  // console.log('action',action);
  const [currentFilteredSpaces, setCurrentFilteredSpaces] = useState(null)
  console.log('currentFilteredSpaces', currentFilteredSpaces)
  const [allSpaces, setAllSpaces] = useState([])
  const {
    userAuth,
    isSkelton,
    setIsSkelton,
    currentUser,
    setFavourite,
    favourite,
    setActiveSidebarOption,
    moreCreditsAndSeatsPopup,
  } = useContext(UserContext)
  console.log('moreCreditsAndSeatsPopup', moreCreditsAndSeatsPopup)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const [dialog, setDialog] = useState(false)
  const [currentIndexId, setCurrentIndexId] = useState()
  const open = Boolean(anchorEl)
  const location = useLocation()
  const [action, setAction] = useState(null)
  const spaceId = localStorage.getItem('spaceId')
  console.log('id main', spaceId)
  const [
    updateCompanyAndAccountTypeModal,
    setUpdateCompanyAndAccountTypeModal,
  ] = useState(false)
  // const location =useLocation()
  // window.location.assign('/dashboard')

  const fullName = currentUser?.fullName
  const singleName = fullName ? fullName.split(' ') : []

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const captureCurrentId = (id) => {
    // console.log('current id', id)
    setCurrentIndexId(id)
  }

  const createWorkspaceButton = (company, accountType, workspaceLimit) => {
    if (
      company &&
      accountType === 'user' &&
      currentUser?.Subscription?.status === 'active'
    ) {
      navigate('/create-new-workspace')
    } else if (!company && accountType === 'member') {
      setUpdateCompanyAndAccountTypeModal(true)
    } else if (
      company &&
      accountType === 'user' &&
      currentUser?.Subscription?.status !== 'active'
    ) {
      navigate('/choose-plan')
    } else navigate('/dashboard/editprofile')
  }
  const selectMenu = (option, index) => {
    // console.log('option', option)
    if (option === 'archive') {
      setDialog(true)
      setAnchorEl(null)
    } else {
      setAnchorEl(null)
    }
  }

  const handleCard = (id) => {
    window.sessionStorage.setItem('spaceId', id)
    navigate(`/dashboard/workspace`)
  }
  const handlelink = (event) => {
    event.stopPropagation()
  }

  const archiveSpace = async (option, id, deleteObject) => {
    // console.log('option', option, 'id', id,'deleteObject',deleteObject)
    if (option === 'Archive' && userAuth) {
      try {
       const resp = await archiveSpacesAPI(userAuth, id, deleteObject)
       console.log('archive',resp)
        getAllSpaces()
        setDialog(!dialog)
        message.success('Workspace has been archived successfully')
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  const addSocialIntegration = (e, spaceId) => {
    e.stopPropagation()
    // console.log('agae', spaceId)
    window.sessionStorage.setItem('spaceId', spaceId)
    navigate('/social-connections')
  }

  const getAllSpaces = async () => {
    setIsSkelton(true)
    try {
      if (userAuth && currentUser) {
        const respOfGetAllSpaces = await getAllSpacesAPI(userAuth, currentUser)
        // setSpacesArray(respOfGetAllSpaces)
        const allworkspaces = JSON.stringify(respOfGetAllSpaces)
        window.localStorage.setItem('workspaces', allworkspaces)
        const fvrtArrray = respOfGetAllSpaces.filter(
          (space) => space.isFavourite === true && space.isDeleted === false
        )
        const nonFvrtArrray = respOfGetAllSpaces.filter(
          (space) => space.isFavourite === false && space.isDeleted === false
        )
        // setSpacesArray([...fvrtArrray, ...nonFvrtArrray])
        setCurrentFilteredSpaces([...fvrtArrray, ...nonFvrtArrray])
        const recentSpace = getMostRecentSpace(respOfGetAllSpaces)

        const activeSpaceId = window.sessionStorage.getItem('spaceId')
        // const activeSpace = recentSpaceString
        // ? JSON.parse(recentSpaceString)
        // : null
        if (!activeSpaceId) {
          window.sessionStorage.setItem('spaceId', recentSpace?._id)
        } else {
          window.sessionStorage.setItem('spaceId', activeSpaceId)
        }
      }
    } catch (error) {
      console.log('error', error)
    }
    setIsSkelton(false)
  }

  const getMostRecentSpace = (spaces) => {
    if (!spaces || spaces.length === 0) return null

    let mostRecentSpace = spaces[0] // Initialize with the first space

    for (let i = 1; i < spaces.length; i++) {
      const currentSpace = spaces[i]
      if (
        new Date(currentSpace.lastUpdated) >
        new Date(mostRecentSpace.lastUpdated)
      ) {
        mostRecentSpace = currentSpace
      }
    }
    return mostRecentSpace
  }

  const makeFavouriteSpace = async (space, spaceId, isFavourite, e) => {
    e.stopPropagation()
    window.localStorage.setItem('spaceId', spaceId)
    const recentSpaceString = JSON.stringify(space)
    window.localStorage.setItem('recentSpace', recentSpaceString)
    await updateSpacesAPI(userAuth, spaceId, { isFavourite: !isFavourite })
    setFavourite(!favourite)
    getAllSpaces()
  }

  const filterButton = (
    dateFilter,
    alphabeticallyFilter,
    platformFilter,
    colorFilter,
    favoriteFilter
  ) => {
    // console.log('object',dateFilter,alphabeticallyFilter,colorFilter,favoriteFilter,platformFilter)
    let filteredSpaces = [...allSpaces]

    // Apply filters based on selected options
    if (favoriteFilter) {
      filteredSpaces = filteredSpaces.filter(
        (space) => space.isFavourite === true
      )
    } else if (dateFilter) {
      filteredSpaces.sort((a, b) => new Date(a.date) - new Date(b.date))
    } else if (alphabeticallyFilter) {
      filteredSpaces.sort((a, b) => a.name.localeCompare(b.name))
    } else if (platformFilter && !colorFilter) {
      filteredSpaces = filteredSpaces.filter(
        (space) => space.platform === platformFilter
      )
    } else if (colorFilter) {
      filteredSpaces = filteredSpaces.filter(
        (space) => space.color === colorFilter
      )
    }
    setCurrentFilteredSpaces(filteredSpaces)
  }

  useEffect(() => {
    setActiveSidebarOption(location.pathname)
    const fetchData = async () => {
      if (userAuth && currentUser) {
        console.log('currentUser', currentUser)
        await getAllSpaces()
      }
    }
    fetchData()
  }, [userAuth])

  useEffect(() => {
    let actionFromState = location.state?.action
    if (actionFromState) {
      setAction(actionFromState)
      navigate(location.pathname, { state: {} }) // Clean up state
    }
  }, [])

  const [page, setPage] = useState(1)
  console.log('page', page)
  const isXs = useMediaQuery('(max-width:600px)')
  const isSm = useMediaQuery('(min-width:601px) and (max-width:899px)')
  const isMd = useMediaQuery('(min-width:900px) and (max-width:1200px)')
  const isLg = useMediaQuery('(min-width:1201px) and (max-width:1535px)')
  const isXl = useMediaQuery('(min-width:1536px)')
  const cardsPerPage = isXs ? 1 : isSm ? 4 : isMd ? 6 : isLg ? 6 : isXl ? 8 : 7
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const startIndex = (page - 1) * cardsPerPage
  const endIndex = startIndex + cardsPerPage
  const theme = useTheme()

  const color = theme.palette.background.workspace
  const svgBackground = `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='334' height='220' viewBox='0 0 334 220' fill='none'><g filter='url(%23filter0_f_13590_13517)'><circle cx='351' cy='-6' r='75' fill='${encodeURIComponent(
    color
  )}' /></g><defs><filter id='filter0_f_13590_13517' x='0.5' y='-356.5' width='701' height='701' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'><feFlood flood-opacity='0' result='BackgroundImageFix' /><feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' /><feGaussianBlur stdDeviation='137.75' result='effect1_foregroundBlur_13590_13517' /></filter></defs></svg>")`

  return (
    <div style={parentDiv(theme)}>
      {action && <CustomSnackbar status={action} />}
      <FilterOfSpaces
        filterButton={filterButton}
        createWorkspaceButton={createWorkspaceButton}
        currentUser={currentUser}
        currentFilteredSpaces={currentFilteredSpaces}
      />
      <Box sx={DashboardMainSectionStyle.skelton_Box}>
        {isSkelton
          ? Array.from({ length: 2 }).map((_, index) => (
              <SpaceSkeleten key={index} />
            ))
          : currentFilteredSpaces &&
            currentFilteredSpaces
              .slice(startIndex, endIndex)
              .map((val, ind) => (
                <Box
                  key={ind}
                  onClick={() => handleCard(val._id)}
                  sx={{
                    ...space_Main_Box(theme),
                    position: 'relative',
                    overflow: 'hidden',

                    background: svgBackground,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <Box
                    sx={
                      DashboardMainSectionStyle.first_section_of_space_main_box
                    }
                  >
                    <img
                      src={val.imageUrl}
                      alt='space-logo'
                      style={DashboardMainSectionStyle.spaceImageStyle(theme)}
                    />
                    <Box sx={DashboardMainSectionStyle.space_detail_box}>
                      <Typography
                        onClick={(e) => handlelink(e)}
                        sx={space_name(theme)}
                      >
                        <a
                          href={val.websiteLink}
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          {val.name}
                        </a>
                      </Typography>
                      <Typography sx={space_website_and_category(theme)}>
                        {val.category}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={(e) =>
                        makeFavouriteSpace(val, val._id, val.isFavourite, e)
                      }
                      sx={space_favourite_button(val, theme)}
                    >
                      <StarRounded />
                    </IconButton>
                  </Box>
                  <Typography sx={space_description(theme)}>
                    {val.description}
                  </Typography>
                  <Box sx={DashboardMainSectionStyle.avtarBox}>
                    <AvatarGroup
                      max={5}
                      sx={DashboardMainSectionStyle.avtarGroup(theme)}
                    >
                      {val.platforms.map((item, index) => {
                        const iconSrc =
                          item.name === 'google'
                            ? google
                            : item.name === 'facebook'
                            ? facebook
                            : item.name === 'instagram'
                            ? instagram
                            : item.name === 'youtube'
                            ? youtube
                            : 'null'
                        return (
                          <Avatar
                            onClick={(e) => e.stopPropagation()}
                            key={index}
                            src={iconSrc}
                            alt='platform'
                            sx={DashboardMainSectionStyle.avtarStyle(theme)}
                          />
                        )
                      })}
                    </AvatarGroup>
                    <IconButton
                      disabled={
                        val?.Permissions?.workSpace?.['Add Platform'] === false
                      }
                      onClick={(e) => addSocialIntegration(e, val._id)}
                      sx={DashboardMainSectionStyle.iconButtonStyle}
                    >
                      <AddRoundedIcon />
                    </IconButton>
                  </Box>
                  <Box sx={DashboardMainSectionStyle.date_and_seeMore_box}>
                    <Typography sx={workspace_date(theme)}>
                      {formatDateString(val.createdAt)}
                    </Typography>
                    {/* <IconButton
                      sx={DashboardMainSectionStyle.space_seeMore_button}
                      onClick={(event) => {
                        event.stopPropagation()
                        handleClick(event)
                        captureCurrentId(val._id)
                      }}
                    >
                      <MoreHoriz />
                    </IconButton> */}
                    <MenuOptions
                      anchorEl={anchorEl}
                      selectMenu={selectMenu}
                      open={open}
                      id={currentIndexId}
                      allSpaces={allSpaces}
                    />
                    {dialog ? (
                      <CustomDialog
                        open={dialog}
                        close={setDialog}
                        archiveSpace={archiveSpace}
                        id={currentIndexId}
                      />
                    ) : null}
                  </Box>
                </Box>
              ))}
        {currentFilteredSpaces && currentFilteredSpaces.length > 8 && (
          <Box sx={DashboardMainSectionStyle.pagination_Box}>
            <Pagination
              count={Math.ceil(currentFilteredSpaces.length / cardsPerPage)}
              page={page}
              onChange={handleChangePage}
              sx={style_Pagination(theme)}
            />
          </Box>
        )}
      </Box>

      {!isSkelton &&
      currentFilteredSpaces &&
      currentFilteredSpaces.length === 0 ? (
        <Box sx={welcomMain_Box()}>
          <span style={span_BoxStyle()}>
            <Typography sx={userWelcome_Text(theme)}>
              Welcome {singleName[0]} !
            </Typography>
            <Typography sx={welcomeParagraph_Text(theme)}>
              Boost Your Social Media Engagement with AI Latics
            </Typography>
          </span>
          <CustomFilterButton
            onClick={() =>
              createWorkspaceButton(
                currentUser?.company,
                currentUser?.accountType
              )
            }
            buttonText={'Create Workspace '}
            fontWeight={theme.typography.h3.fontWeight}
            workspacesCount={currentUser.workspacesCount}
          />
        </Box>
      ) : null}
      {currentUser?.accountType === 'member' ? (
        <UpdateCompanyAndAccountTypeForm
          open={updateCompanyAndAccountTypeModal}
          setUpdateCompanyAndAccountTypeModal={
            setUpdateCompanyAndAccountTypeModal
          }
        />
      ) : null}
    </div>
  )
}

export default DashboardMainSection

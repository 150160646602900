import { Box, Modal } from '@mui/material'
import { PinturaEditor } from '@pqina/react-pintura'
import React from 'react'
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  setPlugins,
  plugin_crop,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_defaults,
  plugin_annotate,
  markup_editor_defaults,
} from '@pqina/pintura'
import {
  LocaleCore,
  LocaleCrop,
  LocaleFinetune,
  LocaleFilter,
  LocaleAnnotate,
  LocaleMarkupEditor,
} from '@pqina/pintura/locale/en_GB'
import '@pqina/pintura/pintura.css'

// Set plugins for the editor
setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate)

const editorDefaults = {
  utils: ['crop', 'finetune', 'filter', 'annotate'],
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...markup_editor_defaults,
  locale: {
    ...LocaleCore,
    ...LocaleCrop,
    ...LocaleFinetune,
    ...LocaleFilter,
    ...LocaleAnnotate,
    ...LocaleMarkupEditor,
  },
}

const ImageEditModelPintora = ({
  editModal,
  setEditModal,
  setThumbnail,
  imageSrc,
}) => {
  return (
    <Modal
      open={editModal}
      onClose={() => setEditModal(false)}
      sx={{
        height: '731px',
        width: '680px',
        borderRadius: '24px',
        mx: 'auto',
        my: 'auto',
        background: 'white',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'hidden', // Prevent overflow
          background: 'white', // Ensure background is visible
          borderRadius: '24px',
        }}
      >
        <PinturaEditor
          {...editorDefaults}
          src={imageSrc}
          style={{ width: '100%', height: '100%' }}
          onLoad={(res) => console.log('load image', res)}
          onProcess={({ dest }) => {
            if (dest) {
              const editedImageURL = URL.createObjectURL(dest)
              setThumbnail({ file: dest, url: editedImageURL })
              setEditModal(false)
            } else {
              console.error('Image processing failed: No destination file.')
            }
          }}
        />
      </Box>
    </Modal>
  )
}

export default ImageEditModelPintora

import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import React from 'react'
import styleAdminPageAccordian, {
  accordian_Details,
  accordionStyle,
  label_Heading,
  title_Style,
} from './styleAdminPageAccordian'
import { useTheme } from '@emotion/react'

const AdminPageAccordian = ({ title, data = [], icon }) => {
  const theme = useTheme()
  return (
    <Accordion sx={accordionStyle(theme)}>
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: theme?.palette.text.main }} />}
        sx={styleAdminPageAccordian.accordianSummry(theme)}
      >
        {icon}
        <Typography sx={title_Style(theme)}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={styleAdminPageAccordian.AccordionDetails}>
        {data.map((item, index) => (
          <Box key={index} sx={accordian_Details(theme)}>
            <label style={label_Heading(theme)}>{item}</label>
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default AdminPageAccordian

import React, { useContext } from 'react'
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import UserContext from '../../../../../../Context/userContext'

const AudienceTarget = ({ audience, setAudience, loading }) => {
  const theme = useTheme()

  const handleChange = (event) => {
    setAudience(event.target.value) // Update state based on user selection
  }
  const { postData } = useContext(UserContext)

  return (
    <Box
      sx={{
        width: '550px',
        display: postData ? 'none' : 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Typography
          sx={{
            color: theme.palette.text.main,
            fontFamily: theme?.typography.h2.fontFamily,
            fontSize: theme?.typography.h4.fontSize,
            fontWeight: theme?.typography.h4.fontWeight,
            fontStyle: 'normal',
            lineHeight: '24px',
          }}
        >
          Audience
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.light,
            fontFamily: theme?.typography.h2.fontFamily,
            fontSize: theme?.typography.paragraph.fontSize,
            fontWeight: theme?.typography.h5.fontWeight,
            fontStyle: 'normal',
            lineHeight: '20px',
          }}
        >
          Regardless of your location, you're legally required to comply with
          the Children's Online Privacy Protection Act (COPPA) and/or other
          laws. You're required to tell us whether your videos are made for
          kids.{' '}
          <a
            href='https://support.google.com/youtube/answer/9386638?hl=en'
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'underline' }}
          >
            Learn more about content made for kids
          </a>
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '16px',
        }}
      >
        <RadioGroup
          aria-labelledby='audience-radio-group-label'
          name='audience-radio-group'
          value={audience} // Controlled value
          onChange={handleChange} // Handle state update
          sx={{
            '& .MuiFormControlLabel-root': {
              marginBottom: '-8px', // Reduce the bottom margin
            },
          }}
        >
          <FormControlLabel
            disabled={loading}
            value='false'
            sx={{ color: theme.palette.text.light }}
            control={
              <Radio
                sx={{
                  color: theme.palette.text.main,
                  '&.Mui-checked': {
                    color: theme.palette.text.main,
                  },
                }}
              />
            }
            label="No, it's not made for kids"
            componentsProps={{
              typography: {
                sx: {
                  fontFamily: theme.typography.h2.fontFamily,
                  color: theme.palette.text.light,
                  fontSize: theme?.typography.h5.fontSize,
                  fontWeight: theme?.typography.h5.fontWeight,
                },
              },
            }}
          />
          <FormControlLabel
            disabled={loading}
            value='true'
            sx={{ color: theme.palette.text.light }}
            control={
              <Radio
                sx={{
                  color: theme.palette.text.main,
                  '&.Mui-checked': {
                    color: theme.palette.text.main,
                  },
                }}
              />
            }
            label="Yes, it's made for kids"
            componentsProps={{
              typography: {
                sx: {
                  fontFamily: theme.typography.h2.fontFamily,
                  color: theme.palette.text.light,
                  fontSize: theme?.typography.h5.fontSize,
                  fontWeight: theme?.typography.h5.fontWeight,
                },
              },
            }}
          />
        </RadioGroup>
      </Box>
    </Box>
  )
}

export default AudienceTarget

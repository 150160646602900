import { Box, Typography } from '@mui/material'
import { Modal } from 'antd'
import { useTheme } from '@emotion/react'
import { CloseRounded } from '@mui/icons-material'
import image from '../../../../../../Assets/Images/google.png'
import {
  CommentInstaIconSvg,
  LikeInstaIconSvg,
  ViewsIconSvg,
} from '../../../../../PasswordStartAdornment/svgFile'
import UserContext from '../../../../../../Context/userContext'
import { useContext, useEffect, useState } from 'react'
import {
  formatCount,
  getYoutubeChannelApi,
} from '../../../../../../API/PlatformsAPIs/YoutubeApi'
import { timeAgo } from '../../../../../../API/PlatformsAPIs/InstagramApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Loader } from '@infostacks/ui-components'
const ViewModelYoutube = ({ open, onCancel }) => {
  const { postData, userAuth } = useContext(UserContext)
  const [ytAccounts, setYtAccounts] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const basePath = currentPath.split('/').slice(0, 3).join('/')
  const theme = useTheme()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)

  const getYtProfileslist = async (spaceId) => {
    setLoading(true)
    try {
      const res = await getYoutubeChannelApi(userAuth, spaceId)
      setYtAccounts(res)
    } catch (error) {
      console.log('error', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    const spaceId = window.sessionStorage.getItem('spaceId')
    if (id !== spaceId) {
      navigate(`${basePath}/${spaceId}`)
    }
    const fetch = async () => {
      if (userAuth) {
        getYtProfileslist(spaceId)
      }
    }
    fetch()
  }, [])

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      centered
      maskClosable={false}
      closable={false}
      height={'fit-content'}
      width={'fit-content'}
      styles={{
        body: {
          boxSizing: 'border-box',
          padding: '0px',
        },
      }}
      style={{
        background: theme.palette.background.light,
        borderRadius: ' var(--Numbers-Number-12, 24px)',
        boxSizing: 'border-box',
        padding: '0px',
      }}
    >
      <Box
        sx={{
          height: '780px',
          width: '600px',
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          flexDirection: 'column',
        }}
      >
        {loading ? (
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {' '}
            <Loader thickness={6} />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                width: '100%',
                height: '40px',
                display: 'flex',
                gap: '12px',
                alignItems: 'center',
              }}
            >
              <img
                src={ytAccounts[0]?.snippet?.thumbnails?.high?.url}
                alt='profilePic'
                height={40}
                width={40}
                style={{ borderRadius: '50%' }}
              />
              <Box sx={{ width: '100%' }}>
                <span
                  style={{
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      lineHeight: '20px',
                      color: theme.palette.text.main,
                      fontFamily: theme.typography.h2.fontFamily,
                      fontSize: theme.typography.h5.fontSize,
                      fontWeight: theme.typography.h2.fontWeight,
                    }}
                  >
                    {ytAccounts[0]?.snippet?.title}{' '}
                  </Typography>
                  <Typography
                    style={{
                      lineHeight: '20px',
                      color: theme.palette.text.light,
                      fontFamily: theme.typography.h2.fontFamily,
                      fontSize: theme.typography.h5.fontSize,
                      fontWeight: theme.typography.h5.fontWeight,
                      fontStyle: 'normal',
                      textAlign: 'center',
                    }}
                  >
                    {ytAccounts[0]?.snippet?.customUrl}
                  </Typography>
                </span>
                <Typography
                  style={{
                    lineHeight: '18px',
                    color: theme.palette.text.light,
                    fontFamily: theme.typography.h2.fontFamily,
                    fontSize: theme.typography.paragraph.fontSize,
                    fontWeight: theme.typography.h5.fontWeight,
                  }}
                >
                  {timeAgo(postData.publishedAt)}
                </Typography>
              </Box>
              <CloseRounded
                onClick={onCancel}
                sx={{
                  height: '24px',
                  width: '24px',
                  cursor: 'pointer',
                  color: theme.palette.text.main,
                }}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.text.main,
                  fontFamily: theme.typography.h2.fontFamily,
                  fontSize: theme.typography.h4.fontSize,
                  fontWeight: theme.typography.h5.fontWeight,
                  width: '100%',
                  mt: '12px',
                  mx: 'auto',
                  lineHeight: '24px',
                  boxSizing: 'border-box',
                  borderRadius: '10px',
                  padding:
                    ' var(--Basic-Forms-Input-Placeholder-Spacing-Y, 12px) var(--Basic-Forms-Input-Placeholder-Spacing-X, 14px)',
                  gap: '10px',
                  border: `1px solid ${theme.palette.border.extralight}`,
                  overflowY: 'auto',
                  maxHeight: '75px',
                  textAlign: 'start',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {postData.title}
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.text.main,
                  fontFamily: theme.typography.h2.fontFamily,
                  fontSize: theme.typography.h5.fontSize,
                  fontWeight: theme.typography.h5.fontWeight,
                  width: '100%',
                  boxSizing: 'border-box',
                  borderRadius: '10px',
                  padding:
                    ' var(--Basic-Forms-Input-Placeholder-Spacing-Y, 12px) var(--Basic-Forms-Input-Placeholder-Spacing-X, 14px)',
                  gap: '10px',
                  mx: 'auto',
                  border: `1px solid ${theme.palette.border.extralight}`,
                  overflowY: 'auto',
                  height: '150px',
                  lineHeight: '24px',
                  textAlign: 'start',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {postData.description}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '450px',
                  width: '100%',
                }}
              >
                <iframe
                  width='100%'
                  height='100%'
                  src={postData?.videoUrl?.replace('watch?v=', 'embed/')}
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  style={{
                    borderRadius: '16px',
                    objectFit: 'contain',
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: '16px',
                  alignItems: 'start',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  <LikeInstaIconSvg
                    style={{ color: theme.palette.text.main }}
                  />
                  <Typography
                    sx={{
                      color: theme.palette.text.main,
                      fontFamily: theme.typography.h2.fontFamily,
                      fontSize: theme.typography.h4.fontSize,
                      fontWeight: theme.typography.h4.fontWeight,
                      lineHeight: '24px',
                    }}
                  >
                    {formatCount(postData.likeCount)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  <CommentInstaIconSvg
                    style={{ color: theme.palette.text.main }}
                  />
                  <Typography
                    sx={{
                      color: theme.palette.text.main,
                      fontFamily: theme.typography.h2.fontFamily,
                      fontSize: theme.typography.h4.fontSize,
                      fontWeight: theme.typography.h4.fontWeight,
                      lineHeight: '24px',
                    }}
                  >
                    {formatCount(postData.commentCount)}
                  </Typography>
                </Box>{' '}
                <Box
                  sx={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                  }}
                >
                  {' '}
                  <ViewsIconSvg style={{ color: theme.palette.text.main }} />
                  <Typography
                    sx={{
                      color: theme.palette.text.main,
                      fontFamily: theme.typography.h2.fontFamily,
                      fontSize: theme.typography.h4.fontSize,
                      fontWeight: theme.typography.h4.fontWeight,
                      lineHeight: '24px',
                    }}
                  >
                    {formatCount(postData.viewCount)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  )
}

export default ViewModelYoutube

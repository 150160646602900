import { ThemeProvider, createTheme } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import UserContext from '../../Context/userContext'
import ThemeContext from './themeContext'

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#241BFF',
      light: '#2563EB',
      dark: '#000464',
      aiColor: 'linear-gradient(90deg, #4950FF 0%, #6268FD 52%)',
    },
    button: {
      main: '#241BFF',
      light: '#000464',
      dark: '#000464',
      gray: '#F8F9FF',
      text: '#fff',
      disable: '#E0E0E0',
      aiColor: 'linear-gradient(90deg, #4950FF 0%, #6268FD 52%)',
    },
    text: {
      light: '#494949',
      hover: '#6B7280',
      disable: '',
      main: '#000',
      searchText: '#000',
      dark: '#818089',
      svg: '#000',
    },
    menu: {
      background: '#E5E5FF',
      menuitems: '#C4C4FF',
    },
    background: {
      default: '#fff',
      divider: '',
      light: '#fff',
      table: '#F9F9FF',
      searchField: '#F2F2F2',
      menu: '#E5E5FF',
      workspace: '#DADAFE',
    },
    loader: {
      shadow: '#DBE3F4',
    },
    table: {
      footer: '#DADAFF',
      even: '#F4F7FF',
      odd: '#fff',
    },

    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
    },
    error: {
      main: '#EF4444',
      light: '#ef5350',
      dark: '#E34935',
      extraLight: '#FFEDEB',
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
    },

    info: {
      main: '#0288d1',
      light: '#03a9f4',
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#44b700',
    },
    black: {
      main: '#000',
      default: '#0E0E10',
      light: '#494949',
      extraLight: '#52575D',
      hover: '#6B7280',
    },
    sky: {
      main: '#6C66FF',
      light: '#DADAFF',

      extraLight: '#E5E4FF',
      dark: '#CCE0FF',
    },
    customWhite: {
      main: '#FFF',
      light: '#EAEAEA',
      extraLight: '#F9F9FF',
      dark: '#FDFDFF',
      hover: '#F2F2F2',
      opacity50: 'rgba(255, 255, 255, 0.50)',
    },
    gray: {
      main: '#F8F9FF',

      light: '#E5E7EB',
      extraLight: '#DBEAFE',
      dark: '#FAFBFC',
      hover: '#F3F4F6',
    },
    hover: {
      main: '#F3F4F6',
      menu: '#C4C4FF',
      svg: '#C2C2C2',
    },
    border: {
      main: '#C2C2C2',
      star: '',
      dark: '#858585',
      extralight: '#DADAFF',
      hover: '#C6C6CE',
      textfield: '#241BFF',
    },
    skeleton: {
      background: '#F8F9FF',
      border: '#DADAFF',
    },
  },
  typography: {
    h1: {
      fontSize: '35px',
      fontWeight: '700',
      fontFamily: 'Sora',
    },
    h2: {
      fontSize: '20px',
      fontWeight: '600',
      fontFamily: 'Sora',
    },
    h3: {
      fontSize: '16px',
      fontWeight: '600',
      fontFamily: 'Sora',
    },
    h4: {
      fontSize: '14px',
      fontWeight: '500',
      fontFamily: 'Sora',
    },
    h5: {
      fontSize: '13px',
      fontWeight: '400',
      fontFamily: 'Sora',
    },
    menuItems: {
      fontSize: '13px',
      fontWeight: '600',
      fontFamily: 'Sora',
    },
    paragraph: {
      fontSize: '13px',
      fontWeight: 'normal',
      fontFamily: 'Sora',
    },
    menualText: {
      fontSize: '30px',
      fontWeight: '700',
      fontFamily: 'Sora',
    },
    extraLarge: {
      fontSize: '36px',
      fontWeight: '800',
    },
    large: {
      fontSize: '28px',
    },
    mediumText: {
      fontSize: '18px',
    },
    headingOne: {
      fontSize: '34px',
    },
    headingTwo: {
      fontSize: '26px',
    },
    headingThree: {
      fontSize: '23px',
    },
    sidebar: { fontWeight: '505' },
  },
})

const darkTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#241BFF',
      light: '#2563EB',
      dark: '#000464',
      aiColor: 'linear-gradient(90deg, #4950FF 0%, #6268FD 52%)',
    },
    skeleton: {
      background: '#18181A',
      border: '#28282A',
    },
    button: {
      main: '#241BFF',
      light: '#2563EB',
      dark: '#3F49FF',
      gray: '#F8F9FF',
      text: '#fff',
      aiColor: 'linear-gradient(90deg, #4950FF 0%, #6268FD 52%)',
      disable: '#28282A',
    },
    text: {
      light: '#818089',
      hover: '#6B7280',
      main: '#f2f2f2',
      searchText: '#B5B5B5',
      dark: '#818089',
      svg: '#818089',
      disable: '#818089',
    },
    menu: {
      background: '#18181A',
      menuitems: '#28282A',
    },
    background: {
      default: '#0E0E10',
      light: '#18181A',
      table: '#28282A',
      searchField: '#28282A',
      menu: '#18181A',
      workspace: '#DADAFF',
    },
    table: {
      footer: '#18181A',
      even: '#28282A',
      odd: '#212121',
    },
    loader: {
      shadow: '#28282A',
    },
    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
    },
    error: {
      main: '#EF4444',
      light: '#ef5350',
      dark: '#E34935',
      extraLight: '#FFEDEB',
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
    },

    info: {
      main: '#0288d1',
      light: '#03a9f4',
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#44b700',
    },
    black: {
      main: '#000',
      default: '#0E0E10',
      light: '#494949',
      extraLight: '#52575D',
      hover: '#6B7280',
    },
    sky: {
      main: '#28282A',
      light: '#28282A',

      extraLight: '#E5E4FF',
      dark: '#CCE0FF',
    },
    hover: {
      main: '#28282A',
      menu: '#0E0E10',
      svg: '#0E0E10',
    },
    customWhite: {
      main: '#FFF',
      light: '#EAEAEA',
      extraLight: '#F9F9FF',
      dark: '#FDFDFF',
      hover: '#F2F2F2',
      opacity50: 'rgba(255, 255, 255, 0.50)',
    },
    gray: {
      main: '#18181A',

      light: '#E5E7EB',
      extraLight: '#DBEAFE',
      dark: '#FAFBFC',
      hover: '#F3F4F6',
    },
    border: {
      main: '#28282A',
      star: '#B5B5B5',

      dark: '#858585',
      extralight: '#28282A',
      hover: '#C6C6CE',
      textfield: '#28282A',
    },
  },

  typography: {
    h1: {
      fontSize: '35px',
      fontWeight: '600',
      fontFamily: 'Sora',
    },
    h2: {
      fontSize: '20px',
      fontWeight: '500',
      fontFamily: 'Sora',
    },
    h3: {
      fontSize: '16px',
      fontWeight: '500',
      fontFamily: 'Sora',
    },
    h4: {
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Sora',
    },
    h5: {
      fontSize: '13px',
      fontWeight: '400',
      fontFamily: 'Sora',
    },
    menuItems: {
      fontSize: '13px',
      fontWeight: '500',
      fontFamily: 'Sora',
    },
    paragraph: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontFamily: 'Sora',
    },
    menualText: {
      fontSize: '30px',
      fontWeight: '600',
      fontFamily: 'Sora',
    },
    extraLarge: {
      fontSize: '36px',
      fontWeight: '700',
    },
    large: {
      fontSize: '28px',
    },
    mediumText: {
      fontSize: '18px',
    },
    headingOne: {
      fontSize: '34px',
    },
    headingTwo: {
      fontSize: '26px',
    },
    headingThree: {
      fontSize: '23px',
    },
    sidebar: { fontWeight: '505' },
  },
})
const ThemeContextProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(
    JSON.parse(window.localStorage.getItem('user'))?.darkMode
  )
  console.log('isDarkMode', isDarkMode)

  return (
    <ThemeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
      <ThemeProvider theme={isDarkMode ? darkTheme : theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider

import { Box, MenuItem, Select, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import React, { useContext } from 'react'
import UserContext from '../../../../../../Context/userContext'

const PlaylistYoutube = ({
  playListField,
  setPlayListField,
  register,
  selectedPlaylist,
  setSelectedPlaylist,
  loading,
}) => {
  const playlistName = ['My Favorite Videos', 'Github', 'Youtube', 'Instagram']
  const theme = useTheme()
  const { postData } = useContext(UserContext)

  return (
    <Box
      sx={{
        width: '550px',
        display: postData ? 'none' : 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <span style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Typography
          sx={{
            color: theme.palette.text.main,
            fontFamily: theme?.typography.h2.fontFamily,
            fontSize: theme?.typography.h4.fontSize,
            fontWeight: theme?.typography.h4.fontWeight,
            fontStyle: 'normal',
            lineHeight: '24px',
          }}
        >
          Playlists
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.light,
            fontFamily: theme?.typography.h2.fontFamily,
            fontSize: theme?.typography.paragraph.fontSize,
            fontWeight: theme?.typography.h5.fontWeight,
            fontStyle: 'normal',
            lineHeight: '20px',
          }}
        >
          Add your video to one or more playlists to organize your content for
          viewers.{' '}
          <span style={{ textDecoration: 'underline' }}>Learn more</span>
        </Typography>
      </span>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '6px',
        }}
      >
        <div style={{ position: 'relative' }}>
          <Select
            disabled={loading}
            variant='outlined'
            fullWidth
            value={selectedPlaylist}
            {...register('playlistId')}
            onChange={(e) => setSelectedPlaylist(e.target.value)}
            sx={{
              background: theme.palette.background.light,
              borderRadius: '8px',
              height: '42px',
              color: theme?.palette.text.main,
              fontFamily: theme.typography.h2.fontFamily,

              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.border.extralight,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.border.extralight,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.border.extralight,
              },
              '& .MuiSelect-icon': {
                color: theme?.palette.text.main,
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  boxSizing: 'border-box',
                  padding: '6px',
                  borderRadius: '12px',
                  borderColor: theme.palette.border.extralight,
                  background: theme.palette.background.light,
                },
              },
            }}
          >
            {playListField.map((val, ind) => (
              <MenuItem
                sx={{
                  height: '44px',
                  mb: '6px',
                  borderRadius: '8px',
                  border: `1px solid ${theme.palette.border.extralight}`,
                  color: theme.palette.text.main,
                  fontStyle: 'normal',
                  lineHeight: '24px',
                  fontSize: theme.typography.h4.fontSize,
                  fontWeight: theme.typography.h5.fontWeight,
                  fontFamily: theme.typography.h2.fontFamily,
                  '&:hover': {
                    border: `1px solid ${theme.palette.border.extralight}`,
                    background: theme.palette.background.default,
                  },
                  '&:focus': {
                    border: `1px solid ${theme.palette.border.extralight}`,
                    background: theme.palette.background.default,
                  },
                }}
                key={ind}
                value={val.id}
              >
                {val.title}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Box>
    </Box>
  )
}

export default PlaylistYoutube

import React, { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { instagramStyle } from '../instagramStyle'
import Header from './Header/header'
import InstagramPostModal from '../InstagramPostModal'
import Postcard from './PostCard'
import UserContext from '../../../../../Context/userContext'
import { Loader } from '@infostacks/ui-components'
import noData from './../../../../../Assets/Images/noData.jpg'
import { instagramGetAllPostsApi } from '../../../../../API/PlatformsAPIs/InstagramApi'
import { useParams } from 'react-router-dom'
import { Nodata } from '../../../../PasswordStartAdornment/svgFile'

const InstagramPosts = () => {
  const { setAiModalOpen, userAuth } = useContext(UserContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [instaModal, setInstaModal] = useState(false)
  const [filterBtns, setFilterBtns] = useState('Posted')
  const [postArray, setPostArray] = useState([])
  const [loading, setLoading] = useState(true)
  const { pageId, id, igUserId } = useParams()
  const [postAndStoryOption, setPostAndStoryOption] = useState('Post')

  // console.log('filterBtns', filterBtns)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setInstaModal(false)
    setAnchorEl(false)
    setAiModalOpen(false)
  }
  const getInstaPosts = async () => {
    setLoading(true)
    try {
      const resp = await instagramGetAllPostsApi(userAuth, id, igUserId, pageId)
      console.log('resp', resp)
      console.log(filterBtns)

      let filteredArray = []

      if (postAndStoryOption === 'Post') {
        filteredArray = resp.filter((item) => item.contentType === 'post')
        if (filterBtns === 'Posted') {
          filteredArray = filteredArray.filter(
            (item) => item.status === 'posted'
          )
        } else if (filterBtns === 'Scheduled') {
          filteredArray = filteredArray.filter(
            (item) => item.status === 'scheduled'
          )
        } else if (filterBtns === 'Drafted') {
          filteredArray = filteredArray.filter(
            (item) => item.status === 'drafted'
          )
        } else filteredArray = []
      } else if (postAndStoryOption === 'Story') {
        filteredArray = resp.filter((item) => item.contentType === 'story')
        if (filterBtns === 'Posted') {
          filteredArray = filteredArray.filter(
            (item) => item.status === 'posted'
          )
        } else if (filterBtns === 'Scheduled') {
          filteredArray = filteredArray.filter(
            (item) => item.status === 'scheduled'
          )
        } else if (filterBtns === 'Drafted') {
          filteredArray = filteredArray.filter(
            (item) => item.status === 'drafted'
          )
        } else filteredArray = []
      }

      setPostArray(filteredArray)
      setLoading(false)
    } catch (error) {
      console.error('error', error)
    }
  }

  useEffect(() => {
    const fetchPost = async () => {
      getInstaPosts()
    }
    fetchPost()
  }, [filterBtns, postAndStoryOption])

  return (
    <Box sx={instagramStyle.parentContainer}>
      <>
        <Header
          setInstaModal={setInstaModal}
          filterBtns={filterBtns}
          setFilterBtns={setFilterBtns}
          postAndStoryOption={postAndStoryOption}
          setPostAndStoryOption={setPostAndStoryOption}
        />
        <Box sx={instagramStyle.postCardsParent}>
          {loading ? (
            <Box sx={instagramStyle.circularProgressBox}>
              <Loader thickness={6} />
            </Box>
          ) : postArray.length === 0 ? (
            <img
              src={noData}
              alt='no data'
              style={instagramStyle.noDataImage}
            />
          ) : (
            // <Nodata/>
            postArray.map((val, ind) => (
              <Postcard
                key={ind}
                val={val}
                anchorEl={anchorEl}
                open={open}
                handleClick={handleClick}
                handleClose={handleClose}
                postAndStoryOption={postAndStoryOption}
                setInstaModal={setInstaModal}
                filterBtns={filterBtns}
              />
            ))
          )}
        </Box>
      </>
      {instaModal ? (
        <InstagramPostModal
          instaModal={instaModal}
          handleClose={handleClose}
          getInstaPosts={getInstaPosts}
          filterBtns={filterBtns}
          setFilterBtns={setFilterBtns}
        />
      ) : null}
    </Box>
  )
}

export default InstagramPosts

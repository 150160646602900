import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Typography, IconButton, Chip, Modal } from '@mui/material'
import { useTheme } from '@emotion/react'
import ReusableTable from '../../../../ReusableComponents/customTable'
import { ArrowBack, CloseRounded, MoreVertRounded } from '@mui/icons-material'
import { addButtonStyle } from '../workspaceProfilePageStyle'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../../../../Context/userContext'
import {
  formatDateString,
  getAllRolesAPI,
  workspaceMembersAPI,
} from '../../../../../API'
import { Loader } from '@infostacks/ui-components'
import CustomMenu from '../../../../ReusableComponents/customMenu'
import {
  allRoles_Text_Style,
  allRolesTable_Style,
  emial_TextStyle,
  fullname_Text_Style,
  map_Box,
  members_Text_Style,
} from './workspaceRoleAndAdminStyle'

const AllRolesTable = () => {
  const { userAuth, setManageRole, setButtonState, stateCheck } =
    useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null)
  const [membersModal, setMembersModal] = useState(false)
  const [acceptedMembers, setAcceptedMembers] = useState([])
  const [specificMembers, setSpecificMembers] = useState([])
  const [specificRole, setSpecificRole] = useState([])
  // console.log('specificMembers', specificMembers)
  const [roles, setRoles] = useState([])
  // console.log('roles', roles)

  // console.log('acceptedMembers', acceptedMembers)

  const getSpaceData = async (spaceId) => {
    try {
      setLoading(true)
      const allRoles = await getAllRolesAPI(userAuth, spaceId)
      setRoles(allRoles)
      const workspaceMemberResponse = await workspaceMembersAPI(
        userAuth,
        spaceId
      )
      const acceptStatusMember = workspaceMemberResponse.filter(
        (val) => val.status === 'accepted'
      )
      setAcceptedMembers(acceptStatusMember)
    } catch (error) {
      console.error('Error fetching space data', error)
    }
    setLoading(false)
  }

  useEffect(() => {
    const spaceId = window.sessionStorage.getItem('spaceId')
    const fetchData = async () => {
      if (userAuth) {
        await getSpaceData(spaceId)
      }
    }
    fetchData()
  }, [userAuth, stateCheck])

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget)
    setManageRole(row)
    window.sessionStorage.setItem('manageRole', JSON.stringify(row))
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const menuItems = [
    {
      label: 'Manage role',
      onClick: () => navigate('/dashboard/manage-role'),
    },
  ]
  const handleNavigate = () => {
    navigate('/dashboard/workspace')
  }
  const handleViewMembers = (state, roleName) => {
    setMembersModal(state)
    // console.log('roleName',roleName);
    setSpecificRole(roleName)
    setSpecificMembers(
      acceptedMembers.filter((val) => val.roleName === roleName)
    )
  }

  const handleManageRole = () => {
    setManageRole()
  }

  const handleAddRole = () => {
    setButtonState('addRole')
    window.sessionStorage.setItem('buttonState', 'addRole')
    window.sessionStorage.removeItem('manageRole')
    navigate('/dashboard/create-role')
  }

  const data = roles

  const column = [
    {
      field: 'roleName',
      headerName: 'Role',

      renderCell: (row) => (
        <Chip
          label={row.roleName}
          sx={{
            fontSize: theme.typography.h5.fontSize,
            // border: `1px solid ${row.borderColor}`,
            background: row.backgroundColor,
            fontFamily: theme?.typography.h4.fontFamily,
            padding: '12px',
            boxSizing: 'border-box',
            borderRadius: '8px',
            fontWeight: theme?.typography.h3.fontWeight,
            color: row.borderColor,
            lineHeight: '20px',
          }}
        ></Chip>
      ),
    },
    {
      field: 'access',
      headerName: 'Access',
      renderCell: (row) => (
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
            },
            fontFamily: theme?.typography.h5.fontFamily,
            fontWeight: theme?.typography.h5.fontWeight,
            color: theme?.palette.text.light,
            lineHeight: '20px',
          }}
        >
          {row.access}
        </Typography>
      ),
    },
    {
      field: 'members',
      headerName: 'Members',
      renderCell: (row) => (
        <Button
          onClick={() => handleViewMembers(true, row.roleName)}
          color='inherit'
          variant='text'
          sx={{
            textTransform: 'capitalize',
            textDecoration: 'underline',
            color: theme?.palette.text.light,
            padding: '0px',
            ':hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
            },
          }}
        >
          View
        </Button>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Date Created',
      renderCell: (row) => (
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
            },
            fontFamily: theme?.typography.h5.fontFamily,
            fontWeight: theme?.typography.h5.fontWeight,
            color: theme?.palette.text.light,
            lineHeight: '20px',
          }}
        >
          {formatDateString(row.createdAt)}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (row) => (
        <IconButton
          sx={{ color: theme?.palette.text.light }}
          disabled={
            row.roleName === 'Admin' ||
            row.roleName === 'Owner' ||
            row.roleName === 'Manager'
              ? true
              : false
          }
          onClick={(event) => handleMenuOpen(event, row)}
        >
          <MoreVertRounded />
        </IconButton>
      ),
    },
  ]

  return (
    <>
      <Box sx={allRolesTable_Style.parent_Box(theme)}>
        <Box sx={allRolesTable_Style.main_Box}>
          <span style={allRolesTable_Style.span_Style}>
            <ArrowBack
              onClick={handleNavigate}
              sx={allRolesTable_Style.arrowBack(theme)}
            />
            <Typography sx={allRoles_Text_Style(theme)}>All Roles</Typography>
          </span>
          <Button onClick={() => handleAddRole()} sx={addButtonStyle(theme)}>
            Add Role
          </Button>
        </Box>
        {loading ? (
          <Box sx={allRolesTable_Style.loder_Box}>
            <Loader thickness={6} sx={{ color: 'blue' }} />
          </Box>
        ) : (
          <ReusableTable columns={column} data={data} />
        )}
        <CustomMenu
          anchorEl={anchorEl}
          onClose={handleClose}
          open={anchorEl}
          menuItems={menuItems}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
      </Box>

      <Modal open={membersModal} sx={allRolesTable_Style.model_Style(theme)}>
        <Box sx={allRolesTable_Style.model_MainBox(theme)}>
          <Box sx={allRolesTable_Style.members_Box}>
            <Typography sx={members_Text_Style(theme)}>Members</Typography>
            <IconButton
              sx={{ height: '30px', width: '30px' }}
              onClick={() => handleViewMembers(false)}
            >
              <CloseRounded sx={{ color: theme.palette.text.main }} />
            </IconButton>
          </Box>
          <Typography
            sx={{
              color: theme?.palette.text.light,
              lineHeight: 'normal',
            }}
          >
            There are {specificMembers.length} members who have the{' '}
            {specificRole} Role.
          </Typography>
          <Box
            className='custom-scrollbar'
            sx={allRolesTable_Style.custom_Scrollbar_Box}
          >
            {specificMembers?.map((val, ind) => (
              <Box key={ind} sx={map_Box(theme)}>
                <img
                  src={val.imageUrl}
                  alt='img'
                  height={48}
                  width={48}
                  style={{ borderRadius: '50%' }}
                />
                <Box sx={allRolesTable_Style.map_Middle_Box}>
                  <Box sx={allRolesTable_Style.fullName_Box}>
                    <Typography sx={fullname_Text_Style(theme)}>
                      {val.fullName}
                    </Typography>
                  </Box>
                  <Typography sx={emial_TextStyle(theme)}>
                    {val.email} - {formatDateString(val.inviteDate)}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default AllRolesTable

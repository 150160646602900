const styleMembersCreditsModel = {
  modal_Style: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form_Style: (theme) => ({
    width: '550px',
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.border.main}`,
    boxSizing: 'border-box',
    padding: '24px',
    borderRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
  }),
  mangeCredits_Box: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  radioBtn_Box: {
    display: 'flex',
    gap: 'var(--Numbers-Number-12, 24px)',
    alignItems: 'center',
    mt: '32px',
  },
  field_Box: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '4px',
    mt: '16px',
  },
  innerStyleField: (theme) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    paddingX: '20px',
  }),
  confirmtion_Box: {
    display: 'flex',
    alignItems: 'center',
    mt: '10px',
    gap: '10px',
  },
  fotterButton_Box: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'end',
    alignItems: 'center',
    mt: '32px',
  },
}
export default styleMembersCreditsModel
export const mangeCreditsText = (theme) => ({
  fontFamily: theme.typography.h2.fontFamily,
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.h1.fontWeight,
  color: theme.palette.text.main,
  letterSpacing: '-0.96px',
})
export const description_Text = (theme) => ({
  fontFamily: theme.typography.h2.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.text.main,
  lineHeight: '20px',
  mt: '8px',
})
export const radio_Button = (theme) => ({
  fontFamily: theme.typography.h2.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.text.main,
  lineHeight: '24px',
  fontStyle: 'normal',
  '&.Mui-checked': {
    color: theme.palette.text.main,
  },
  '&.Mui-disabled': {
    color: theme.palette.text.main,
  },
})
export const creditsText_Style = (theme) => ({
  color: theme.palette.text.main,
  lineHeight: '20px',
  fontFamily: theme.typography.h2.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  fontStyle: 'normal',
})
export const field_Box_Style = (theme) => ({
  width: '100%',
  display: 'flex',
  background: theme.palette.background.light,
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: '16px',
})
export const icon_Box_Style = (theme) => ({
  width: '60px',
  display: 'flex',
  borderLeft: `1px solid ${theme.palette.border.main}`,
  justifyContent: 'center',
  alignItems: 'center',
})
export const confirmtion_Text = (theme) => ({
  fontFamily: theme.typography.h2.fontFamily,
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  color: theme.palette.text.main,
  lineHeight: '20px',
})
export const cancel_Button = (theme) => ({
  fontFamily: theme.typography.h2.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  height: '40px',
  borderRadius: '14px',
  padding: '7px 20px',
  boxSizing: 'border-box',
  lineHeight: '24px',
  fontStyle: 'normal',
  background: '',
  textTransform: 'capitalize',
  color: theme.palette.button.dark,
  border: `1px solid ${theme.palette.button.dark}`,
  ':hover': {
    color: theme.palette.button.dark,
    border: `1px solid ${theme.palette.button.dark}`,
    backgroundColor: 'transparent',
  },
  '&.Mui-disabled': {
    background: '',
    color: theme.palette.button.dark,
  },
})
export const confirm_Button = (checkbox, theme) => ({
  fontFamily: theme.typography.h2.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  width: '97px',
  height: '40px',
  borderRadius: '14px',
  boxSizing: 'border-box',
  padding: '7px 20px',
  background: theme.palette.button.dark,
  textTransform: 'capitalize',
  fontStyle: 'normal',
  lineHeight: '24px',
  color: theme.palette.customWhite.main,
 
  ':hover': {
    background: checkbox ? theme.palette.button.dark : '',
    // opacity: 'unset',
  },
  '&.Mui-disabled': {
    background: theme.palette.button.disable,
    color: theme.palette.text.disable,
  },
})

import { Loader } from '@infostacks/ui-components'
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import createMemberFormStyle, {
  apiErrorText,
  commonTextFieldStyle,
  ContinueButton,
  desc_Text,
  forgetPassword_Typography,
  heading_Text,
  labelTextfield,
} from '../CreateMemberForm/createMemberFormStyle'
import { useForm } from 'react-hook-form'
import { useTheme } from '@emotion/react'
import {
  emailResolver,
  emailValidator,
  passwordResolver,
  passwordValidator,
} from '../../../Validators/authValidator'
import { Visibility, VisibilityOff, WarningRounded } from '@mui/icons-material'
import UserContext from '../../../Context/userContext'

const LoginMemberForm = ({
  open,
  loginOnSubmit,
  setApiError,
  apiError,
  btnLoader,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const theme = useTheme()
  const { forgotPasswordContext } = useContext(UserContext)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields },
  } = useForm()

  const forgetButton = async () => {
    try {
      const email = watch('email')

      if (email) {
        const resOfForgotPassword = await forgotPasswordContext(email)
        console.log('resOfForgotPassword', resOfForgotPassword)
        if (resOfForgotPassword.message === 'User Not Found') {
          setApiError(resOfForgotPassword.message)
        }
      } else {
        setApiError('Email is required for password reset.')
      }
    } catch (error) {
      console.log(error, 'forget error')
    }
  }

  return (
    <Modal open={open} sx={createMemberFormStyle.model_Style}>
      <Box sx={createMemberFormStyle.parent_Box}>
        <form
          className='signupForm'
          onSubmit={handleSubmit(loginOnSubmit)}
          style={createMemberFormStyle.form_Style}
        >
          <Box sx={createMemberFormStyle.title_Box}>
            <Typography sx={heading_Text(theme)}>Sign In account</Typography>
            <Typography sx={desc_Text(theme)}>
              Please provide your email and password
            </Typography>
          </Box>
          <Box sx={createMemberFormStyle.allFieldsWrapper}>
            <Box sx={createMemberFormStyle.fieldParentBox}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Email*
              </label>
              <div style={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  autoComplete='off'
                  variant='outlined'
                  placeholder='Enter your email'
                  sx={commonTextFieldStyle(errors.email)}
                  {...register('email', emailValidator)}
                />
                <FormHelperText sx={createMemberFormStyle.helperText}>
                  {errors.email && touchedFields.email
                    ? emailResolver[errors.email?.type]
                    : apiError === 'User Not Found'
                    ? 'User Not Found'
                    : apiError === 'Email is required for password reset.'
                    ? 'Email is required for password reset.'
                    : ''}
                </FormHelperText>
              </div>
            </Box>

            <Box sx={createMemberFormStyle.fieldParentBox}>
              <label className='textfield-label' style={labelTextfield(theme)}>
                Password*
              </label>
              <div style={{ position: 'relative' }}>
                <TextField
                  sx={commonTextFieldStyle(errors.password)}
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  variant='outlined'
                  placeholder='Enter your password'
                  autoComplete='off'
                  {...register('password', passwordValidator)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge='end'
                          sx={{ color: '#818089' }}
                        >
                          {showPassword ? (
                            <Visibility fontSize='small' />
                          ) : (
                            <VisibilityOff fontSize='small' />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText sx={createMemberFormStyle.helperText}>
                  {errors.password && touchedFields.password
                    ? passwordResolver[errors.password?.type]
                    : ''}
                </FormHelperText>
              </div>
            </Box>
            <Typography
              onClick={forgetButton}
              sx={forgetPassword_Typography(theme)}
            >
              Forget Password
            </Typography>
          </Box>
          <Button type='submit' variant='contained' sx={ContinueButton(theme)}>
            {btnLoader ? <Loader thickness={7} size={30} /> : 'Continue'}
          </Button>

          {apiError === 'Invalid credentials' ? (
            <Box sx={createMemberFormStyle.error_Box}>
              <WarningRounded sx={{ color: 'red' }} />
              <Typography sx={apiErrorText(theme)}>
                {apiError}. Please try again.
              </Typography>
            </Box>
          ) : null}
        </form>
      </Box>
    </Modal>
  )
}

export default LoginMemberForm

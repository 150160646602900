import { useTheme } from '@emotion/react'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import React from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ChatIcon } from '../PasswordStartAdornment'
import styles, {
  contact_Sales_Btn,
  decp_TextStyle,
  iconBox_Style,
  main_BoxEnterpriseDialog,
  subTitle_TextStyle,
} from './pricingStyle'

const EnterpriseDialog = ({ open, onClose }) => {
  const theme = useTheme()
  return (
    <Modal open={open} sx={styles.model_Style}>
      <Box sx={main_BoxEnterpriseDialog(theme)}>
        <IconButton onClick={() => onClose(false)} sx={styles.icon_Style}>
          <CloseRoundedIcon sx={{ color: theme.palette.text.main }} />
        </IconButton>
        <Box sx={iconBox_Style(theme)}>
          <ChatIcon />
        </Box>
        <Typography sx={subTitle_TextStyle(theme)}>
          Contact Us for Enterprise Plan{' '}
        </Typography>
        <Typography sx={decp_TextStyle(theme)}>
          If you are interested in our enterprise plan, please contact us at
          <span
            style={{
              color: 'blue',
            }}
          >
            email@example.com
          </span>{' '}
          . Our team will assist you further.
        </Typography>

        <Button sx={contact_Sales_Btn(theme)}>Contact Sales</Button>
      </Box>
    </Modal>
  )
}

export default EnterpriseDialog

import { useTheme } from '@emotion/react'
import { Box, Typography, TextField, Autocomplete } from '@mui/material'
import { useContext } from 'react'
import UserContext from '../../../../../../Context/userContext'

const AddTag = ({ tags, setTags, loading }) => {
  const theme = useTheme()
  const maxCharacters = 10
  console.log('tagsss', tags)
  const handleTagChange = (event, value) => {
    const uniqueTags = [
      ...new Set(value.map((tag) => tag.trim().toLowerCase())),
    ]
    if (uniqueTags.length <= maxCharacters) {
      setTags(uniqueTags)
    }
  }
  const { postData } = useContext(UserContext)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 'var(--Numbers-Number-6, 12px)',
        maxWidth: '550px',
        width: '100%',
      }}
    >
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.main,
            fontFamily: theme?.typography?.h4?.fontFamily,
            fontSize: theme?.typography?.h4?.fontSize,
            fontWeight: theme?.typography?.h4?.fontWeight,
            fontStyle: 'normal',
            lineHeight: '24px',
          }}
        >
          Tags
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.light,
            fontFamily: 'Sora',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
          }}
        >
          Tags can be useful if content in your video is commonly misspelled.
          Otherwise, tags play a minimal role in helping viewers find your
          video.
          <a
            href='https://support.google.com/youtube/answer/2797468?hl=en'
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'underline' }}
          >
            Learn more
          </a>
          .
        </Typography>
      </span>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        {' '}
        <Autocomplete
          disabled={loading}
          sx={{
            width: '100%',

            '& .MuiAutocomplete-inputRoot': {
              backgroundColor: theme.palette.background.light,
              borderRadius: 'var(--Basic-Forms-Input-Border-Radius, 8px)',
            },
            '& .MuiSvgIcon-root': {
              color: theme.palette.text.main,
            },
          }}
          freeSolo
          multiple
          id='tags-autocomplete'
          options={[]} // You can leave options empty for freeSolo input
          value={tags || postData?.tags} // Set value to tags
          // onChange={(event, value) => setTags(value)}
          onChange={handleTagChange}
          getOptionLabel={(option) =>
            typeof option === 'string' ? option : option.label || ''
          }
          // renderTags={(value, getTagProps) =>
          //   value.map((option, index) => (
          //     <span
          //       {...getTagProps({ index })}
          //       key={option} // Ensure the key is unique
          //       style={{
          //         display: 'inline-flex',
          //         alignItems: 'center',
          //         margin: '4px',
          //         padding: '4px 8px',
          //         backgroundColor: theme.palette.primary.light,
          //         color: theme.palette.primary.contrastText,
          //         borderRadius: '12px',
          //         fontFamily: 'Sora',
          //         fontSize: '14px',
          //         fontWeight: '500',
          //       }}
          //     >
          //       {option}
          //       <span
          //         style={{
          //           marginLeft: '8px',
          //           cursor: 'pointer',
          //           color: theme.palette.text.main,
          //         }}
          //         onClick={() => {
          //           const newTags = value.filter((_, i) => i !== index)
          //           setTags(newTags)
          //         }}
          //       >
          //         ✕ {/* Replace this with an actual icon if needed */}
          //       </span>
          //     </span>
          //   ))
          // }

          renderInput={(params) => (
            <TextField
              {...params}
              variant='filled'
              sx={{
                borderRadius: 'var(--Basic-Forms-Input-Border-Radius, 8px)',
                border: `1px solid ${theme.palette.border.extralight}`,
                backgroundColor: theme.palette.background.light,

                '& .MuiFilledInput-root': {
                  boxShadow: 'none',
                  backgroundColor: theme.palette.background.light,
                  color: theme.palette.text.main,
                },
                '& .MuiFilledInput-underline:before': {
                  borderBottom: 'none',
                },
                '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottom: 'none',
                },
                '& .MuiFilledInput-underline:after': {
                  borderBottom: 'none',
                },
                '& .MuiFilledInput-root:hover': {
                  backgroundColor: theme.palette.background.light,
                },
              }}
            />
          )}
        />
        <span
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {' '}
          <Typography
            sx={{
              color: theme.palette.text.light,
              fontFamily: ' Sora',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            }}
          >
            Enter to make tag
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.light,
              fontFamily: ' Sora',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            }}
          >
            {`${tags.length}/${maxCharacters}`}
          </Typography>
        </span>
      </Box>
    </Box>
  )
}

export default AddTag

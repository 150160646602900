import { CloseRounded } from '@mui/icons-material'
import { Box, IconButton, Modal, Typography } from '@mui/material'
import React from 'react'
import workSpaceMemberModalStyle, {
  desc_Text,
  permission_MapBox,
  permission_Text,
  spaceName_Text,
  websiteLink_Text,
} from './workSpaceMemberModalStyle'
import { useTheme } from '@emotion/react'

const WorkSpaceMemberModal = ({
  permissions,
  handlePermissionModal,
  permissionModel,
}) => {
  const theme = useTheme()
  return (
    <Modal open={permissionModel} sx={workSpaceMemberModalStyle.modal_Style}>
      <Box sx={workSpaceMemberModalStyle.main_Box(theme)}>
        <Box sx={workSpaceMemberModalStyle.permisstionBox}>
          <Typography sx={permission_Text(theme)}>
            Workspace Permissions
          </Typography>
          <IconButton
            sx={{ height: '30px', width: '30px' }}
            onClick={() => handlePermissionModal(false)}
          >
            <CloseRounded sx={{ color: theme?.palette.text.main }} />
          </IconButton>
        </Box>
        <Typography sx={desc_Text(theme)}>
          You have permissions for {permissions?.length} workspaces. Manage your
          access and navigate between different workspaces.
        </Typography>
        <Box
          className='custom-scrollbar'
          sx={workSpaceMemberModalStyle.scrollbar_Box}
        >
          {permissions?.map((val, ind) => (
            <Box key={ind} sx={permission_MapBox(theme)}>
              <img
                src={val.imageUrl}
                alt=''
                style={{ borderRadius: '50%', height: '48px', width: '48px' }}
              />
              <Box sx={workSpaceMemberModalStyle.infoBox_Style}>
                <Box sx={workSpaceMemberModalStyle.second_Box}>
                  <Typography sx={spaceName_Text(theme)}>{val.name}</Typography>
                </Box>
                <Typography sx={websiteLink_Text(theme)}>
                  {val.websiteLink} - {val.category}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  )
}

export default WorkSpaceMemberModal

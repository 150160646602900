const newWorkspaceFormStyle = {
  mainBoxOfTextfield: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '4px',
    background: '',
  },
  helperText: {
    position: 'absolute',
    bottom: '-20px',
    color: 'red',
  },
  closeRounded_Box: (theme) => ({
    position: 'absolute',
    background: theme.palette.background.light,
    borderRadius: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '5%',
    right: '25%',
  }),
  fromStyle: {
    width: '784px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 'var(--Numbers-Number-32, 64px)',
    background: '',
    padding: '16px',
    boxSizing: 'border-box',
  },
  heading_Box: {
    width: '752px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '',
  },
  avatar_Box: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    height: '250px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '14px',
  },
  field_MainBox: {
    width: '346px',
    display: 'flex',
    flexDirection: 'column',
    gap: 'var(--Numbers-Number-8, 16px)',
    marginTop: '0px',
    background: '',
  },
  iconButton_Box: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.10)',
    borderRadius: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '5%',
  },
  circularProgressBox: {
    position: 'fixed',
    color: '',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },
}
export default newWorkspaceFormStyle
export const aiModelStyle = {
  parent_Box: {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 'calc(100vh - 100vh)',
    left: 'calc(100vw - 64vw)',
    bottom: 'calc(100vw - 97vw)',
  },
  prompt_Box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '',
  },
  textfield_Div: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  loader_Box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '328px',
    width: '100%',
  },
  footer_Box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  span_Style: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  background_img_Box: {
    width: '160px',
    height: '160px',
    borderRadius: '8px',
    cursor: 'pointer',
    background: '',
  },
}
export const pareantBox = (theme) => ({
  width: '100vw',
  background: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  minHeight: '100vh',
  overflowY: 'hidden',
})
export const formStyle = (showImages, theme) => ({
  width: '360px',
  backgroundColor: theme.palette.background.paper,
  boxSizing: 'border-box',
  borderRadius: '16px',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  boxShadow: 'none',
  overflow: 'hidden',
  transition: 'max-height 1s ease-in-out',
  maxHeight: showImages ? '498px' : '200px',
})
export const promptText = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  color: theme.palette.black.main,
  lineHeight: '20px',
})
export const labelTextfield = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  color: 'theme.palette.black.main',
  width: 'auto',
  marginRight: 'auto',
  lineHeight: '20px',
})
export const commonTextFieldStyle = (error, theme) => ({
  background: '',
  display: 'flex',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    gap: '12px',
    '&:hover fieldset': {
      borderColor: theme?.palette?.border?.extralight,
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'red' : theme?.palette?.border?.textfield,
    },
    '& fieldset': {
      borderColor: theme?.palette?.border?.extralight,
    },
    '& .MuiOutlinedInput-input': {
      height: '20px',
      padding: '12px 16px',
      color: theme?.palette?.text?.main,
      fontSize: '16px',
    },
  },
})
export const ContinueButton = (theme) => ({
  width: '100%',
  height: '44px',
  boxSizing: 'border-box',
  padding: '12px 16px',
  fontSize: theme.typography.h4.fontSize,
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h2.fontWeight,
  lineHeight: '24px',
  textTransform: 'capitalize',
  borderRadius: '14px',
  backgroundColor: theme.palette.button.dark,
  marginTop: '2px',
})
export const descriptionTextFieldStyle = (error, theme) => ({
  background: '',
  // height:'90px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '14px',
    gap: '12px',
    '&:hover fieldset': {
      borderColor: theme.palette.border.extralight,
    },
    '&.Mui-focused fieldset': {
      borderColor: error ? 'red' : theme.palette.border.textfield,
    },
    '& fieldset': {
      borderColor: theme.palette.border.extralight,
    },
    '& .MuiOutlinedInput-input': {
      height: { xs: '15px', sm: '24px', md: '70px' },
      color: theme?.palette?.text?.main,
    },
  },
})
export const commonSelectStyle = (error, theme) => ({
  background: '',
  borderRadius: '14px',
  height: '44px',
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border.extralight,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: error ? 'red' : theme.palette.border.textfield,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border.extralight,
  },
  '& .MuiSelect-select': {
    // height: "15px",
    color: '', // Ensure the color is set correctly or remove it
  },
  '& .MuiOutlinedInput-input': {
    // height: '20px' ,
    padding: '12px 16px',
    color: theme.palette.text.main,
    fontSize: '16px',
  },
  '& .MuiSelect-icon': {
    color: theme.palette.text.main,
  },
})
export const image = (theme) => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  border: `2px solid ${theme.palette.sky.light}`,
  borderRadius: '14px',
})
export const heading_Text = (theme) => ({
  fontWeight: theme.typography.menualText.fontWeight,
  fontSize: {
    xs: theme.typography.h2.fontSize,
    sm: theme.typography.menualText.fontSize,
  },
  color: theme.palette.text.main,
  fontFamily: theme.typography.h1.fontFamily,
  lineHeight: '40px',
})
export const paragraph_Text = (theme) => ({
  color: theme.palette.text.light,
  fontFamily: theme.typography.h4.fontFamily,
  fontWeight: theme.typography.h4.fontWeight,
  width: { xs: '100%', sm: '80%' },
  textAlign: 'center',
  fontSize: {
    xs: theme.typography.h5.fontSize,
    sm: theme.typography.h3.fontSize,
  },
  lineHeight: 'normal',
  letterSpacing: '-0.16px',
})
export const image_MainBox = () => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '14px',
  justifyContent: 'center',
  alignItems: 'center',
  background: '',
})
export const label_Style = (theme, selectedImage) => ({
  backgroundColor: selectedImage
    ? 'transparent'
    : theme.palette.customWhite.light,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '14px',
  cursor: 'pointer',
  background: theme.palette.customWhite.light,
})
export const add_ImageText = (theme) => ({
  fontWeight: theme.typography.h5.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  color: theme.palette.black.light,
  fontSize: theme.typography.h5.fontSize,
  lineHeight: '24px',
})
export const showImage_Box = (showImages) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '8px',
  maxHeight: showImages ? '328px' : '0',
  opacity: showImages ? 1 : 0,
  transition: 'max-height 1s ease-in-out, opacity 1s ease-in-out',
})
export const footerText = (theme) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  fontFamily: theme.typography.h4.fontFamily,
  lineHeight: '20px',
})

import { useTheme } from '@emotion/react';
import { ExpandMoreOutlined, GridView } from '@mui/icons-material'
import { Box, Collapse, Typography } from '@mui/material';
import React, { useContext, useState } from 'react'
import UserContext from '../../Context/userContext';

const NewPlatforms = ({drawer,platforms,navigation,sessId,allSpaces,activeSpace}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const theme = useTheme()
    const { setActiveSidebarOption, activeSidebarOption } = useContext(UserContext)
    
    const handlePlatformOption = (e, ind) => {
        if (e === 'google') {
          setActiveSidebarOption('/dashboard/google')
          navigation('/dashboard/google')
        }
        if (e === 'facebook') {
          setActiveSidebarOption(`/dashboard/facebook/${sessId}`)
          navigation(`/dashboard/facebook/${sessId}`)
        }
        if (e === 'instagram') {
          setActiveSidebarOption(`/dashboard/instagram-profiles/${sessId}`)
          navigation(`/dashboard/instagram-profiles/${sessId}`)
        }
        if (e === 'youtube') {
          setActiveSidebarOption(`/dashboard/youtube-channels/${sessId}`)
          navigation(`/dashboard/youtube-channels/${sessId}`)
        }
        setActiveSidebarOption(e)
      }


    const toggleExpand = () => {
      setIsExpanded((prev) => !prev);
    };

    
    const hasSpaces = allSpaces.length > 0
  const integratedPlatform = activeSpace[0]?.platforms?.length > 0
  const canAddPlatform =
    activeSpace[0]?.Permissions?.workSpace?.['Add Platform'] === true
  if (canAddPlatform === false) {
    platforms = platforms?.filter((v) =>
      activeSpace[0]?.platforms?.some((e) => e.name === v.name)
    )
  }


  return (
    <>
    <Box
        onClick={toggleExpand}
        sx={{
          background:isExpanded ? '#F5F6F8' : '',
          width: '100%',
          height:'48px',
          display:(hasSpaces && canAddPlatform) || (hasSpaces && integratedPlatform) ? 'flex' : 'none',
          alignItems: 'center',
          boxSizing: 'border-box',
          padding: '12px 16px',
          borderRadius: '12px',
          cursor: 'pointer',
          gap: 'var(--Components-Badge-Spacing-Y-LG, 12px)',
          border: isExpanded ? '1px solid #DFE0E7' : '1px solid white',
          transition: 'background-color 0.3s ease',
          '&:hover': {
          background: '#E1E4EA',
          border:'1px solid #E6E7EC',
  },
        }}
      >
        <GridView sx={{ height: '24px', width: '24px',color:'#0C0C0C', }} />
        <Typography
          sx={{
            color:isExpanded ? '#0C0C0C' : '#494949',
            lineHeight: '24px',
            fontSize: '16px',
            fontWeight: isExpanded ? 500 : 400,
            width: 'auto',
            display: 'flex',
            fontFamily:theme.typography.h4.fontFamily
          }}
        >
          Platforms
        </Typography>

        <ExpandMoreOutlined
          sx={{
            color:'#0C0C0C',
            height: '24px',
            width: '24px',
            ml: 'auto',
            display: 'flex',
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
        />
      </Box>

      {/* Expandable Content */}
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Box
          sx={{
            paddingLeft: '26px',
            backgroundColor: 'white',
            borderRadius: '8px',
            marginTop: '8px',
            gap:'4px',
            display:'flex',
            flexDirection:'column'
          }}
        >
          {platforms.map((val,ind)=>(
          <Box key={ind} onClick={() => handlePlatformOption(val.name)} sx={{ color: '#494949',height:'40px',cursor:'pointer', fontSize: '14px',boxSizing:'border-box',padding:'8px 10px',display:'flex',justifyContent:'',alignItems:'center',gap:'16px',borderRadius:'12px',border:activeSidebarOption.includes(val.name)? '1px solid #DFE0E7' : 'white',background:activeSidebarOption.includes(val.name)? '#F5F6F8': '',":hover":{borderRadius:'12px',background:'#E1E4EA'} }}>
         <img src={val.src} alt='' style={{height: '24px',width: '24px',objectFit: 'cover',}} />
          <Typography sx={{color:activeSidebarOption.includes(val.name)?'#0C0C0C' : '#494949',lineHeight: '24px',fontSize: '14px',fontWeight: 400,fontFamily:theme.typography.h4.fontFamily,textTransform:'capitalize'}}>{val.name}</Typography>
          </Box>
         ))}
        </Box>
      </Collapse>
      </>
  )
}

export default NewPlatforms
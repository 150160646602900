import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { main_Container, second_Box } from './activeSectionStyle'
import { facebookGetPagesApi } from '../../../../../API/PlatformsAPIs/Facebook'
import UserContext from '../../../../../Context/userContext'
import FilterOfActiveSection from './filterOfActiveSection'
import PageSection from './pageSection'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import CustomTableTanstack from '../../../../Table/customTableTanstack'
const ActiveSectionPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const basePath = currentPath.split('/').slice(0, 4).join('/')
  // console.log('basePath in table', basePath)
  const { id } = useParams()
  const { userAuth, setIsSkelton, stateCheck, currentUser } =
    useContext(UserContext)
  // console.log('currentUser in Active Section', currentUser)
  const [pagesNames, setPagesNames] = useState([])
  const [pagesData, setPagesData] = useState([])
  const [postedAndScheduledCount, setPostedAndScheduledCount] = useState([])
  // console.log('postedAndScheduledCount', postedAndScheduledCount)

  const getPages = async () => {
    setIsSkelton(true)
    try {
      const response = await facebookGetPagesApi(userAuth, id)
      if (response && response.data && Array.isArray(response.data)) {
        // console.log('Successfully retrieved pages', response.data)
        const res = response.data
        setPagesData(res)
        setPagesNames(res.map((val) => val.name))
      }
    } catch (error) {
      // console.error('Error retrieving pages:', error)
      setPagesData([])
      setPagesNames([])
      navigate(`/dashboard/facebook/${id}`)
    }
    setIsSkelton(false)
  }

  useEffect(() => {
    const spaceId = sessionStorage.getItem('spaceId')
    if (id !== spaceId) {
      navigate(`${basePath}/${spaceId}`)
    }
    const fetchData = async () => {
      if (userAuth) {
        await getPages(spaceId)
      }
    }
    fetchData()
  }, [userAuth, stateCheck, basePath, id, navigate])

  const [activeButton, setActiveButton] = useState('pages')
  const [openModal, setOpenModal] = useState(false)
  const handleModalOpen = (state) => {
    setOpenModal(state)
  }
  const theme = useTheme()
  return (
    <Box sx={main_Container(theme)}>
      <FilterOfActiveSection
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        handleModalOpen={handleModalOpen}
        openModal={openModal}
        pagesNames={pagesNames}
        pagesData={pagesData}
        postedAndScheduledCount={postedAndScheduledCount}
        currentUser={currentUser}
      />
      <Box sx={second_Box(theme)}>
        {activeButton === 'pages' ? (
          <>
            <PageSection
              handleModalOpen={handleModalOpen}
              openModal={openModal}
              pagesNames={pagesNames}
              pagesData={pagesData}
              spaceId={id}
              postedAndScheduledCount={postedAndScheduledCount}
            />

            <CustomTableTanstack
              pagesData={pagesData}
              userAuth={userAuth}
              spaceId={id}
              // openModal={openModal}
              handleModalOpen={handleModalOpen}
              basePath={basePath}
              postedAndScheduledCount={postedAndScheduledCount}
              setPostedAndScheduledCount={setPostedAndScheduledCount}
            />
          </>
        ) : activeButton === 'groups' ? (
          ''
        ) : null}
      </Box>
    </Box>
  )
}

export default ActiveSectionPage

import { Box, FormHelperText, TextField } from '@mui/material'
import React from 'react'
import {
  aiPromptResolver,
  aiPromptValidator,
} from '../../../../../Validators/authValidator'
import { useTheme } from '@emotion/react'

const AiPromptField = ({
  register,
  errors,
  touchedFields,
  textPrompt,
  setTextPrompt,
}) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        background: theme.palette.background.table,
        borderRadius: '10px',
        border: `1px solid ${theme.palette.border.extralight}`,
        gap: '10px',
        boxSizing: 'border-box',
        paddingBottom: '10px',
        mt: '5px',
      }}
    >
      <div
        style={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          background: '',
        }}
      >
        <TextField
          value={textPrompt}
          onChange={(e) => setTextPrompt(e.target.value)}
          variant='standard'
          placeholder='Type your prompt . . .'
          fullWidth
          sx={{
            background: theme.palette.background.table,
            width: '95%',
            mx: 'auto',
            overflowY: 'auto',
            color: theme.palette.text.light,

            '& textarea': {
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              color: theme.palette.text.main,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            },
          }}
          InputProps={{
            rows: 4,
            multiline: true,
            inputComponent: 'textarea',
            disableUnderline: true,
          }}
          // {...register('aiPrompt', aiPromptValidator)}
        />

        {/* <FormHelperText
          sx={{
            position: 'absolute',
            bottom: '-30px',
            color: 'red',
            ml: '0px',
          }}
        >
          {errors.aiPrompt && touchedFields.aiPrompt
            ? aiPromptResolver[errors.aiPrompt?.type]
            : ''}
        </FormHelperText> */}
      </div>
    </Box>
  )
}

export default AiPromptField

import React, { useContext, useState } from 'react'
import {
  badgeStyle,
  footerLogoBox,
  footerLogoImageStyle,
  footerTypography,
  footerUserNameBox,
  sidebarFooter,
} from '../sidebarStyle'
import { Badge, Box, Menu, Typography, useMediaQuery } from '@mui/material'
import UserContext from '../../../Context/userContext'
import { useTheme } from '@emotion/react'
import LogOutPopup from '../LogoutPopup'
import {
  CreditsIcon,
  SeatsIcon,
  SignoutSvg,
} from '../../PasswordStartAdornment'
import { useNavigate } from 'react-router-dom'
import {
  logOutPopupStyle,
  popup_MenuStyle,
  popupText_Style,
  signout_BoxStyle,
  subscriptionBoxStyles,
  upgradePlan_BoxStyles,
} from '../LogoutPopup/logoutPopupStyle'

const SideBarFooter = ({ drawer, handlemoreCreditsAndSeatsPopup }) => {
  const { setIsLoading, signOutContext, currentUser } = useContext(UserContext)
  const fullName = currentUser?.fullName
  const nameParts = fullName ? fullName.split(' ') : []
  const user = JSON.parse(window.localStorage.getItem('user'))
  const theme = useTheme()
  const navigate = useNavigate()
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleAnchorElOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleAnchorElClose = () => {
    setAnchorEl(null)
  }

  const handleOpen = (event) => {
    if (!drawer) {
      handleAnchorElOpen(event)
    } else {
      setOpen((prevOpen) => !prevOpen)
    }
  }

  const signoutBtn = async () => {
    try {
      setIsLoading(true)
      const signOutResponse = await signOutContext()
      console.log('signOutResponse response', signOutResponse)
      setIsLoading(false)
    } catch (error) {
      console.log('error of signout', error)
    }
  }

  return (
    <Box sx={{height:'fit-content',width:'100%',boxSizing:'border-box',padding:'16px',background:''}}>
      <Box
        onClick={(event) => handleOpen(event)}
        sx={sidebarFooter(theme, drawer, currentUser)}
      >
        {/* <Box sx={footerLogoBox(drawer)}> */}
        {currentUser?.imageUrl ? (
          <Badge
            color='success'
            overlap='circular'
            badgeContent=' '
            variant='dot'
            sx={badgeStyle(theme)}
          >
            <img
              src={currentUser?.imageUrl}
              alt='profile-Avatar'
              style={footerLogoImageStyle(drawer)}
            />
          </Badge>
        ) : (
          <Box sx={footerUserNameBox()}>
            {user?.fullName ? user.fullName[0] : ''}
          </Box>
        )}
        {/* </Box> */}
        <Typography sx={footerTypography(drawer, theme)}>
          {nameParts[0]}
        </Typography>
        {isLg && open && drawer && (
          <LogOutPopup
            open={open}
            handlemoreCreditsAndSeatsPopup={handlemoreCreditsAndSeatsPopup}
            drawer={drawer}
          />
        )}
      </Box>
      {!drawer && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleAnchorElClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            sx: popup_MenuStyle(theme),
          }}
        >
          <Box sx={logOutPopupStyle.mainBox(theme)}>
            <Box
              onClick={handlemoreCreditsAndSeatsPopup}
              sx={subscriptionBoxStyles(currentUser, theme)}
            >
              <Box sx={logOutPopupStyle.item_Box}>
                <SeatsIcon sx={{ height: '26px', width: '26px' }} />
              </Box>
              <Typography sx={popupText_Style(theme)}>
                Buy Seats & Credits
              </Typography>
            </Box>
            <Box
              onClick={() => navigate('/choose-plan')}
              sx={upgradePlan_BoxStyles(currentUser, theme)}
            >
              <CreditsIcon sx={{ height: '24px', width: '24px' }} />
              <Typography sx={popupText_Style(theme)}>Upgrade Plan</Typography>
            </Box>
            <Box onClick={signoutBtn} sx={signout_BoxStyle(theme)}>
              <SignoutSvg
                sx={{
                  height: '24px',
                  width: '24px',
                }}
              />
              <Typography sx={popupText_Style(theme)}>Log Out</Typography>
            </Box>
          </Box>
        </Menu>
      )}
    </Box>
  )
}

export default SideBarFooter

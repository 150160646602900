import {
  Badge,
  Box,
  Divider,
  IconButton,
  Menu,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import UserContext from '../../../Context/userContext'
import { useNavigate } from 'react-router-dom'
import { NotificationsNoneOutlined } from '@mui/icons-material'

import dashboardHeaderStyles, {
  itemsDropDown,
  main_Box,
  onlineText,
  profileMenuItem,
  signoutMenuItem,
  userNameText,
} from './dashboardHeaderStyles'
import { useTheme } from '@emotion/react'
import NotificationMenuItem from '../Notifications/notificationMenuItem'
import { HelpIcon, PersonSvg, SignoutSvg } from '../../PasswordStartAdornment'
import NavbarBreadcrumbs from './breadCrums'
import ColorMode from './lightanddarkmode'

const DashboardHeader = () => {
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const { currentUser, isLoading, setIsLoading, signOutContext } =
    useContext(UserContext)
  const user = JSON.parse(window.localStorage.getItem('user'))
  const navigate = useNavigate()

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const notificationMenuOpen = (event) => {
    setNotificationAnchorEl(event.currentTarget)
  }

  const notificationMenuClose = (state) => {
    console.log('state', state)
    setNotificationAnchorEl(false)
  }

  const userProfileNavigate = () => {
    navigate('/dashboard/userprofile')
    handleMenuClose()
  }

  const signOut = async () => {
    console.log('sign-Out', 'Loading', isLoading)
    try {
      setIsLoading(true)
      const signOutResponse = await signOutContext()
      console.log('signout', signOutResponse)
      setIsLoading(false)
    } catch (error) {
      console.log('error of signout', error)
    }
  }
  const theme = useTheme()
  return (
    <>
      <Box sx={main_Box(theme)}>
        <NavbarBreadcrumbs />
        <Box sx={dashboardHeaderStyles.midMainBox}>
          <ColorMode />
          <Badge
            badgeContent={4}
            sx={{
              cursor:'pointer',
              '& .MuiBadge-badge': {
                backgroundColor: 'red',
                color: theme.palette.customWhite.main,
                fontSize: '12px',
                top: '4px',
                right: '4px',
              },
            }}
          >
            <NotificationsNoneIcon
              sx={{ color: theme.palette.text.main, fontSize: '26px' }}
              onClick={notificationMenuOpen}
            />
          </Badge>
          <Box sx={dashboardHeaderStyles.userInfoBox}>
            {currentUser?.imageUrl ? (
              <Badge
                color='success'
                overlap='circular'
                badgeContent=' '
                variant='dot'
                sx={dashboardHeaderStyles.badge_Style(theme)}
              >
                <img
                  onClick={handleMenuOpen}
                  src={currentUser?.imageUrl}
                  alt='profile-Avatar'
                  style={{
                    ...dashboardHeaderStyles.buttonUserProfile(theme),
                    objectFit: 'cover',
                  }}
                />
              </Badge>
            ) : (
              <Box
                onClick={handleMenuOpen}
                sx={dashboardHeaderStyles.main_Menu_Box}
              >
                {user?.fullName ? user.fullName[0] : ''}
              </Box>
            )}
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              elevation={1}
              PaperProps={{
                sx: itemsDropDown(theme),
              }}
            >
              <Box sx={dashboardHeaderStyles.imageBoxStyle}>
                {currentUser?.imageUrl ? (
                  <Badge
                    color='success'
                    overlap='circular'
                    badgeContent=' '
                    variant='dot'
                    sx={dashboardHeaderStyles.badge_Style(theme)}
                  >
                    <img
                      src={currentUser?.imageUrl}
                      alt='profile-Avatar'
                      style={dashboardHeaderStyles.userImage(theme)}
                    />
                  </Badge>
                ) : (
                  <Box sx={dashboardHeaderStyles.user_Info_Box}>
                    {user?.fullName ? user.fullName[0] : ''}
                  </Box>
                )}
                <Box sx={{ width: '100%' }}>
                  <span style={dashboardHeaderStyles.spanStyle}>
                    <Typography sx={userNameText(theme)}>
                      {currentUser?.fullName}{' '}
                    </Typography>
                    <Box sx={dashboardHeaderStyles.planBox_Style(theme)}>
                      {currentUser?.Subscription?.planType
                        ? currentUser?.Subscription?.planType
                        : 'Member'}
                    </Box>
                  </span>
                  <Typography sx={onlineText(theme)}>
                    {currentUser?.email}
                  </Typography>
                </Box>
              </Box>
              <Box sx={dashboardHeaderStyles.devider_Box}>
                <Divider sx={dashboardHeaderStyles.devider_Style(theme)} />
              </Box>
              <Box onClick={userProfileNavigate} sx={profileMenuItem(theme)}>
                <PersonSvg style={dashboardHeaderStyles.custom_Style(theme)} />
                Profile
              </Box>
              <Box sx={profileMenuItem(theme)}>
                <HelpIcon style={dashboardHeaderStyles.custom_Style(theme)} />
                Help Center
              </Box>
              <Box sx={dashboardHeaderStyles.devider_Box}>
                <Divider sx={dashboardHeaderStyles.devider_Style(theme)} />
              </Box>
              <Box onClick={signOut} sx={profileMenuItem(theme)}>
                <SignoutSvg sx={dashboardHeaderStyles.custom_Style(theme)} />
                Sign Out
              </Box>
            </Menu>
          </Box>
        </Box>
      </Box>
      <NotificationMenuItem
        anchorEl={notificationAnchorEl}
        open={Boolean(notificationAnchorEl)}
        onClose={notificationMenuClose}
      />
    </>
  )
}

export default DashboardHeader

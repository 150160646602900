import { Box, Typography } from '@mui/material'
import React from 'react'
import {
  headerCloseLogo,
  headerLogo,
  headerTypography,
  sidebarHeader,
} from './sidebarStyle'
import appLogo from './../../Assets/Images/sideBarHeaderLogoLight.png'
import { useTheme } from '@emotion/react'
import { SideBarSwitcherSvg } from '../PasswordStartAdornment'
const SideBarHeader = ({ drawer, sideBarState }) => {
  const theme = useTheme()
  return (
    <Box sx={sidebarHeader(theme)}>
      {/* <Box sx={headerLogo()}> */}
      <img
        onClick={sideBarState}
        src={appLogo}
        alt='app-logo'
        height={38}
        width={38}
        style={{ cursor: 'pointer' }}
      />
      {/* </Box> */}
      <Typography sx={headerTypography(drawer, theme)}>Latics</Typography>
      <Box sx={headerCloseLogo(drawer, theme)}>
        <svg
          onClick={sideBarState}
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='25'
          viewBox='0 0 24 25'
          fill='none'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.6001 4.86406C4.94324 4.86406 3.6001 6.2072 3.6001 7.86406V17.1368C3.6001 18.7936 4.94324 20.1368 6.6001 20.1368H17.4001C19.0569 20.1368 20.4001 18.7936 20.4001 17.1368V7.86406C20.4001 6.20721 19.0569 4.86406 17.4001 4.86406H6.6001ZM12.6258 6.3916C11.5212 6.3916 10.6258 7.28703 10.6258 8.3916V16.6098C10.6258 17.7143 11.5212 18.6098 12.6258 18.6098H16.7203C17.8249 18.6098 18.7203 17.7143 18.7203 16.6098V8.3916C18.7203 7.28703 17.8249 6.3916 16.7203 6.3916H12.6258Z'
            fill={theme.palette.text.light}
          />
        </svg>
      </Box>
    </Box>
  )
}

export default SideBarHeader

import { useTheme } from '@emotion/react'
import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import AudienceTarget from './audienceTarget'
import AgeRestriction from './ageRestriction'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Category from './category'
import CommentsandRatings from './commentsRating'
import AddTag from './addTags'
import VisibilityVideo from './visibilityVideo'

const ShowMore = ({
  audience,
  setAudience,
  ageRestriction,
  setAgeRestriction,
  comments,
  setComments,
  category,
  setCategories,
  tags,
  setTags,
  selectedCategory,
  setSelectedCategory,
  videoVisibility,
  setVideoVisibility,
  loading,
}) => {
  const [show, setShow] = useState(false)
  const theme = useTheme()

  const toggleShow = () => {
    setShow((prevShow) => !prevShow)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Button
        onClick={toggleShow}
        startIcon={
          show ? (
            <KeyboardArrowUpIcon sx={{ color: 'black' }} />
          ) : (
            <KeyboardArrowDownIcon sx={{ color: 'black' }} />
          )
        }
        sx={{
          width: 'fit-content',
          boxSizing: 'border-box',
          padding: '11px var(--Numbers-Number-8, 14px)',
          borderRadius: 'var(--Numbers-Number-32, 64px)',
          background: theme.palette.background.light,
          textTransform: 'capitalize',
          border: `1px solid  ${theme.palette.border.extralight}`,
          color: theme.palette.text.main,
          fontFamily: theme?.typography.h2.fontFamily,
          fontSize: theme?.typography.h5.fontSize,
          fontWeight: theme?.typography.h2.fontWeight,
          fontStyle: 'normal',
          lineHeight: 'normal',
          letterSpacing: '0.075px',
          ':hover': { background: theme.palette.background.light },
        }}
      >
        {show ? 'Show Less' : 'Show More'}
      </Button>
      {show && (
        <>
          <VisibilityVideo
            videoVisibility={videoVisibility}
            setVideoVisibility={setVideoVisibility}
            loading={loading}
          />
          <AudienceTarget
            audience={audience}
            setAudience={setAudience}
            loading={loading}
          />
          <AgeRestriction
            audience={audience}
            ageRestriction={ageRestriction}
            setAgeRestriction={setAgeRestriction}
            loading={loading}
          />
          <AddTag tags={tags} setTags={setTags} loading={loading} />{' '}
          <Category
            category={category}
            setCategories={setCategories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            loading={loading}
          />
          <CommentsandRatings
            comments={comments}
            setComments={setComments}
            loading={loading}
          />
        </>
      )}
    </Box>
  )
}

export default ShowMore

import {
  Modal,
  Box,
  Typography,
  IconButton,
  Radio,
  Button,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
} from '@mui/material'
import { Add, CloseRounded, Remove } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { useTheme } from '@emotion/react'
import { useContext, useState } from 'react'
import { Loader } from '@infostacks/ui-components'
import {
  handleRevertCreditsAPI,
  handleShareCreditsAPI,
} from '../../../../../API/Stripe'
import UserContext from '../../../../../Context/userContext'
import { Checkbox } from 'antd'
import styleMembersCreditsModel, {
  cancel_Button,
  confirm_Button,
  confirmtion_Text,
  creditsText_Style,
  description_Text,
  field_Box_Style,
  icon_Box_Style,
  mangeCreditsText,
  radio_Button,
} from './styleMembersCreditsModel'

const MembersCreditsModel = ({
  currentRowData,
  open,
  onClose,
  setApiError,
  setActionDetect,
}) => {
  const { userAuth, updateUserContext, currentUser } = useContext(UserContext)
  const { register, handleSubmit } = useForm()
  const theme = useTheme()
  const [credits, setCredits] = useState(500)
  const [checkbox, setChechbox] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selected, setSelectedVlaue] = useState('send')
  const spaceId = window.sessionStorage.getItem('spaceId')

  const handleClose = () => {
    setCredits(500)
    setChechbox(false)
    setSelectedVlaue('send')
    onClose()
  }

  const handleRadioSelected = (event) => {
    setSelectedVlaue(event.target.value)
  }
  const onSubmit = async (data) => {
    setLoading(true)
    setActionDetect(false)
    try {
      const updatedData = { ...data, amount: credits }
      if (userAuth && spaceId && updatedData?.creditType === 'send') {
        delete updatedData.creditType
        updatedData.workspaceId = spaceId
        updatedData.memberId = currentRowData?.memberId
        const resp = await handleShareCreditsAPI(userAuth, updatedData)
        console.log('resp', resp)
        if (resp.message === 'Credits shared successfully') {
          const updateUser = await updateUserContext(
            userAuth,
            { fullName: currentUser?.fullName },
            currentUser?._id
          )
          setActionDetect(true)
          setApiError('Credits shared successfully')
          handleClose()
        }
        if (resp.status === 400) {
          setActionDetect(true)
          setApiError(resp.error)
          handleClose()
        }
      }
      if (userAuth && spaceId && updatedData?.creditType === 'reclaim') {
        delete updatedData.creditType
        updatedData.workspaceId = spaceId
        updatedData.memberId = currentRowData?.memberId
        const resp = await handleRevertCreditsAPI(userAuth, updatedData)
        console.log('revert resp', resp)
        if (resp?.message?.includes('Credits reverted successfully')) {
          const updateUser = await updateUserContext(
            userAuth,
            { fullName: currentUser?.fullName },
            currentUser?._id
          )
          setActionDetect(true)
          setApiError('Credits reverted successfully')
          handleClose()
        }
        if (resp.status === 400) {
          setActionDetect(true)
          setApiError(resp.error)
          handleClose()
        }
      }
    } catch (error) {
      console.log('error', error)
    }
    setLoading(false)
  }

  const handleCredits = (val) => {
    // If the input is empty, reset the credits to the default value (500)
    if (val === '') {
      setCredits()
    } else {
      // Set the value if it's not empty, ensuring it's at least 1
      setCredits(Math.max(1, Number(val)))
    }
  }

  const handleCheckBox = (state) => {
    setChechbox(state)
  }
  return (
    <Modal open={open} sx={styleMembersCreditsModel.modal_Style}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={styleMembersCreditsModel.form_Style(theme)}
      >
        <Box sx={styleMembersCreditsModel.mangeCredits_Box(theme)}>
          <Typography sx={mangeCreditsText(theme)}>
            Manage {currentRowData?.fullName} Credits
          </Typography>
          <IconButton
            disabled={loading}
            onClick={() => handleClose(false)}
            sx={{ height: '32px', width: '32px' }}
          >
            <CloseRounded sx={{ color: theme.palette.text.main }} />
          </IconButton>
        </Box>
        <Typography sx={description_Text(theme)}>
          Credits are used for AI interactions. Seats allow additional team
          members to access the platform.
        </Typography>
        <Box sx={styleMembersCreditsModel.radioBtn_Box}>
          <RadioGroup value={selected} row onChange={handleRadioSelected}>
            <FormControlLabel
              label='Send'
              value='send'
              sx={{
                color: theme.palette.text.main,
                '& .MuiFormControlLabel-label.Mui-disabled': {
                  color: theme.palette.text.main,
                },
              }}
              control={
                <Radio
                  disabled={loading}
                  size='small'
                  {...register('creditType')}
                  sx={radio_Button(theme)}
                />
              }
            />
            <FormControlLabel
              label='Reclaim'
              value='reclaim'
              sx={{
                color: theme.palette.text.main,
                '& .MuiFormControlLabel-label.Mui-disabled': {
                  color: theme.palette.text.main,
                },
              }}
              control={
                <Radio
                  disabled={loading}
                  size='small'
                  {...register('creditType')}
                  sx={radio_Button(theme)}
                />
              }
            />
          </RadioGroup>
        </Box>
        <Box sx={styleMembersCreditsModel.field_Box}>
          <Typography sx={creditsText_Style(theme)}>Credits</Typography>
          <Box sx={field_Box_Style(theme)}>
            <Box sx={styleMembersCreditsModel.innerStyleField(theme)}>
              <input
                value={credits}
                onChange={(e) => handleCredits(e.target.value)}
                type='number'
                style={{
                  border: 'none',
                  background: theme.palette.background.light,
                  color: theme.palette.text.main,
                  width: '100%',
                  outline: 'none',
                }}
              />
            </Box>
            <Box sx={icon_Box_Style(theme)}>
              <IconButton
                disabled={credits <= 1 || loading}
                onClick={() => setCredits((prev) => prev - 1)}
              >
                <Remove sx={{ color: theme.palette.text.main }} />
              </IconButton>
            </Box>
            <Box sx={icon_Box_Style(theme)}>
              <IconButton
                disabled={loading}
                onClick={() => setCredits((prev) => prev + 1)}
              >
                <Add sx={{ color: theme.palette.text.main }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box sx={styleMembersCreditsModel.confirmtion_Box}>
          <Checkbox
            disabled={loading}
            onClick={() => handleCheckBox(!checkbox)}
          />
          <Typography sx={confirmtion_Text(theme)}>
            I confirm that I want to share <b>{credits}</b> AI Credits.
          </Typography>
        </Box>
        <Box sx={styleMembersCreditsModel.fotterButton_Box}>
          <Button
            disabled={loading}
            sx={cancel_Button(theme)}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={credits <= 0 || !checkbox || !credits}
            sx={confirm_Button(checkbox, theme)}
            type='submit'
          >
            {loading ? (
              // <Loader size={22} thickness={7} color='#FFFFFF' />
              <CircularProgress
                size={22}
                thickness={6}
                sx={{
                  color: 'white',
                }}
              />
            ) : (
              'Confirm'
            )}
          </Button>
        </Box>
      </form>
    </Modal>
  )
}

export default MembersCreditsModel

import { CloseRounded } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useTheme } from '@emotion/react'
import instaFormSyle from './instaFormStyle'

const ManualPostHeader = ({ handleClose, loading }) => {
  const theme = useTheme()
  return (
    <Box sx={instaFormSyle.modelHeader}>
      <Typography sx={instaFormSyle.createNewPost_Text(theme)}>
        Create new post
      </Typography>
      <IconButton
        disabled={loading}
        onClick={handleClose}
        sx={{ boxSizing: 'border-box', padding: '0px' }}
      >
        <CloseRounded
          sx={{
            cursor: 'pointer',
            fontSize: '24px',
            color: theme.palette.text.main,
          }}
        />
      </IconButton>
    </Box>
  )
}

export default ManualPostHeader

import { useTheme } from '@emotion/react'
import { Box, TextField } from '@mui/material'
import React, { useContext } from 'react'
import UserContext from '../../../../../../Context/userContext'

const DescriptionField = ({
  descriptionField,
  setDescriptionField,
  register,
  loading,
}) => {
  const theme = useTheme()
  const { postData } = useContext(UserContext)

  return (
    <div
      style={{
        width: '550px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        background: '',
      }}
    >
      <label
        style={{
          color: theme.palette.text.light,
          fontFamily: theme?.typography.h2.fontFamily,
          fontSize: theme?.typography.h5.fontSize,
          fontWeight: theme?.typography.h4.fontWeight,
          fontStyle: 'normal',
          lineHeight: '18px',
        }}
      >
        Description{' '}
      </label>
      <TextField
        disabled={loading}
        value={descriptionField || postData?.description}
        {...register('description')}
        onChange={(e) => setDescriptionField(e.target.value)}
        variant='standard'
        fullWidth
        sx={{
          boxSizing: 'border-box',
          borderRadius: '10px',
          padding:
            ' var(--Basic-Forms-Input-Placeholder-Spacing-Y, 14px) var(--Basic-Forms-Input-Placeholder-Spacing-X, 16px)',
          gap: '10px',
          border: `1px solid ${theme.palette.border.extralight}`,
          width: '100%',
          mx: 'auto',
          overflowY: 'auto',
          '& textarea': {
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            color: theme.palette.text.light,
            fontFamily: theme?.typography.h2.fontFamily,
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        }}
        InputProps={{
          rows: 5,
          multiline: true,
          inputComponent: 'textarea',
          disableUnderline: true,
        }}
      />
    </div>
  )
}

export default DescriptionField

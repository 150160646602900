import {
  benefitIcon1,
  benefitIcon2,
  benefitIcon3,
  benefitIcon4,
  benefitImage2,
  chromecast,
  disc02,
  discordBlack,
  facebook,
  file02,
  homeSmile,
  instagram,
  notification2,
  notification3,
  notification4,
  plusSquare,
  recording01,
  recording03,
  roadmap1,
  roadmap2,
  roadmap3,
  roadmap4,
  searchMd,
  sliders04,
  telegram,
  twitter,
} from '../assets'
import { links } from '../config'
import fbicon from '../../../../Assets/Images/facebook.png'
import insticon from '../../../../Assets/Images/instagram.png'
import xicon from '../../../../Assets/Images/x.png'
import youtubeicon from '../../../../Assets/Images/youtube.png'
import pinicon from '../../../../Assets/Images/pinterest.png'
import lnicon from '../../../../Assets/Images/linkedin.png'
import tikicon from '../../../../Assets/Images/tiktok.png'
import googleicon from '../../../../Assets/Images/google.png'
import hireUp from '../../../../Assets/Images/hireUp.png'
import nexxtLogo from '../../../../Assets/Images/nexxtAiLogo.png'
import infostackLogo from '../../../../Assets/Images/infoStackLogo.png'
import tntLogo from '../../../../Assets/Images/tntLogo.png'
import abalLogo from '../../../../Assets/Images/abalLogo.png'
export const navigation = [
  {
    id: '0',
    title: 'Features',
    url: '#features',
  },
  {
    id: '1',
    title: 'Pricing',
    url: '#pricing',
  },
  {
    id: '2',
    title: 'How to use',
    url: '#how-to-use',
  },
  {
    id: '3',
    title: 'Roadmap',
    url: '#roadmap',
  },
  {
    id: '4',
    title: 'Source Code',
    url: links.sourceCode,
    onlyMobile: true,
    external: true,
  },
]

export const heroIcons = [homeSmile, file02, searchMd, plusSquare]

export const notificationImages = [notification4, notification3, notification2]

export const companyLogos = [
  { logo: nexxtLogo, name: 'Nexxt AI' },
  { logo: infostackLogo, name: 'Infostack' },
  { logo: hireUp, name: 'HireAible' },
  { logo: tntLogo, name: 'TracknTruck' },
  { logo: abalLogo, name: 'Abal' },
]
export const ailaticsServices = [
  'Photo generating',
  'Photo enhance',
  'Seamless Integration',
]
export const ailaticsServicesIcons = [
  recording03,
  recording01,
  disc02,
  chromecast,
  sliders04,
]

export const roadmap = [
  {
    id: '0',
    title: 'Voice recognition',
    text: 'Enable the chatbot to understand and respond to voice commands, making it easier for users to interact with the app hands-free.',
    date: 'May 2023',
    status: 'done',
    imageUrl: roadmap1,
    colorful: true,
  },
  {
    id: '1',
    title: 'Gamification',
    text: 'Add game-like elements, such as badges or leaderboards, to incentivize users to engage with the chatbot more frequently.',
    date: 'May 2023',
    status: 'progress',
    imageUrl: roadmap2,
  },
  {
    id: '2',
    title: 'Chatbot customization',
    text: "Allow users to customize the chatbot's appearance and behavior, making it more engaging and fun to interact with.",
    date: 'May 2023',
    status: 'done',
    imageUrl: roadmap3,
  },
  {
    id: '3',
    title: 'Integration with APIs',
    text: 'Allow the chatbot to access external data sources, such as weather APIs or news APIs, to provide more relevant recommendations.',
    date: 'May 2023',
    status: 'progress',
    imageUrl: roadmap4,
  },
]

export const collabText =
  'Bring all your social media platforms together in one place, with intuitive tools for effortless collaboration and management.'
export const collabTextOne =
  'Stay ahead of the curve with smart automation that creates trending content tailored to your audience.'
export const collabTextTwo =
  'Enjoy peace of mind with top-notch security measures, ensuring your data and accounts remain protected.'
export const socialCollabTextTwo =
  '  Empowered by generative AI and robust security, it’s the ultimate solution for teams and creators aiming to excel in digital media management.'
export const collabContent = [
  {
    id: '0',
    title: 'Unified Integration',
    text: collabText,
  },
  {
    id: '1',
    title: 'Generative AI Excellence',
    text: collabTextOne,
  },
  {
    id: '2',
    title: 'Robust Security',
    text: collabTextTwo,
  },
]

export const collabApps = [
  {
    id: '0',
    title: 'Facebook',
    icon: fbicon,
    width: 26,
    height: 36,
  },
  {
    id: '1',
    title: 'Instagram',
    icon: insticon,
    width: 32,
    height: 32,
  },
  {
    id: '2',
    title: 'LinkedIn',
    icon: lnicon,
    width: 40,
    height: 32,
  },
  {
    id: '3',
    title: 'Youtube',
    icon: youtubeicon,
    width: 32,
    height: 32,
  },
  {
    id: '4',
    title: 'TiikTok',
    icon: tikicon,
    width: 32,
    height: 32,
  },
  {
    id: '5',
    title: 'Pinterest',
    icon: pinicon,
    width: 34,
    height: 34,
  },
  {
    id: '6',
    title: 'Google',
    icon: googleicon,
    width: 26,
    height: 34,
  },
  {
    id: '7',
    title: 'X',
    icon: xicon,
    width: 42,
    height: 36,
  },
]

export const pricing = [
  {
    id: '0',
    title: 'Basic',
    description: 'Advanced AI chatbot, analytics, analytics dashboard',
    price: '0.00',
    features: [
      '1 Workspace',
      '1 Ai post',
      '3 post per Platform',
      '5MB Storage',
      'Optional credit top ups',
      '3 concurrent fast jobs',
      '3 concurrent fast jobs',
    ],
    premium: false,
    contact: false,
  },
  {
    id: '1',
    title: 'Premium',
    description: 'Advanced AI chatbot, analytics, analytics dashboard',
    price: '210.98',
    features: [
      '10M Credits',
      '2 Users',
      '30h Fast generations',
      'Unlimited Relaxed generations',
      ' General commercial terms',
      'Access to member gallery',
      'Optional credit top ups',
    ],
    premium: true,
    contact: false,
  },
  {
    id: '2',
    title: 'Enterprise',
    description: 'Advanced AI chatbot, analytics, analytics dashboard',
    price: '120.00',
    features: [
      '10M Credits',

      ' 60h Fast generations',

      ' Unlimited Relaxed generations',

      'General commercial terms',

      ' Access to member gallery',

      ' Optional credit top ups',

      ' 12 concurrent fast jobs',
    ],
    premium: false,
    contact: true,
  },
]

export const benefits = [
  {
    id: '0',
    title: 'One-Stop Window for Digital Media',
    text: 'Manage all your digital media platforms from a single interface, simplifying your workflow and saving time.',
    backgroundUrl: '/src/assets/benefits/card-1.svg',
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: '1',
    title: 'Generative AI Content Creation',
    text: 'Leverage advanced AI to generate videos, audio, text, and graphics that align with current and future trends.',
    backgroundUrl: '/src/assets/benefits/card-2.svg',
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: '2',
    title: 'AI-Powered Scheduler',
    text: 'An intelligent scheduler that acts as your AI agent, determining the best content to post and the optimal time to post it.',
    backgroundUrl: '/src/assets/benefits/card-3.svg',
    iconUrl: benefitIcon3,
    imageUrl: benefitImage2,
  },
  {
    id: '3',
    title: 'Branding Genie',
    text: 'Transform your brand with AI-powered tools that create compelling branding assets and strategies.',
    backgroundUrl: '/src/assets/benefits/card-4.svg',
    iconUrl: benefitIcon4,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: '4',
    title: 'AI Shop and Marketplace Management',
    text: 'Create products, manage orders, and run promotions seamlessly across digital platforms with AI-driven tools.',
    backgroundUrl: '/src/assets/benefits/card-5.svg',
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: '5',
    title: 'AI Agents for Customer Engagement',
    text: 'Automate customer support, sales, tracking, and email responses using AI agents trained on your knowledge base.',
    backgroundUrl: '/src/assets/benefits/card-6.svg',
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
  },
]

export const socials = [
  {
    id: '0',
    title: 'Discord',
    iconUrl: discordBlack,
    url: '#',
  },
  {
    id: '1',
    title: 'Twitter',
    iconUrl: twitter,
    url: '#',
  },
  {
    id: '2',
    title: 'Instagram',
    iconUrl: instagram,
    url: '#',
  },
  {
    id: '3',
    title: 'Telegram',
    iconUrl: telegram,
    url: '#',
  },
  {
    id: '4',
    title: 'Facebook',
    iconUrl: facebook,
    url: '#',
  },
]

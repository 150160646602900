import { Box, Button, Divider, Modal, Typography } from '@mui/material'
import React, { useCallback, useContext, useState } from 'react'
import { useTheme } from '@emotion/react'
import UserContext from '../../../../../../Context/userContext'
import { useDropzone } from 'react-dropzone'
import ImageEditModalPintura from './ImageSection/imageEditModal'
import ManualPostHeader from './manualPostHeader'
import CaptionEmojiAndOptions from './CaptionEmojiAndOptions'
import ImageSection from './ImageSection'
import ManualPostFooter from './ManualPostFooter'
import instaFormSyle, { generateBtn, mediaBtn } from './instaFormStyle'
import {
  instagramCreatePostApi,
  instagramEditPostApi,
  instagramSchedulePostApi,
} from '../../../../../../API/PlatformsAPIs/InstagramApi'
import { useParams } from 'react-router-dom'
import ReuseableCalender from '../../../../../ReusableComponents/reuseableCalender'
import {
  AiWhiteIconSvg,
  MediaIconSvg,
} from '../../../../../PasswordStartAdornment/svgFile'
const ManualPostForm = ({
  handleClose,
  loading,
  setLoading,
  media,
  setMedia,
  caption,
  setCaption,
  getInstaPosts,
  filterBtns,
  setFilterBtns,
}) => {
  const { pageId, igUserId, id } = useParams()
  // console.log('pageId',pageId,'instaId',instaId,'spaceId',id)
  const theme = useTheme()
  const { userAuth, setAiModalOpen, postData, ScheduleTime, setScheduleTime } =
    useContext(UserContext)
  const [mediaType, setMediaType] = useState(postData?.contentType || 'post')
  const [imageSrc, setImageSrc] = useState(null)
  const [editImageIndex, setEditimageIndex] = useState(null)
  const [editModal, setEditModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState('Publish')
  const [anchorEl, setAnchorEl] = useState(null)
  const [timeModal, setTimeModal] = useState(false)
  // const [ScheduleTime, setScheduleTime] = useState('')
  console.log('selectedOption', selectedOption)

  const handleForm = async (option) => {
    setLoading(true)
    const formData = new FormData()
    // Add caption if not a "Story" or empty
    if (mediaType !== 'story' && caption.length > 0) {
      formData.append('caption', caption)
    }
    // Append each media file to the FormData object

    media.forEach((mediaItem, index) => {
      console.log(`Appending file ${index}:`, mediaItem.file) // Ensure it's the edited image
      formData.append(`files`, mediaItem.file)
    })
    // Log form data content
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1])
    }

    try {
      if (option === 'Publish') {
        formData.append('postStatus', 'posted')
        // console.log('formData before', formData.getAll('files'))

        const resp = await instagramCreatePostApi(
          userAuth,
          pageId,
          igUserId,
          id,
          formData,
          mediaType
        )
        console.log('posted resp', resp)
        handleClose()
        getInstaPosts()
      }
      if (option === 'Schedule') {
        formData.append('scheduleTime', ScheduleTime)
        const resp = await instagramSchedulePostApi(
          userAuth,
          id,
          pageId,
          igUserId,
          mediaType,
          formData
        )
        console.log('Schedule resp', resp)
        handleClose()
        getInstaPosts()
      }
      if (option === 'Draft') {
        formData.append('postStatus', 'drafted')
        console.log('Ready for Draft')
        const resp = await instagramCreatePostApi(
          userAuth,
          pageId,
          igUserId,
          id,
          formData,
          mediaType
        )
        console.log('Draft resp', resp)
        handleClose()
        setFilterBtns(filterBtns)
      }
      if (option === 'ScheduleToPublish') {
        console.log('Ok ScheduleToPublish')
        formData.append('postStatus', 'posted')
        formData.append('id', postData._id)
        const resp = await instagramCreatePostApi(
          userAuth,
          pageId,
          igUserId,
          id,
          formData,
          mediaType
        )
        console.log('posted resp', resp)

        handleClose()
        getInstaPosts()
      }
      if (option === 'ScheduleToEdit') {
        console.log('ok ScheduleToEdit')
        formData.append('scheduleTime', ScheduleTime)
        const resp = await instagramEditPostApi(
          userAuth,
          id,
          pageId,
          igUserId,
          mediaType,
          postData?._id,
          formData
        )
        console.log('ScheduleToEdit resp', resp)
        handleClose()
        getInstaPosts()
      }
      if (option === 'DraftToEdit') {
        console.log('ok DraftToEdit')
        console.log('formData before', formData.getAll('caption'))
        const resp = await instagramEditPostApi(
          userAuth,
          id,
          pageId,
          igUserId,
          mediaType,
          postData?._id,
          formData
        )
        console.log('DraftToEdit resp', resp)
        handleClose()
        getInstaPosts()
      }
      if (option === 'DraftToPublish') {
        console.log('ok DraftToPublish', postData._id)
        formData.append('postStatus', 'posted')
        formData.append('id', postData?._id)

        console.log('formData before', formData.getAll('id'))
        const resp = await instagramCreatePostApi(
          userAuth,
          pageId,
          igUserId,
          id,
          formData,
          mediaType
        )
        console.log('posted resp', resp)
        handleClose()
        getInstaPosts()
      }
      if (option === 'DraftToSchedule') {
        console.log('ok DraftToSchedule')
        formData.append('scheduleTime', ScheduleTime)
        const resp = await instagramEditPostApi(
          userAuth,
          id,
          pageId,
          igUserId,
          mediaType,
          postData?._id,
          formData
        )
        console.log('ScheduleToEdit resp', resp)
        handleClose()
        getInstaPosts()
      }
    } catch (error) {
      console.log('error', error)
    }
    setLoading(false)
  }

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      console.log('acceptedFiles', acceptedFiles)
      const newMedia = acceptedFiles.map((file) => {
        const objectUrl = URL.createObjectURL(file)
        console.log('objectUrl', objectUrl)
        return { file, url: objectUrl }
      })
      setMedia((prevMedia) => {
        const currentMedia = Array.isArray(prevMedia) ? prevMedia : []
        const combinedMedia = [...currentMedia, ...newMedia]
        return combinedMedia.slice(0, 8)
      })
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 8,
    accept: {
      'image/png': [],
      'image/jpeg': [],
      'video/mp4': [],
    },
  })

  const handleClosebtn = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOptionSelect = (option) => {
    console.log('option', option)
    if (option === 'Publish') {
      handleClosebtn()
      setSelectedOption(option)
    }
    if (option === 'Schedule') {
      handleClosebtn()
      setSelectedOption(option)
      setTimeModal(true)
    }
    if (option === 'Draft') {
      handleClosebtn()
      setSelectedOption(option)
    }
    if (option === 'ScheduleToPublish') {
      handleClosebtn()
      setSelectedOption(option)
      handleForm(option)
    }
    if (option === 'ScheduleToEdit') {
      handleClosebtn()
      setSelectedOption(option)
      handleForm(option)
    }
    if (option === 'DraftToEdit') {
      handleClosebtn()
      setSelectedOption(option)
      handleForm(option)
    }
    if (option === 'DraftToPublish') {
      // handleClosebtn()
      setSelectedOption('Publish')
      // handleForm(option)
    }
    if (option === 'DraftToSchedule') {
      // handleClosebtn()
      setSelectedOption('Schedule')
      // handleForm(option)
      setTimeModal(true)
    }
  }

  const confirmDate = (dateTime, state) => {
    console.log('dateTime', dateTime, 'state', state)

    setScheduleTime(dateTime)
    setTimeModal(false)
  }

  return (
    <>
      {editModal ? (
        <ImageEditModalPintura
          editModal={editModal}
          setEditModal={setEditModal}
          setMedia={setMedia}
          editImageIndex={editImageIndex}
          imageSrc={imageSrc}
        />
      ) : (
        <form style={instaFormSyle.postForm(theme)}>
          <ManualPostHeader handleClose={handleClose} loading={loading} />
          <Divider
            sx={{ width: '100%', background: theme.palette.border.extralight }}
          />
          {media.length === 0 ? (
            <Box sx={instaFormSyle.uploadBox(theme)}>
              <MediaIconSvg style={{ color: theme.palette.text.main }} />
              <Typography sx={instaFormSyle.uplodeMedia_Text(theme)}>
                Upload Media Here
              </Typography>
              <Box sx={instaFormSyle.button_generateBox}>
                <Button
                  onClick={() => setAiModalOpen(true)}
                  startIcon={<AiWhiteIconSvg />}
                  sx={generateBtn(theme)}
                >
                  Generate
                </Button>
                <Button {...getRootProps()} sx={mediaBtn(theme)}>
                  Select Media
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <CaptionEmojiAndOptions
                setAiModalOpen={setAiModalOpen}
                mediaType={mediaType}
                caption={caption}
                setCaption={setCaption}
                loading={loading}
              />
              <ImageSection
                getRootProps={getRootProps}
                media={media}
                setMedia={setMedia}
                setImageSrc={setImageSrc}
                setEditimageIndex={setEditimageIndex}
                setEditModal={setEditModal}
                mediaType={mediaType}
                setMediaType={setMediaType}
                loading={loading}
              />
              <ManualPostFooter
                loading={loading}
                handleOptionSelect={handleOptionSelect}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                anchorEl={anchorEl}
                handleClosebtn={handleClosebtn}
                handleClick={handleClick}
                handleForm={handleForm}
                ScheduleTime={ScheduleTime}
                setTimeModal={setTimeModal}
                filterBtns={filterBtns}
                setFilterBtns={setFilterBtns}
              />
            </>
          )}
        </form>
      )}
      {timeModal && (
        <Modal
          open={timeModal}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              setTimeModal(false)
            }
          }}
          sx={instaFormSyle.calender_Box}
        >
          <ReuseableCalender
            timeModal={timeModal}
            setTimeModal={setTimeModal}
            confirmDate={confirmDate}
          />
        </Modal>
      )}
    </>
  )
}

export default ManualPostForm

import React, { useContext, useEffect, useState } from 'react'
import ReusableTable from '../../../ReusableComponents/customTable'
import {
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import { MoreVertRounded } from '@mui/icons-material'
import {
  formatDateString,
  resendInviteMemberAPI,
  workspaceMembersAPI,
} from '../../../../API'
import UserContext from '../../../../Context/userContext'
import { Loader } from '@infostacks/ui-components'
import workspaceProfilePageStyle from './workspaceProfilePageStyle'

const InvitedMembersTable = ({ spaceId, renderInviteTable, spaceData }) => {
  const { userAuth, currentUser } = useContext(UserContext)
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [otherMembers, setotherMembers] = useState([])
  // console.log('spaceId', spaceId)
  // console.log('setotherMembers in invited table', otherMembers)

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget)
    setSelectedRow(row)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSelectedRow(null)
  }

  const handleResendInvite = async (row) => {
    handleMenuClose()
    setLoading(true)
    const body = {
      inviteId: row.memberId,
      senderName: currentUser.fullName,
      pageName: 'invitation',
      workspaceId: window.sessionStorage.getItem('spaceId'),
    }
    // console.log('Change Role for:', body)
    const respOfResendInvite = await resendInviteMemberAPI(userAuth, body)
    // console.log('respOfResendInvite', respOfResendInvite)
    await invitedMembers(spaceId)
  }

  const invitedMembers = async (spaceId) => {
    setLoading(true)
    try {
      const workspaceMemberResponse = await workspaceMembersAPI(
        userAuth,
        spaceId
      )
      // console.log('workspaceMemberResponse', workspaceMemberResponse)
      const otherStatusMember = workspaceMemberResponse.filter(
        (val) =>
          val.status === 'pending' ||
          val.status === 'resent' ||
          val.status === 'rejected'
      )
      setotherMembers(otherStatusMember)
    } catch (error) {
      console.error('Error fetching space data', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (userAuth) {
        //  const spaceId = window.localStorage.getItem('DashBoardSpaceId')
        await invitedMembers(spaceId)
      }
    }
    fetchData()
  }, [userAuth, renderInviteTable])

  const data = otherMembers

  const columns = [
    {
      field: 'email',
      headerName: 'Email',
      renderCell: (row) => (
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h4.fontSize,
            },
            fontFamily: theme.typography.h4.fontFamily,
            fontWeight: theme.typography.h4.fontWeight,
            color: theme.palette.text.main,
            lineHeight: '24px',
          }}
        >
          {row.email}
        </Typography>
      ),
    },
    {
      field: 'roleName',
      headerName: 'Role',
      renderCell: (row) => (
        <Chip
          label={row.roleName}
          sx={{
            padding: '0px',
            width: '100px',
            boxSizing: 'border-box',
            borderRadius: '8px',
            fontFamily: theme.typography.h4.fontFamily,
            fontSize: '12px',
            backgroundColor:
              row.roleName === 'Owner'
                ? '#CCFBF1'
                : row.roleName === 'Admin'
                ? '#CCE0FF'
                : row.roleName === 'Manager'
                ? '#FEF9C3'
                : null,
            color:
              row.roleName === 'Owner'
                ? '#115E59'
                : row.roleName === 'Admin'
                ? '#1F2937'
                : row.roleName === 'Manager'
                ? '#854D0E'
                : theme.palette.text.main,
          }}
        />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (row) => (
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
            },
            fontFamily: theme.typography.h5.fontFamily,
            fontWeight: theme.typography.h5.fontWeight,
            color: theme.palette.text.light,
            lineHeight: '20px',
          }}
        >
          {row.status}
        </Typography>
      ),
    },
    {
      field: 'inviteDate',
      headerName: 'Invite Date',
      renderCell: (row) => (
        <Typography
          sx={{
            fontSize: {
              xs: theme.typography.h5.fontSize,
            },
            fontFamily: theme.typography.h5.fontFamily,
            fontWeight: theme.typography.h5.fontWeight,
            color: theme.palette.text.light,
            lineHeight: '20px',
          }}
        >
          {formatDateString(row.inviteDate)}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (row) => (
        <IconButton
          disabled={
            spaceData?.Permissions?.workSpace['Resend Invite'] === false
          }
          onClick={(event) => handleMenuOpen(event, row)}
        >
          <MoreVertRounded sx={{ color: theme.palette.text.main }} />
        </IconButton>
      ),
    },
  ]
  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: 'fixed',
            color: '',
            top: '60%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <Loader thickness={6} sx={{ color: 'blue' }} />
        </Box>
      ) : (
        <ReusableTable columns={columns} data={data} />
      )}
      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              borderRadius: '12px',
              background: theme.palette.menu.background,
              paddingY: { xs: '2px', sm: '4px' },
              paddingX: { xs: '8px', sm: '8px' },
              border: `1px solid ${theme.palette.border.extralight}`,
              boxShadow:
                '0px 1px 1px 0px rgba(0, 0, 0, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.02)',
            },
          }}
        >
          <Tooltip
            title={
              <Typography
                sx={{
                  fontFamily: theme.typography.h5.fontFamily,
                  fontSize: '11px',
                }}
              >
                {`${5 - selectedRow?.resendCount} resend remaining`}
              </Typography>
            }
            placement='top'
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              },
            }}
          >
            <MenuItem
              onClick={() => handleResendInvite(selectedRow)}
              disabled={selectedRow?.resendCount === 5}
              sx={{
                fontSize: theme.typography.menuItems.fontSize,
                fontWeight: theme?.typography.menuItems.fontWeight,
                color: theme?.palette.text.main,
                fontFamily: theme?.typography.menuItems.fontFamily,
                '&:hover': {
                  backgroundColor: theme?.palette?.menu.menuitems,
                  borderRadius:
                    'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)',
                },
              }}
            >
              Resend
            </MenuItem>
          </Tooltip>
        </Menu>
      )}
    </>
  )
}

export default InvitedMembersTable

import { Box, Button, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import activeSectionStyle, {
  custom_Button,
  filterBox,
  icon_Group_Style,
  icon_Style,
  text_Style,
} from './activeSectionStyle'
import GroupsIcon from '@mui/icons-material/Groups'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import FacebookPostModal from '../FacebookPostModal/facebookPostModal'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../../../../Context/userContext'
import { CustomButton } from '../../../../ReusableComponents/customButtons'
import { useTheme } from '@emotion/react'
import CustomMoreVertRoundedButton from '../../../../ReusableComponents/customMoreVertRoundedButton'
import CustomMenu from '../../../../ReusableComponents/customMenu'

const FilterOfActiveSection = ({
  setActiveButton,
  activeButton,
  handleModalOpen,
  openModal,
  pagesNames,
  pagesData,
  postedAndScheduledCount,
  currentUser,
}) => {
  const [unArchived, setUnArchived] = useState(null)
  const { setSpecificPages, setButtonStatus } = useContext(UserContext)
  // console.log('pages Names ddddddddd', pagesNames[0])
  const navigate = useNavigate()

  const getName = () => {
    // console.log('name',name);
    setSpecificPages(pagesNames[0])
  }
  const handleUnArchiveClick = (event) => {
    setUnArchived(event.currentTarget)
  }

  const unarchivedClose = () => {
    setUnArchived(null)
  }
  const menuItems = [
    {
      label: 'Unarchive Spaces',
      onClick: () => navigate('/dashboard/archived-spaces'),
    },
  ]
  const theme = useTheme()
  return (
    <Box sx={filterBox(theme)}>
      <Box sx={activeSectionStyle.button_main_Box}>
        <Button
          onClick={() => setActiveButton('pages')}
          sx={custom_Button(activeButton === 'pages', theme)}
        >
          <FileOpenIcon sx={icon_Style(theme)} />
          <Typography sx={text_Style(theme)}>Pages</Typography>
        </Button>
        <Button
          disabled
          onClick={() => setActiveButton('groups')}
          sx={custom_Button(activeButton === 'groups', theme)}
        >
          <GroupsIcon sx={icon_Group_Style(theme)} />
          <Typography
            sx={{ ...text_Style(theme), color: theme?.palette.text.light }}
          >
            Groups
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          background: '',
          // alignItems:"center",
          justifyContent: { xs: 'space-between', sm: '' },
          gap: { xs: '12px' },
        }}
      >
        {(currentUser?.Subscription?.planType === 'basic' &&
          postedAndScheduledCount < currentUser?.Subscription?.posts) ||
        (currentUser?.Subscription?.planType !== 'basic' &&
          currentUser?.Subscription?.posts < 0) ? (
          <CustomButton
            onClick={() => {
              handleModalOpen(true)
              getName()
              setButtonStatus('Publish')
            }}
            buttonText={'Create Post'}
          />
        ) : null}
        <CustomMoreVertRoundedButton onClick={handleUnArchiveClick} />
        {openModal && (
          <FacebookPostModal
            handleDialogOpen={handleModalOpen}
            open={openModal}
            pagesNames={pagesNames}
            pagesData={pagesData}
          />
        )}
      </Box>
      <CustomMenu
        anchorEl={unArchived}
        onClose={unarchivedClose}
        open={unArchived}
        menuItems={menuItems}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  )
}

export default FilterOfActiveSection

import { EmojiEmotionsOutlined } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import EmojiPicker from 'emoji-picker-react'
import React, { useState } from 'react'
import { useTheme } from '@emotion/react'
import { addPickerStyle } from './captionEmojiStyle'

const AddPicker = ({ caption, handleEmojiClick, mediaType, loading }) => {
  const [emojiState, setEmojiState] = useState(false)
  const [char, setChar] = useState(2200)
  const handleChangeEmoji = (event, emojiObject) => {
    // console.log('emoji', event)
    if (handleEmojiClick) {
      handleEmojiClick(event)
    }
    setEmojiState(false)
  }
  const theme = useTheme()

  return (
    <>
      <Box sx={addPickerStyle.mainBox}>
        <IconButton
          disabled={mediaType === 'story' || loading}
          onClick={() => setEmojiState(!emojiState)}
          sx={{
            boxSizing: 'border-box',
            padding: '0px',
            color: theme.palette.text.light,
          }}
        >
          <EmojiEmotionsOutlined />
        </IconButton>
        <Typography sx={addPickerStyle.text_Style(theme)}>
          {caption?.length}/{char}
        </Typography>
      </Box>
      {emojiState && (
        <EmojiPicker
          Reactions={true}
          lazyLoadEmojis={false}
          width={350}
          height={450}
          theme='dark'
          emojiStyle='facebook'
          emojiVersion='0.6'
          style={addPickerStyle.emojipickerStyle}
          onEmojiClick={handleChangeEmoji}
        />
      )}
    </>
  )
}

export default AddPicker

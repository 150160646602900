/* eslint-disable no-unused-expressions */
import React from 'react'
export const EmailStartAdornment = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 22 20'
    fill='none'
    {...props} // Spread the props to allow dynamic styling
  >
    <path
      d='M16.9026 6.85107L12.4593 10.4641C11.6198 11.1301 10.4387 11.1301 9.59922 10.4641L5.11844 6.85107'
      stroke='currentColor' // Inherit color dynamically
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.9089 19C18.9502 19.0084 21 16.5095 21 13.4384V6.57001C21 3.49883 18.9502 1 15.9089 1H6.09114C3.04979 1 1 3.49883 1 6.57001V13.4384C1 16.5095 3.04979 19.0084 6.09114 19H15.9089Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const PasswordEndAdornment = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='17'
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.3344 0.750244H5.66543C2.64443 0.750244 0.750427 2.88924 0.750427 5.91624V14.0842C0.750427 17.1112 2.63543 19.2502 5.66543 19.2502H14.3334C17.3644 19.2502 19.2504 17.1112 19.2504 14.0842V5.91624C19.2504 2.88924 17.3644 0.750244 14.3344 0.750244Z'
      stroke='#3F3F3F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.43994 10.0002L8.81394 12.3732L13.5599 7.6272'
      stroke='#3F3F3F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const KeywordEndAdornment = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='18'
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.3341 0.75H5.66509C2.64409 0.75 0.750092 2.889 0.750092 5.916V14.084C0.750092 17.111 2.63509 19.25 5.66509 19.25H14.3331C17.3641 19.25 19.2501 17.111 19.2501 14.084V5.916C19.2501 2.889 17.3641 0.75 14.3341 0.75Z'
      stroke='#3F3F3F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.68908 9.99995C8.68908 11.0229 7.86008 11.8519 6.83708 11.8519C5.81408 11.8519 4.98508 11.0229 4.98508 9.99995C4.98508 8.97695 5.81408 8.14795 6.83708 8.14795H6.84008C7.86108 8.14895 8.68908 8.97795 8.68908 9.99995Z'
      stroke='#3F3F3F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.69199 10H15.01V11.852'
      stroke='#3F3F3F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.1818 11.852V10'
      stroke='#3F3F3F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const PersonSvg = (style) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 16 20'
    fill='none'
    style={style}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.98478 13.3462C4.11716 13.3462 0.814301 13.931 0.814301 16.2729C0.814301 18.6148 4.0962 19.2205 7.98478 19.2205C11.8524 19.2205 15.1543 18.6348 15.1543 16.2938C15.1543 13.9529 11.8733 13.3462 7.98478 13.3462Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.98477 10.0059C10.5229 10.0059 12.58 7.94779 12.58 5.40969C12.58 2.8716 10.5229 0.814453 7.98477 0.814453C5.44667 0.814453 3.38858 2.8716 3.38858 5.40969C3.38001 7.93922 5.42382 9.99731 7.95239 10.0059H7.98477Z'
      stroke='currentColor'
      strokeWidth='1.42857'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const CheckBox = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.3344 0.750183H5.66543C2.64443 0.750183 0.750427 2.88918 0.750427 5.91618V14.0842C0.750427 17.1112 2.63543 19.2502 5.66543 19.2502H14.3334C17.3644 19.2502 19.2504 17.1112 19.2504 14.0842V5.91618C19.2504 2.88918 17.3644 0.750183 14.3344 0.750183Z'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.43994 10.0002L8.81394 12.3732L13.5599 7.6272'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const PhoneSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 22 22'
    fill='none'
    {...props} // Spread the props to allow dynamic styling
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.5317 11.4724C14.5208 15.4604 15.4258 10.8467 17.9656 13.3848C20.4143 15.8328 21.8216 16.3232 18.7192 19.4247C18.3306 19.737 15.8616 23.4943 7.1846 14.8197C-1.49348 6.144 2.26158 3.67244 2.57397 3.28395C5.68387 0.173846 6.16586 1.58938 8.61449 4.03733C11.1544 6.5765 6.54266 7.48441 10.5317 11.4724Z'
      stroke='currentColor' // Use currentColor to inherit color dynamically
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const CameraSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='36'
    viewBox='0 0 40 36'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.0804 2.10257C28.1004 2.90657 28.7184 5.70657 29.5444 6.60657C30.3704 7.50657 31.5524 7.81257 32.2064 7.81257C35.6824 7.81257 38.5004 10.6306 38.5004 14.1046V25.6946C38.5004 30.3546 34.7204 34.1346 30.0604 34.1346H9.94043C5.27843 34.1346 1.50043 30.3546 1.50043 25.6946V14.1046C1.50043 10.6306 4.31843 7.81257 7.79443 7.81257C8.44643 7.81257 9.62843 7.50657 10.4564 6.60657C11.2824 5.70657 11.8984 2.90657 13.9184 2.10257C15.9404 1.29857 24.0604 1.29857 26.0804 2.10257Z'
      stroke='#494949'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M30.991 13H31.009'
      stroke='#494949'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.3578 20.2559C26.3578 16.7439 23.5118 13.8979 19.9998 13.8979C16.4878 13.8979 13.6418 16.7439 13.6418 20.2559C13.6418 23.7679 16.4878 26.6139 19.9998 26.6139C23.5118 26.6139 26.3578 23.7679 26.3578 20.2559Z'
      stroke='#494949'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export const NotificationSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <path
      d='M20 11.845C20.0627 11.963 20.134 12.0793 20.214 12.194C20.694 12.87 21.339 13.562 21.47 14.394C21.683 15.747 20.732 16.686 19.568 17.154C15.105 18.949 8.89501 18.949 4.43201 17.154C3.26801 16.686 2.31701 15.747 2.53001 14.394C2.66001 13.562 3.30701 12.87 3.78601 12.194C4.41301 11.297 4.47501 10.32 4.47601 9.279C4.47501 5.26 7.84301 2 12 2C12.5147 2.00067 13.0147 2.049 13.5 2.145'
      stroke='#241BFF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.016 3H19.688C20.461 3 20.848 3 20.95 3.24C21.052 3.48 20.79 3.768 20.265 4.344L16.847 7.656C16.321 8.232 15.913 8.52 16.016 8.76C16.119 9 16.65 9 17.423 9H21M9 21C9.796 21.622 10.848 22 12 22C13.152 22 14.204 21.622 15 21'
      stroke='#241BFF'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export const DoubleClickSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <g clipPath='url(#clip0_7295_4093)'>
      <path
        d='M2.222 11.1212C2.39419 10.949 2.62329 10.8456 2.86632 10.8303C3.10934 10.815 3.34959 10.8889 3.542 11.0382L3.636 11.1212L7.878 15.3642C8.05735 15.5441 8.16147 15.7856 8.16923 16.0396C8.17698 16.2935 8.08778 16.5409 7.91975 16.7315C7.75172 16.922 7.51745 17.0415 7.26452 17.0656C7.0116 17.0897 6.75899 17.0166 6.558 16.8612L6.464 16.7782L2.222 12.5362C2.12902 12.4433 2.05526 12.333 2.00494 12.2116C1.95461 12.0902 1.92871 11.9601 1.92871 11.8287C1.92871 11.6973 1.95461 11.5671 2.00494 11.4457C2.05526 11.3243 2.12902 11.214 2.222 11.1212ZM22.778 5.46517C22.9502 5.63737 23.0536 5.86647 23.0689 6.10949C23.0842 6.35252 23.0103 6.59277 22.861 6.78517L22.778 6.87917L12.949 16.7072C12.7585 16.8979 12.5045 17.0121 12.2354 17.028C11.9662 17.0438 11.7006 16.9603 11.489 16.7932L11.393 16.7082L7.222 12.5362C7.04074 12.3566 6.93497 12.1146 6.92631 11.8596C6.91766 11.6046 7.00679 11.356 7.17546 11.1646C7.34413 10.9732 7.57958 10.8535 7.83362 10.83C8.08766 10.8065 8.34108 10.881 8.542 11.0382L8.636 11.1212L12.171 14.6572L21.364 5.46517C21.5515 5.2777 21.8058 5.17239 22.071 5.17239C22.3362 5.17239 22.5905 5.2777 22.778 5.46517ZM17.778 5.46517C17.9502 5.63737 18.0536 5.86647 18.0689 6.10949C18.0842 6.35252 18.0103 6.59277 17.861 6.78517L17.778 6.87917L12.828 11.8292C12.648 12.0085 12.4066 12.1126 12.1526 12.1204C11.8987 12.1282 11.6513 12.039 11.4607 11.8709C11.2702 11.7029 11.1507 11.4686 11.1266 11.2157C11.1025 10.9628 11.1756 10.7102 11.331 10.5092L11.414 10.4142L16.364 5.46417C16.5515 5.2767 16.8058 5.17139 17.071 5.17139C17.3362 5.17139 17.5905 5.2777 17.778 5.46517Z'
        fill='#241BFF'
      />
    </g>
    <defs>
      <clipPath id='clip0_7295_4093'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export const SideBarSwitcherSvg = (state) => (
  <svg
    onClick={() => state()}
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.6001 4.86406C4.94324 4.86406 3.6001 6.2072 3.6001 7.86406V17.1368C3.6001 18.7936 4.94324 20.1368 6.6001 20.1368H17.4001C19.0569 20.1368 20.4001 18.7936 20.4001 17.1368V7.86406C20.4001 6.20721 19.0569 4.86406 17.4001 4.86406H6.6001ZM12.6258 6.3916C11.5212 6.3916 10.6258 7.28703 10.6258 8.3916V16.6098C10.6258 17.7143 11.5212 18.6098 12.6258 18.6098H16.7203C17.8249 18.6098 18.7203 17.7143 18.7203 16.6098V8.3916C18.7203 7.28703 17.8249 6.3916 16.7203 6.3916H12.6258Z'
      fill='#494949'
    />
  </svg>
)

export const SignoutSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='22'
    viewBox='0 0 24 25'
    fill='none'
    {...props}
  >
    <path
      d='M8.8999 8.05999C9.2099 4.45999 11.0599 2.98999 15.1099 2.98999H15.2399C19.7099 2.98999 21.4999 4.77999 21.4999 9.24999V15.77C21.4999 20.24 19.7099 22.03 15.2399 22.03H15.1099C11.0899 22.03 9.2399 20.58 8.9099 17.04'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.0001 12.5H3.62012'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.85 9.15002L2.5 12.5L5.85 15.85'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export const CalenderSvg = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    {...props}
  >
    <path
      d='M1.57715 6.83688H16.4304'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.7017 10.0913H12.7094'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.00397 10.0913H9.01169'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.29816 10.0913H5.30588'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.7017 13.33H12.7094'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.00397 13.33H9.01169'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.29816 13.33H5.30588'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.3699 0.666504V3.40882'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.63771 0.666504V3.40882'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5319 1.98242H5.4758C3.02856 1.98242 1.5 3.3457 1.5 5.85161V13.393C1.5 15.9383 3.02856 17.3331 5.4758 17.3331H12.5242C14.9791 17.3331 16.5 15.9619 16.5 13.456V5.85161C16.5077 3.3457 14.9868 1.98242 12.5319 1.98242Z'
      stroke='currentColor'
      strokeWidth='1.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export const Graphsvg = ({ style }) => (
  <svg
    width='15'
    height='15'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={style}
  >
    <g id='vuesax/linear/diagram'>
      <g id='diagram'>
        <path
          id='Vector'
          d='M1.5 1.5V14.25C1.5 15.495 2.505 16.5 3.75 16.5H16.5'
          stroke='currentColor'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M3.75 12.75L7.1925 8.73001C7.7625 8.07001 8.775 8.025 9.39 8.6475L10.1025 9.36001C10.7175 9.97501 11.73 9.9375 12.3 9.2775L15.75 5.25'
          stroke='currentColor'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
  </svg>
)
export const AiIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='16'
    viewBox='0 0 21 16'
    fill='none'
  >
    <path
      d='M5.97897 8.49967C5.51977 8.32481 5.51977 7.67519 5.97897 7.50033C8.82993 6.41474 11.0815 4.16318 12.1671 1.31223C12.3419 0.85302 12.9916 0.85302 13.1664 1.31223C14.252 4.16318 16.5036 6.41474 19.3545 7.50033C19.8137 7.67519 19.8137 8.32481 19.3545 8.49967C16.5036 9.58526 14.252 11.8368 13.1664 14.6878C12.9916 15.147 12.3419 15.147 12.1671 14.6878C11.0815 11.8368 8.82993 9.58526 5.97897 8.49967Z'
      fill='#241BFF'
    />
    <path
      d='M3.10416 3.49989C2.95109 3.4416 2.95109 3.22506 3.10416 3.16678C4.05447 2.80491 4.805 2.05439 5.16686 1.10408C5.22514 0.951007 5.44169 0.951007 5.49997 1.10407C5.86183 2.05439 6.61235 2.80491 7.56267 3.16678C7.71574 3.22506 7.71574 3.4416 7.56267 3.49989C6.61235 3.86175 5.86183 4.61227 5.49997 5.56259C5.44169 5.71566 5.22514 5.71566 5.16686 5.56259C4.805 4.61227 4.05447 3.86175 3.10416 3.49989Z'
      fill='#241BFF'
    />
    <path
      d='M0.492085 11.8541C0.319883 11.7885 0.319883 11.5449 0.492085 11.4793C1.56119 11.0722 2.40553 10.2279 2.81262 9.15877C2.8782 8.98657 3.1218 8.98657 3.18738 9.15877C3.59447 10.2279 4.43881 11.0722 5.50792 11.4793C5.68012 11.5449 5.68012 11.7885 5.50792 11.8541C4.43881 12.2612 3.59447 13.1055 3.18738 14.1746C3.1218 14.3468 2.8782 14.3468 2.81262 14.1746C2.40553 13.1055 1.56119 12.2612 0.492085 11.8541Z'
      fill='#241BFF'
    />
  </svg>
)

export const AiWhiteIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='16'
    viewBox='0 0 22 16'
    fill='none'
  >
    <path
      d='M6.64523 8.49967C6.18603 8.32481 6.18603 7.67519 6.64523 7.50033C9.49619 6.41474 11.7478 4.16318 12.8333 1.31223C13.0082 0.85302 13.6578 0.85302 13.8327 1.31223C14.9183 4.16318 17.1698 6.41474 20.0208 7.50033C20.48 7.67519 20.48 8.32481 20.0208 8.49967C17.1698 9.58526 14.9183 11.8368 13.8327 14.6878C13.6578 15.147 13.0082 15.147 12.8333 14.6878C11.7478 11.8368 9.49619 9.58526 6.64523 8.49967Z'
      fill='white'
    />
    <path
      d='M3.77042 3.49989C3.61735 3.4416 3.61735 3.22506 3.77042 3.16678C4.72073 2.80491 5.47126 2.05439 5.83312 1.10408C5.8914 0.951007 6.10795 0.951007 6.16623 1.10407C6.52809 2.05439 7.27861 2.80491 8.22893 3.16678C8.382 3.22506 8.382 3.4416 8.22893 3.49989C7.27861 3.86175 6.52809 4.61227 6.16623 5.56259C6.10795 5.71566 5.8914 5.71566 5.83312 5.56259C5.47126 4.61227 4.72073 3.86175 3.77042 3.49989Z'
      fill='white'
    />
    <path
      d='M1.15859 11.8539C0.986387 11.7883 0.986387 11.5447 1.15859 11.4791C2.2277 11.072 3.07203 10.2277 3.47913 9.15859C3.5447 8.98639 3.78831 8.98639 3.85388 9.15859C4.26097 10.2277 5.10531 11.072 6.17442 11.4791C6.34662 11.5447 6.34662 11.7883 6.17442 11.8539C5.10531 12.261 4.26097 13.1053 3.85388 14.1744C3.78831 14.3466 3.5447 14.3466 3.47913 14.1744C3.07203 13.1053 2.2277 12.261 1.15859 11.8539Z'
      fill='white'
    />
  </svg>
)
export const HelpIcon = (style) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='25'
    viewBox='0 0 24 25'
    fill='none'
    style={style}
  >
    <path
      d='M17 18.93H13L8.54999 21.89C7.88999 22.33 7 21.86 7 21.06V18.93C4 18.93 2 16.93 2 13.93V7.92993C2 4.92993 4 2.92993 7 2.92993H17C20 2.92993 22 4.92993 22 7.92993V13.93C22 16.93 20 18.93 17 18.93Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.0001 11.86V11.65C12.0001 10.97 12.4201 10.61 12.8401 10.32C13.2501 10.04 13.66 9.68002 13.66 9.02002C13.66 8.10002 12.9201 7.35999 12.0001 7.35999C11.0801 7.35999 10.3401 8.10002 10.3401 9.02002'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9955 14.25H12.0045'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export const SeatsIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.58012 12.0259H9.61012C12.4301 12.0259 14.7241 9.73187 14.7241 6.91087C14.7241 4.09087 12.4301 1.79688 9.61012 1.79688C6.79112 1.79688 4.49712 4.09088 4.49712 6.90788C4.49112 8.27087 5.01712 9.55287 5.97712 10.5199C6.93612 11.4849 8.21512 12.0209 9.58012 12.0259ZM5.99712 6.91088C5.99712 4.91788 7.61812 3.29688 9.61012 3.29688C11.6031 3.29688 13.2241 4.91788 13.2241 6.91088C13.2241 8.90388 11.6031 10.5259 9.61012 10.5259H9.58312C8.62112 10.5219 7.71812 10.1449 7.04212 9.46187C6.36412 8.77987 5.99312 7.87488 5.99712 6.91088Z'
      stroke='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.05078 18.6743C2.05078 22.2033 7.54278 22.2033 9.60978 22.2033C11.4788 22.2033 17.1688 22.2033 17.1688 18.6543C17.1688 15.9673 13.7078 13.6963 9.60978 13.6963C5.51178 13.6963 2.05078 15.9753 2.05078 18.6743ZM3.55078 18.6743C3.55078 17.0323 6.14178 15.1963 9.60978 15.1963C13.0778 15.1963 15.6688 17.0213 15.6688 18.6543C15.6688 20.3473 12.3738 20.7033 9.60978 20.7033C5.58878 20.7033 3.55078 20.0203 3.55078 18.6743Z'
      stroke='currentColor'
    />
    <path
      d='M16.8532 10.8261C16.5212 10.8261 16.2182 10.6051 16.1282 10.2691C16.0222 9.86909 16.2592 9.45909 16.6602 9.35109C17.7672 9.05509 18.5402 8.04809 18.5402 6.90009C18.5412 5.70009 17.6902 4.65409 16.5162 4.41309C16.1102 4.33009 15.8482 3.93309 15.9322 3.52809C16.0142 3.12209 16.4102 2.85609 16.8172 2.94409C18.6862 3.32809 20.0412 4.99209 20.0412 6.90009C20.0412 8.72509 18.8092 10.3301 17.0462 10.8011C16.9822 10.8181 16.9162 10.8261 16.8532 10.8261Z'
      stroke='currentColor'
    />
    <path
      d='M18.9932 18.0196C19.0742 18.3646 19.3822 18.5976 19.7222 18.5976C19.7792 18.5976 19.8372 18.5916 19.8952 18.5776C21.0842 18.2976 21.9492 17.2986 21.9492 16.2016C21.9492 14.4746 19.8342 12.9016 17.5122 12.9016C17.0982 12.9016 16.7622 13.2376 16.7622 13.6516C16.7622 14.0656 17.0982 14.4016 17.5122 14.4016C19.1332 14.4016 20.4492 15.4916 20.4492 16.2016C20.4492 16.5486 20.1132 16.9856 19.5512 17.1176C19.1482 17.2116 18.8982 17.6166 18.9932 18.0196Z'
      stroke='currentColor'
    />
  </svg>
)
export const CreditsIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    {...props}
  >
    <path
      d='M13 17C17.4183 17 21 13.4183 21 9C21 4.58172 17.4183 1 13 1C8.58172 1 5 4.58172 5 9C5 13.4183 8.58172 17 13 17Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M2.15657 10C1.42523 11.1176 1 12.4535 1 13.8888C1 17.8162 4.18378 21 8.11116 21C9.54646 21 10.8824 20.5748 12 19.8434'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
    />
    <path
      d='M13.7349 5C13.7349 4.58579 13.3991 4.25 12.9849 4.25C12.5707 4.25 12.2349 4.58579 12.2349 5L13.7349 5ZM12.2349 13C12.2349 13.4142 12.5707 13.75 12.9849 13.75C13.3991 13.75 13.7349 13.4142 13.7349 13H12.2349ZM14.069 7.36959C14.1504 7.77573 14.5456 8.03899 14.9517 7.9576C15.3579 7.87621 15.6211 7.48099 15.5397 7.07486L14.069 7.36959ZM11.7327 10.6178C11.6444 10.2131 11.2447 9.95668 10.84 10.045C10.4353 10.1334 10.1789 10.5331 10.2673 10.9378L11.7327 10.6178ZM12.9849 8.13495C12.3788 8.13495 12.1228 8.01911 12.0251 7.94128C11.9661 7.89417 11.8878 7.80275 11.8878 7.50223H10.3878C10.3878 8.09264 10.5613 8.69258 11.09 9.1141C11.5801 9.50489 12.2477 9.63495 12.9849 9.63495V8.13495ZM11.8878 7.50223C11.8878 7.11771 12.2803 6.63906 12.9849 6.63906V5.13906C11.6492 5.13906 10.3878 6.1049 10.3878 7.50223H11.8878ZM14.25 10.4981C14.25 10.8489 14.1282 11.0168 13.9752 11.1255C13.7842 11.2613 13.4549 11.3613 12.9849 11.3613V12.8613C13.6278 12.8613 14.306 12.7308 14.8444 12.3481C15.4207 11.9383 15.75 11.2996 15.75 10.4981H14.25ZM12.9849 9.63495C13.5987 9.63495 13.8988 9.74571 14.0379 9.85312C14.1337 9.92708 14.25 10.0729 14.25 10.4981H15.75C15.75 9.77102 15.5304 9.1103 14.9545 8.66575C14.422 8.25464 13.7146 8.13495 12.9849 8.13495V9.63495ZM13.7349 5.88906L13.7349 5L12.2349 5L12.2349 5.88906L13.7349 5.88906ZM12.2349 12.1113V13H13.7349V12.1113H12.2349ZM12.9849 6.63906C13.6033 6.63906 13.9994 7.0226 14.069 7.36959L15.5397 7.07486C15.3057 5.90692 14.1881 5.13906 12.9849 5.13906V6.63906ZM12.9849 11.3613C12.2343 11.3613 11.8019 10.9344 11.7327 10.6178L10.2673 10.9378C10.529 12.1364 11.748 12.8613 12.9849 12.8613V11.3613Z'
      stroke='currentColor'
    />
  </svg>
)
export const LocationIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='20'
    viewBox='0 0 18 20'
    fill='none'
    {...props} // Spread the props here
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.5 8.50051C11.5 7.11924 10.3808 6 9.00051 6C7.61924 6 6.5 7.11924 6.5 8.50051C6.5 9.88076 7.61924 11 9.00051 11C10.3808 11 11.5 9.88076 11.5 8.50051Z'
      stroke='currentColor' // Use currentColor to inherit color
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.99951 19C7.80104 19 1.5 13.8984 1.5 8.56329C1.5 4.38664 4.8571 1 8.99951 1C13.1419 1 16.5 4.38664 16.5 8.56329C16.5 13.8984 10.198 19 8.99951 19Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const AiSvgIcon = ({ width, height, style }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width || '31'}
    height={height || '24'}
    viewBox='0 0 31 24'
    style={style} // Allow passing additional styles
    fill='none'
  >
    <path
      d='M8.96834 12.7495C8.27953 12.4872 8.27953 11.5128 8.96834 11.2505C13.2448 9.62212 16.6221 6.24477 18.2505 1.96834C18.5128 1.27953 19.4872 1.27953 19.7495 1.96834C21.3779 6.24477 24.7552 9.62212 29.0317 11.2505C29.7205 11.5128 29.7205 12.4872 29.0317 12.7495C24.7552 14.3779 21.3779 17.7552 19.7495 22.0317C19.4872 22.7205 18.5128 22.7205 18.2505 22.0317C16.6221 17.7552 13.2448 14.3779 8.96834 12.7495Z'
      fill='currentColor' // Use currentColor to inherit color
    />
    <path
      d='M4.65611 5.24983C4.42651 5.16241 4.42651 4.83759 4.65611 4.75016C6.08159 4.20737 7.20737 3.08159 7.75017 1.65611C7.83759 1.42651 8.16241 1.42651 8.24984 1.65611C8.79263 3.08159 9.91841 4.20737 11.3439 4.75016C11.5735 4.83759 11.5735 5.16241 11.3439 5.24983C9.91841 5.79263 8.79263 6.91841 8.24984 8.34389C8.16241 8.57349 7.83759 8.57349 7.75017 8.34389C7.20737 6.91841 6.08159 5.79263 4.65611 5.24983Z'
      fill='currentColor'
    />
    <path
      d='M0.738127 17.7811C0.479824 17.6827 0.479824 17.3173 0.738127 17.2189C2.34179 16.6083 3.60829 15.3418 4.21894 13.7381C4.31729 13.4798 4.68271 13.4798 4.78106 13.7381C5.39171 15.3418 6.65821 16.6083 8.26187 17.2189C8.52018 17.3173 8.52018 17.6827 8.26187 17.7811C6.65821 18.3917 5.39171 19.6582 4.78106 21.2619C4.68271 21.5202 4.31729 21.5202 4.21894 21.2619C3.60829 19.6582 2.34179 18.3917 0.738127 17.7811Z'
      fill='currentColor'
    />
  </svg>
)

export const BackIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
  >
    <path
      d='M7.00008 11.0834L2.91675 7.00002L7.00008 2.91669'
      stroke='#1E40AF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.0834 7H2.91675'
      stroke='#1E40AF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export const RetryIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='14'
    viewBox='0 0 12 14'
    fill='none'
  >
    <path
      d='M7.53455 0.213463C7.81186 0.0646635 8.15692 0.169522 8.30527 0.447671L8.64578 1.08611L8.65959 1.112C8.7627 1.30524 8.86147 1.49037 8.92124 1.63902C8.95239 1.71647 8.99102 1.82636 9.00231 1.94893C9.01445 2.08059 8.99857 2.28105 8.85191 2.45915C8.69866 2.64524 8.49557 2.69302 8.3712 2.70387C8.25094 2.71436 8.13599 2.69749 8.05169 2.68138C7.88823 2.65014 7.67701 2.58721 7.45014 2.51963L7.42268 2.51144C6.97361 2.37772 6.49601 2.3055 5.99992 2.3055C3.31047 2.3055 1.15964 4.42333 1.15964 6.99998C1.15964 7.85256 1.39348 8.65118 1.80306 9.34035C1.96409 9.61131 1.87563 9.96191 1.60549 10.1234C1.33535 10.2849 0.985817 10.1962 0.824786 9.92526C0.313787 9.06542 0.020752 8.06561 0.020752 6.99998C0.020752 3.76035 2.71395 1.16315 5.99992 1.16315C6.32096 1.16315 6.63638 1.1879 6.94423 1.23561L7.24955 0.583313C7.31661 0.429287 7.38547 0.293459 7.53455 0.213463Z'
      fill='#494949'
    />
    <path
      d='M10.3943 3.87653C10.6645 3.71502 11.014 3.80374 11.1751 4.0747C11.6861 4.93454 11.9791 5.93435 11.9791 6.99998C11.9791 10.2396 9.28589 12.8368 5.99992 12.8368C5.70293 12.8368 5.41074 12.8156 5.12492 12.7747L4.75029 13.4166C4.68926 13.5523 4.61437 13.7065 4.46529 13.7865C4.18798 13.9353 3.84292 13.8304 3.69457 13.5523L3.34025 12.888C3.23714 12.6947 3.13836 12.5096 3.07859 12.3609C3.04744 12.2835 3.00881 12.1736 2.99751 12.051C2.98537 11.9194 3.00125 11.7189 3.14791 11.5408C3.30117 11.3547 3.50427 11.3069 3.62864 11.2961C3.74889 11.2856 3.86384 11.3025 3.94814 11.3186C4.11162 11.3498 4.32285 11.4128 4.54974 11.4803L4.57716 11.4885C5.02623 11.6222 5.50383 11.6945 5.99992 11.6945C8.68937 11.6945 10.8402 9.57663 10.8402 6.99998C10.8402 6.1474 10.6064 5.34878 10.1968 4.65961C10.0358 4.38865 10.1242 4.03805 10.3943 3.87653Z'
      fill='#494949'
    />
  </svg>
)
export const CustaiIconomSvgIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='20'
    viewBox='0 0 26 20'
    fill='none'
  >
    <path
      d='M7.64032 10.6245C7.0663 10.406 7.0663 9.59392 7.64032 9.37535C11.204 8.01837 14.0185 5.20391 15.3755 1.64021C15.5941 1.0662 16.4061 1.0662 16.6247 1.64021C17.9817 5.20391 20.7962 8.01837 24.3599 9.37535C24.9339 9.59392 24.9339 10.406 24.3599 10.6245C20.7962 11.9815 17.9817 14.796 16.6247 18.3597C16.4061 18.9337 15.5941 18.9337 15.3755 18.3597C14.0185 14.796 11.204 11.9815 7.64032 10.6245Z'
      fill='white'
    />
    <path
      d='M4.04677 4.3748C3.85543 4.30195 3.85543 4.03126 4.04677 3.95841C5.23468 3.50608 6.17284 2.56793 6.62517 1.38003C6.69803 1.18869 6.96871 1.18869 7.04156 1.38003C7.4939 2.56793 8.43206 3.50608 9.61997 3.95841C9.8113 4.03126 9.8113 4.30195 9.61996 4.3748C8.43206 4.82713 7.4939 5.76528 7.04156 6.95318C6.96871 7.14452 6.69803 7.14452 6.62517 6.95318C6.17284 5.76528 5.23468 4.82713 4.04677 4.3748Z'
      fill='white'
    />
    <path
      d='M0.781867 14.8176C0.566612 14.7356 0.566612 14.4311 0.781867 14.3492C2.11826 13.8403 3.17369 12.7849 3.68257 11.4485C3.76453 11.2332 4.06904 11.2332 4.15101 11.4485C4.65988 12.7849 5.71532 13.8403 7.05171 14.3492C7.26696 14.4311 7.26696 14.7356 7.05171 14.8176C5.71532 15.3265 4.65988 16.3819 4.15101 17.7183C4.06904 17.9335 3.76453 17.9335 3.68257 17.7183C3.17369 16.3819 2.11826 15.3265 0.781867 14.8176Z'
      fill='white'
    />
  </svg>
)
export const AiIconSvgIcon = ({ style }) => (
  <svg width='25' height='20' viewBox='0 0 26 20' fill='none' style={style}>
    <path
      d='M7.64032 10.6245C7.0663 10.406 7.0663 9.59392 7.64032 9.37535C11.204 8.01837 14.0185 5.20391 15.3755 1.64021C15.5941 1.0662 16.4061 1.0662 16.6247 1.64021C17.9817 5.20391 20.7962 8.01837 24.3599 9.37535C24.9339 9.59392 24.9339 10.406 24.3599 10.6245C20.7962 11.9815 17.9817 14.796 16.6247 18.3597C16.4061 18.9337 15.5941 18.9337 15.3755 18.3597C14.0185 14.796 11.204 11.9815 7.64032 10.6245Z'
      fill='currentColor'
    />
    <path
      d='M4.04677 4.3748C3.85543 4.30195 3.85543 4.03126 4.04677 3.95841C5.23468 3.50608 6.17284 2.56793 6.62517 1.38003C6.69803 1.18869 6.96871 1.18869 7.04156 1.38003C7.4939 2.56793 8.43206 3.50608 9.61997 3.95841C9.8113 4.03126 9.8113 4.30195 9.61996 4.3748C8.43206 4.82713 7.4939 5.76528 7.04156 6.95318C6.96871 7.14452 6.69803 7.14452 6.62517 6.95318C6.17284 5.76528 5.23468 4.82713 4.04677 4.3748Z'
      fill='currentColor'
    />
    <path
      d='M0.781867 14.8176C0.566612 14.7356 0.566612 14.4311 0.781867 14.3492C2.11826 13.8403 3.17369 12.7849 3.68257 11.4485C3.76453 11.2332 4.06904 11.2332 4.15101 11.4485C4.65988 12.7849 5.71532 13.8403 7.05171 14.3492C7.26696 14.4311 7.26696 14.7356 7.05171 14.8176C5.71532 15.3265 4.65988 16.3819 4.15101 17.7183C4.06904 17.9335 3.76453 17.9335 3.68257 17.7183C3.17369 16.3819 2.11826 15.3265 0.781867 14.8176Z'
      fill='currentColor'
    />
  </svg>
)

export const ChatIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='26'
    height='26'
    viewBox='0 0 26 26'
    fill='none'
  >
    <path
      d='M15.1667 9.74984C15.1667 10.3245 14.9384 10.8756 14.5321 11.2819C14.1258 11.6882 13.5747 11.9165 13 11.9165H6.50002L2.16669 16.2498V4.33317C2.16669 3.1415 3.14169 2.1665 4.33335 2.1665H13C13.5747 2.1665 14.1258 2.39478 14.5321 2.80111C14.9384 3.20743 15.1667 3.75853 15.1667 4.33317V9.74984Z'
      stroke='#1E40AF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19.5 9.75H21.6666C22.2413 9.75 22.7924 9.97827 23.1987 10.3846C23.605 10.7909 23.8333 11.342 23.8333 11.9167V23.8333L19.5 19.5H13C12.4253 19.5 11.8742 19.2717 11.4679 18.8654C11.0616 18.4591 10.8333 17.908 10.8333 17.3333V16.25'
      stroke='#1E40AF'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export const VerifyIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='37'
    height='36'
    viewBox='0 0 37 36'
    fill='none'
  >
    <path
      d='M25.6325 3H11.3675C9.62922 3 8.76006 3 8.05908 3.24391C6.72983 3.70643 5.68622 4.78078 5.23693 6.14919C5 6.87082 5 7.76558 5 9.55509V30.5613C5 31.8486 6.4775 32.5317 7.41214 31.6764C7.96126 31.1739 8.78874 31.1739 9.33786 31.6764L10.0625 32.3395C11.0249 33.2202 12.4751 33.2202 13.4375 32.3395C14.3999 31.4589 15.8501 31.4589 16.8125 32.3395C17.7749 33.2202 19.2251 33.2202 20.1875 32.3395C21.1499 31.4589 22.6001 31.4589 23.5625 32.3395C24.5249 33.2202 25.9751 33.2202 26.9375 32.3395L27.6621 31.6764C28.2113 31.1739 29.0387 31.1739 29.5879 31.6764C30.5225 32.5317 32 31.8486 32 30.5613V9.55509C32 7.76558 32 6.87082 31.7631 6.14919C31.3138 4.78078 30.2702 3.70643 28.9409 3.24391C28.2399 3 27.3708 3 25.6325 3Z'
      stroke='#115E59'
      strokeWidth='2.25'
    />
    <path
      d='M14.75 15.6L16.8929 18L22.25 12'
      stroke='#115E59'
      strokeWidth='2.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.75 23.25H25.25'
      stroke='#115E59'
      strokeWidth='2.25'
      strokeLinecap='round'
    />
  </svg>
)

import { useTheme } from '@emotion/react'
import { Box, TextField } from '@mui/material'
import React from 'react'

const AiPromptField = ({ textPrompt, setTextPrompt }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        background: '',
        borderRadius: '10px',
        border: '1px solid #E5E7EB',
        gap: '10px',
        boxSizing: 'border-box',
        paddingX: '10px',
        mt: '5px',
      }}
    >
      <div
        style={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          background: '',
        }}
      >
        <TextField
          value={textPrompt}
          onChange={(e) => setTextPrompt(e.target.value)}
          variant='standard'
          placeholder='Type your prompt . . .'
          fullWidth
          sx={{
            width: '100%',
            mx: 'auto',
            overflowY: 'auto',
            borderRadius: '10px',
            '& textarea': {
              color: theme.palette.text.main,
              fontFamily: theme.typography.h4.fontFamily,
              fontSize: theme.typography.h5.fontSize,

              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            },
          }}
          InputProps={{
            rows: 4,
            multiline: true,
            inputComponent: 'textarea',
            disableUnderline: true,
          }}
        />
      </div>
    </Box>
  )
}

export default AiPromptField

import React, { useContext, useEffect, useState } from 'react'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import {
  Circle,
  MoreVertRounded,
  WarningAmberRounded,
} from '@mui/icons-material'
import { useTheme } from '@emotion/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import UserContext from '../../../../../Context/userContext'
import {
  formatCount,
  formatYouTubeDuration,
  youtubeDeleteVideoApi,
  youtubeGetAllPostsApi,
} from '../../../../../API/PlatformsAPIs/YoutubeApi'
import { timeAgo } from '../../../../../API/PlatformsAPIs/InstagramApi'
import YoutubePostHeader from './youtubePostHeader'
import YoutubePostSkeleton from './youtubePostSkeleton'
import youtubeStyle from '../youtubeStyle'
import YoutubePostModel from '../YoutubePostModel'
import ViewModelYoutube from './ViewModalYoutube/viewModelYoutube'
import CustomDialog from '../../../../ReusableComponents/customDialoge'
const YoutubePost = () => {
  const { userAuth, setPostData, postData } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [skeleton, setSkeleton] = useState(true)
  const [youtubeModal, setYoutubeModal] = useState(false)
  const navigate = useNavigate()
  const { id, channelId } = useParams()
  const location = useLocation()
  const currentPath = location.pathname
  const basePath = currentPath.split('/').slice(0, 3).join('/')
  console.log('basePath', basePath)
  const [ytData, setYtData] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [viewVideoModel, setViewVideoModel] = useState(null)
  let text = 'Delete'

  const handleConfirmAction = async () => {
    console.log('video delete')
    setLoading(true)
    try {
      const resp = await youtubeDeleteVideoApi(userAuth, id, postData?.videoId)
      console.log('resp', resp)
      if (resp === 'Video deleted successfully') {
        getAllYtVideos(userAuth, id, channelId)
      }
    } catch (error) {
      console.log('error', error)
    }
    setLoading(false)
    handleCloseDialog()
  }
  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleAnchorOpen = (event, val) => {
    setAnchorEl(event.currentTarget)
    setPostData(val)
  }
  const handleAnchorClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const viewModelOpen = () => {
    setViewVideoModel(true)
  }
  const viewModelClose = () => {
    setViewVideoModel(false)
  }
  const items = ['Edit', 'View Details', 'Delete', 'Cancel']
  const getAllYtVideos = async (userAuth, spaceId, channelId) => {
    try {
      setSkeleton(true)
      const resp = await youtubeGetAllPostsApi(userAuth, spaceId, channelId)
      console.log('resp', resp)
      setYtData(resp)
    } catch (error) {
      console.log('error', error)
    }
    setSkeleton(false)
  }

  useEffect(() => {
    const spaceId = window.sessionStorage.getItem('spaceId')
    if (id !== spaceId) {
      navigate(`${basePath}/${spaceId}`)
    }
    const fetch = async () => {
      if (userAuth) {
        getAllYtVideos(userAuth, spaceId, channelId)
      }
    }
    fetch()
  }, [])
  const handleClose = () => {
    setYoutubeModal(false)
  }
  const clickAble = (item) => {
    if (item === 'View Details') {
      viewModelOpen()
    }
    if (item === 'Delete') {
      setIsDialogOpen(true)
    }
    if (item === 'Edit') {
      if (postData?.videoUrl) {
        setYoutubeModal(true)
      }
    }

    handleAnchorClose(false)
  }
  const theme = useTheme()
  return (
    <>
      <YoutubePostHeader setYoutubeModal={setYoutubeModal} />
      {skeleton && <YoutubePostSkeleton />}
      <Box sx={youtubeStyle.ytPostCardParentBox(theme)}>
        {ytData?.map((val, ind) => (
          <Box key={ind} sx={youtubeStyle.ytPostCard(theme, val)}>
            <Box sx={youtubeStyle.ytPostCardContentBox()}>
              <Box sx={youtubeStyle.ytPostCardContentTitleAndIconBtnBox(theme)}>
                <Typography
                  sx={youtubeStyle.ytPostCardContentTitleTypography(theme)}
                >
                  {val.title}
                </Typography>
                <IconButton
                  onClick={(e) => handleAnchorOpen(e, val)}
                  sx={{ color: '#FFF', fontSize: '24px' }}
                >
                  <MoreVertRounded />
                </IconButton>
              </Box>
              <Box
                sx={youtubeStyle.ytPostCardContentEngagementsParentBox(theme)}
              >
                <Box
                  sx={youtubeStyle.ytPostCardContentEngagementsFirstChildBox(
                    theme
                  )}
                >
                  <Typography
                    sx={youtubeStyle.ytPostCardContentEngagementsFirstChildPostTime(
                      theme
                    )}
                  >
                    {timeAgo(val.publishedAt)}
                  </Typography>
                  <Circle
                    sx={youtubeStyle.ytPostCardContentEngagementsCircle(theme)}
                  />
                  <Typography
                    sx={youtubeStyle.ytPostCardContentEngagementsFirstChildViews(
                      theme
                    )}
                  >
                    views {formatCount(val.viewCount)}
                  </Typography>
                  <Circle
                    sx={youtubeStyle.ytPostCardContentEngagementsCircle(theme)}
                  />
                  <Typography
                    sx={youtubeStyle.ytPostCardContentEngagementsFirstChildLikes(
                      theme
                    )}
                  >
                    likes {formatCount(val.likeCount)}
                  </Typography>
                  <Circle
                    sx={youtubeStyle.ytPostCardContentEngagementsCircle(theme)}
                  />
                  <Typography
                    sx={youtubeStyle.ytPostCardContentEngagementsFirstChildComments(
                      theme
                    )}
                  >
                    comments {formatCount(val.commentCount)}
                  </Typography>
                </Box>
                <Box
                  sx={youtubeStyle.ytPostCardContentEngagementsSecondChild(
                    theme
                  )}
                >
                  <Typography
                    sx={youtubeStyle.ytPostCardContentEngagementsSecondChildVideoTime(
                      theme
                    )}
                  >
                    {formatYouTubeDuration(val.duration)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <CustomDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmAction}
        dialogTitle='Delete Video'
        dialogDescription='Are you sure you want to delete this Video?'
        confirmButtonText='Delete'
        loading={loading}
        icon={
          <WarningAmberRounded
            sx={{ color: 'red', height: '30px', width: '30px' }}
          />
        }
      />
      {youtubeModal && (
        <YoutubePostModel
          youtubeModal={youtubeModal}
          setYoutubeModal={setYoutubeModal}
          handleClose={handleClose}
        />
      )}
      {viewVideoModel && (
        <ViewModelYoutube
          open={viewVideoModel}
          onCancel={viewModelClose}
          setViewVideoModel={setViewVideoModel}
        />
      )}
      {/* {editStatus && <EditPostModalYoutube handleClose={handleClose} />} */}
      {
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleAnchorClose}
          PaperProps={{
            sx: {
              boxSizing: 'border-box',
              padding:
                'var(--Overlays-Dropdowns-List-Spacing-Y, 0px) var(--Overlays-Dropdowns-List-Spacing-X, 8px)',
              display: 'inline-flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderRadius: '16px',
              background: theme.palette.background.light,
              boxShadow: '0px 6px 10px 0px rgba(0,0,0,0.03)',
            },
          }}
        >
          {items.map((item, index) => (
            <MenuItem
              key={index}
              onClick={() => clickAble(item)}
              sx={{
                boxSizing: 'border-box',
                lineHeight: '20px',
                letterSpacing: '0.07px',
                fontSize: theme.typography?.menuItems?.fontSize || '',
                fontWeight: theme.typography?.menuItems?.fontWeight || 'normal',
                fontFamily: theme.typography?.menuItems?.fontFamily,
                color: item === text ? 'red' : theme.palette?.text?.main,
                '&:hover': {
                  backgroundColor:
                    theme.palette?.background?.table || '#f5f5f5',
                  borderRadius:
                    'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)',
                },
              }}
            >
              {item}
            </MenuItem>
          ))}{' '}
        </Menu>
      }
    </>
  )
}

export default YoutubePost

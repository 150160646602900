import { Box, Typography } from '@mui/material'
import { Modal } from 'antd'
import React, { useContext } from 'react'
import UserContext from '../../../../../Context/userContext'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { postViewModalStyle } from './activeSectionStyle'
import { useTheme } from '@emotion/react'
import { CloseOutlined } from '@mui/icons-material'
const PostViewModal = ({ open, handlePostViewModal }) => {
  const { setPostData, postData } = useContext(UserContext)
  // console.log('postDatasssssssss', postData)
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const options = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }
    return date.toLocaleDateString('en-US', options)
  }
  const postViewModelClose = () => {
    setPostData('')
    handlePostViewModal(false)
  }
  const theme = useTheme()
  return (
    <Modal
      open={open}
      onCancel={postViewModelClose}
      footer
      centered
      width={800}
      maskClosable={false}
      closeIcon={
        <CloseOutlined
          style={{
            color: theme.palette.text.main,
            fontSize: '18px',
          }}
        />
      }
      style={{
        background: theme.palette.background.light,
        borderRadius: '12px',
        border: `1px solid ${theme.palette.border.extralight}`,
      }}
      title=<Box sx={postViewModalStyle.modal_Style(theme)}>
        <img
          src={postData?.profilePic}
          alt='profilePic'
          height={40}
          width={40}
          style={{ borderRadius: '50%' }}
        />
        <Typography sx={postViewModalStyle.date_Text(theme)}>
          <span style={postViewModalStyle.header_Text(theme)}>AILatics</span>
          posted this on
          <span style={postViewModalStyle.header_Text(theme)}>
            {postData?.pageName}
          </span>
          at <span>{formatDate(postData?.createdAt)}</span>
        </Typography>
      </Box>
    >
      <Box sx={postViewModalStyle.main_Box}>
        <Box sx={postViewModalStyle.image_Box(theme)}>
          <Typography sx={postViewModalStyle.desc_TextStyle(theme)}>
            {postData?.message + ' ' + postData?.link}
          </Typography>
          <Swiper
            spaceBetween={20}
            navigation={true}
            modules={[Pagination, Navigation]}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              },
            }}
            className='mySwiper'
            style={postViewModalStyle.swiper_Style}
          >
            {postData?.imageUrls?.map((item, index) => (
              <SwiperSlide
                key={index}
                style={postViewModalStyle.swiperSlide_Style}
              >
                <Box
                  sx={{
                    ...postViewModalStyle.backgroundImage_Box,
                    backgroundImage: `url(${item})`,
                  }}
                ></Box>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* </Box> */}
        </Box>
        <Box sx={postViewModalStyle.footer_Box}>
          <Box sx={postViewModalStyle.customBox}>
            <Typography sx={postViewModalStyle.reactionCount_Style(theme)}>
              {postData?.engagements?.reactionsCount}
            </Typography>
            <Typography sx={postViewModalStyle.text_Style(theme)}>
              Likes
            </Typography>
          </Box>
          <Box sx={postViewModalStyle.customBox}>
            <Typography sx={postViewModalStyle.reactionCount_Style(theme)}>
              {postData?.engagements?.commentsCount}
            </Typography>
            <Typography sx={postViewModalStyle.text_Style(theme)}>
              Comments
            </Typography>
          </Box>
          <Box sx={postViewModalStyle.customBox}>
            <Typography sx={postViewModalStyle.reactionCount_Style(theme)}>
              {postData?.engagements?.sharesCount}
            </Typography>
            <Typography sx={postViewModalStyle.text_Style(theme)}>
              Shares
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default PostViewModal

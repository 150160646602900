import React from 'react'
import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography,
} from '@mui/material'
import { useTheme } from '@emotion/react'

const CustomAccordion = ({
  title,
  data,
  selectedPermissions = [],
  handlePermissionChange,
  icon,
  showCheckboxes,
  totalLength,
  selectedLength,
  type, // Add type prop to differentiate between workspace and platform
  children,
}) => {
  const isAllSelected = selectedPermissions.includes(`all-${type}`)
  const theme = useTheme()
  console.log('data', data)
  return (
    <Accordion
      sx={{
        border: `2px solid ${theme.palette.border.extralight}`,
        backgroundColor: theme.palette.background.light,
        boxShadow: 'none',
        borderRadius: '8px',
        '&:before': { display: 'none' },
        '&.MuiAccordion-root': { borderRadius: '16px' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore sx={{ color: theme?.palette.text.main }} />}
        sx={{
          display: 'flex',
          width: '100%',
          '.MuiAccordionSummary-content': { gap: '12px' },
          color: theme?.palette.text.main,
        }}
      >
        {icon}
        <Typography
          sx={{
            fontSize: theme?.typography.h4.fontSize,
            color: theme?.palette.text.main,
            fontFamily: theme?.typography.h4.fontFamily,
            fontWeight: theme?.typography.h4.fontWeight,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ ml: 'auto', mr: '20px', color: theme?.palette.text.main }}
        >
          {selectedLength} / {totalLength}
        </Typography>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          boxSizing: 'border-box',
          padding: ' var(--Numbers-Number-8, 16px)',
        }}
      >
        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: showCheckboxes ? '2px' : '10px 12px',
            boxSizing: 'border-box',
            gap: '6px',
            width: 'auto',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.gray.light}`,
            background: theme.palette.background.default,
          }}
        >
          {showCheckboxes && <Checkbox size='small' checked={true} disabled />}
          <label
            style={{
              fontSize: theme?.typography.h4.fontSize,
              color: theme?.palette.black.main,
              fontFamily: theme?.typography.h4.fontFamily,
              fontWeight: theme?.typography.h4.fontWeight,
              fontStyle: 'normal',
              lineWeight: '24px',
              letterSpacing: '0.08px',
            }}
          >
            Read
          </label>
        </Box> */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: showCheckboxes ? '2px' : '10px 12px',
            boxSizing: 'border-box',
            gap: '6px',
            width: 'auto',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.gray.light}`,
            background: theme.palette.background.table,
          }}
        >
          {showCheckboxes && (
            <Checkbox
              size='small'
              checked={isAllSelected}
              sx={{
                color: theme?.palette.text.main,
              }}
              onChange={() => handlePermissionChange('all', type)}
            />
          )}
          <label
            style={{
              fontSize: theme?.typography.h4.fontSize,
              color: theme?.palette.text.main,
              fontFamily: theme?.typography.h4.fontFamily,
              fontWeight: theme?.typography.h4.fontWeight,
              fontStyle: 'normal',
              lineWeight: '24px',
              letterSpacing: '0.08px',
            }}
          >
            Select all
          </label>
        </Box>
        {data?.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: showCheckboxes ? '2px' : '10px 12px',
              boxSizing: 'border-box',
              gap: '6px',
              width: 'auto',
              borderRadius: '8px',
              border: `1px solid ${theme.palette.gray.light}`,
              background: theme.palette.background.table,
            }}
          >
            {showCheckboxes && (
              <Checkbox
                size='small'
                sx={{ color: theme?.palette.text.main }}
                checked={selectedPermissions.includes(item)}
                onChange={() => handlePermissionChange(item, type)}
              />
            )}
            <label
              style={{
                fontSize: theme?.typography.h4.fontSize,
                color: theme?.palette.text.light,
                fontFamily: theme?.typography.h4.fontFamily,
                fontWeight: theme?.typography.h4.fontWeight,
                fontStyle: 'normal',
                lineWeight: '24px',
                letterSpacing: '0.08px',
              }}
            >
              {item}
            </label>
          </Box>
        ))}
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default CustomAccordion

import { Box, Button, Menu, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Check } from '@mui/icons-material'
import {
  all_workspaces_Text,
  apply_Cancel_Btn,
  custom_Typography_Text,
  dropDown_Box,
  filterofSapceStyle,
  menu_Tag_Style,
  selectText_BackgroundColor,
} from './dashboardMainSectionStyle'
import { CustomButton } from '../../ReusableComponents/customButtons'

import CustomMoreVertRoundedButton from '../../ReusableComponents/customMoreVertRoundedButton'
import { useTheme } from '@emotion/react'
import CustomMenu from '../../ReusableComponents/customMenu'
const FilterOfSpaces = ({
  filterButton,
  createWorkspaceButton,
  currentUser,
  currentFilteredSpaces,
}) => {
  const navigate = useNavigate()
  const [dateFilter, setDateFilter] = useState(false)
  const [alphabeticallyFilter, setAlphabeticallyFilter] = useState(false)
  const [platformFilter, setPlatformFilter] = useState(false)
  const [colorFilter, setColorFilter] = useState(false)
  const [favoriteFilter, setFavoriteFilter] = useState(false)
  const [unArchived, setUnArchived] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  // console.log('pppppp', currentFilteredSpaces)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUnArchiveClick = (event) => {
    setUnArchived(event.currentTarget)
  }

  const unarchivedClose = () => {
    setUnArchived(null)
  }

  const filter = () => {
    filterButton(
      dateFilter,
      alphabeticallyFilter,
      platformFilter,
      colorFilter,
      favoriteFilter
    )
    console.log(
      dateFilter,
      alphabeticallyFilter,
      colorFilter,
      favoriteFilter,
      platformFilter
    )
    handleClose()
  }
  const handleDateFilter = () => {
    setDateFilter(!dateFilter)
  }
  const handleAlphabeticallyFilter = () => {
    setAlphabeticallyFilter(!alphabeticallyFilter)
  }
  const handlePlatformFilter = () => {
    setPlatformFilter(!platformFilter)
  }
  const handleColorFilter = () => {
    setColorFilter(!colorFilter)
  }
  const handleFavoriteFilter = () => {
    setFavoriteFilter(!favoriteFilter)
  }
  useEffect(() => {}, [
    platformFilter,
    colorFilter,
    alphabeticallyFilter,
    dateFilter,
    favoriteFilter,
  ])
  const menuItems = [
    {
      label: 'Unarchive Spaces',
      onClick: () => navigate('/dashboard/archived-spaces'),
    },
  ]
  const theme = useTheme()
  return (
    <>
      <Box sx={filterofSapceStyle.container_Parent}>
        <Typography sx={all_workspaces_Text(theme)}>All Workspaces</Typography>
        <Box sx={filterofSapceStyle.btn_Box}>
          {currentUser?.Subscription?.workspaceLimit >= 1 &&
          currentUser?.accountType === 'user' ? null : (
            <CustomButton
              onClick={() =>
                createWorkspaceButton(
                  currentUser?.company,
                  currentUser?.accountType
                )
              }
            />
          )}

          <CustomMoreVertRoundedButton onClick={handleUnArchiveClick} />
        </Box>
      </Box>
      <CustomMenu
        anchorEl={unArchived}
        onClose={unarchivedClose}
        open={unArchived}
        menuItems={menuItems}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
        PaperProps={{
          sx: menu_Tag_Style(theme),
        }}
      >
        <Box sx={filterofSapceStyle.all_Customtext_mainBox}>
          <Box onClick={handleAlphabeticallyFilter} sx={dropDown_Box(theme)}>
            <Typography sx={custom_Typography_Text(theme)}>
              Sort Alphabetically
            </Typography>
            {alphabeticallyFilter ? (
              <Check sx={selectText_BackgroundColor(theme)} />
            ) : null}
          </Box>
          <Box onClick={handleDateFilter} sx={dropDown_Box(theme)}>
            <Typography sx={custom_Typography_Text(theme)}>
              Sort by Date
            </Typography>
            {dateFilter ? (
              <Check sx={selectText_BackgroundColor(theme)} />
            ) : null}
          </Box>
          {/* <Box
            onClick={handlePlatformFilter}
            sx={filterofSapceStyle.dropDown_Box}
          >
            <Typography sx={filterofSapceStyle.custom_Typography_Text}>
              Sort by Platform
            </Typography>
            {platformFilter ? (
              <Check sx={filterofSapceStyle.selectText_BackgroundColor} />
            ) : null}
          </Box>
          <Box onClick={handleColorFilter} sx={filterofSapceStyle.dropDown_Box}>
            <Typography sx={filterofSapceStyle.custom_Typography_Text}>
              Sort by Color
            </Typography>
            {colorFilter ? (
              <Check sx={filterofSapceStyle.selectText_BackgroundColor} />
            ) : null}
          </Box> */}
          <Box onClick={handleFavoriteFilter} sx={dropDown_Box(theme)}>
            <Typography sx={custom_Typography_Text(theme)}>
              Sort by Favorite
            </Typography>
            {favoriteFilter ? (
              <Check sx={selectText_BackgroundColor(theme)} />
            ) : null}
          </Box>
          <Box sx={filterofSapceStyle.button_Box}>
            <Button onClick={handleClose} sx={apply_Cancel_Btn(theme)}>
              Cancel
            </Button>
            <Button onClick={filter} sx={apply_Cancel_Btn(theme)}>
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  )
}
export default FilterOfSpaces

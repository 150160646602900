export const instagramStyle = {
  parentContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    background: theme.palette.background.default,
    paddingX: '20px ',
    height: '100%',
    overflow: 'hidden',
  }),
  main_Box: {
    background: '',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  card_Box: (theme) => ({
    boxSizing: 'border-box',
    display: 'flex',
    padding: '16px',
    gap: '16px',
    cursor: 'pointer',
    backgroundColor: theme?.palette?.gray.main,
    borderRadius: '16px',
    border: `1px solid ${theme.palette.border.extralight}`,
    boxShadow:
      '0px 0px 1px 0px rgba(82, 82, 137, 0.08), 0px 2px 2px 0px rgba(82, 82, 137, 0.07), 0px 4px 2px 0px rgba(82, 82, 137, 0.04), 0px 7px 3px 0px rgba(82, 82, 137, 0.01), 0px 11px 3px 0px rgba(82, 82, 137, 0.00)',
  }),
  img_Style: (theme) => ({
    borderRadius: '50%',
    border: `1px solid ${theme.palette.sky.light}`,
    objectFit: 'cover',
  }),
  circularProgressBox: {
    position: 'fixed',
    color: 'red',
    top: '55%',
    left: '57%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },
  postCardsParent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '16px',
    mt: '8px',
    overflowY: 'scroll',
    background: '',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  noDataImage: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '100px',
    height: '400px',
    width: '400px',
  },
  accountDetails_Box: {
    display: 'flex',
    flexDirection: 'column',
    background: '',
    justifyContent: 'space-between',
  },
  account_TextStyle: (theme) => ({
    fontSize: theme.typography.h5.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
    color: theme.palette.text.main,
  }),
}

export const instagramContentBox = (IgFound) => ({
  background: '',
  display: 'flex',
  flexDirection: 'column',
  overflowY: IgFound ? 'scroll' : 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  width: '100%',
  // height: '100%',
  gap: '8px',
})

export const instgramHeaderTypography = (theme) => ({
  fontSize: {
    xs: theme.typography.h3.fontSize,
    sm: theme.typography.mediumText.fontSize,
  },
  fontWeight: {
    xs: theme.typography.h2.fontWeight,
    sm: theme?.typography.menualText.fontWeight,
  },
  fontFamily: theme?.typography.menualText.fontFamily,
  color: theme?.palette.black.main,
  lineHeight: '',
  background: '',
})

export const headerMain = (theme) => ({
  color: ' var(--Text-Text, #0C0C0C)',
  fontFamily: theme.typography.h1.fontFamily,
  fontSize: theme.typography.large.fontSize,
  fontWeight: theme.typography.h1.fontWeight,
  fontStyle: 'normal',
  lineHght: 'normal',
  letterSpacing: '-1.2px',
})
export const headerstyle = {
  instagramHeader: {
    display: { xs: 'flex' },
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '',
    boxSizing: 'border-box',
    width: { xs: '100%' },
    paddingY: { xs: '8px', sm: '10px' },
  },
  main: (theme) => ({
    fontStyle: 'normal',
    lineHght: 'normal',
    letterSpacing: '-1.2px',
    fontSize: theme.typography.large.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h1.fontWeight,
    color: theme.palette.text.main,
  }),
  headerButtons: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
  },
  searchBar_Field_Style: (theme) => ({
    boxSizing: 'border-box',
    paddingRight: 0,
    background: theme.palette.background.searchField,
    padding: '0 0px',
    marginRight: '-1px',
  }),

  searchbar: (theme) => ({
    flex: 1,
    border: `1px solid ${theme.palette.border.extralight}`,
    height: '36px',
    borderRadius: '8px',
    backgroundColor: theme.palette.background.searchField,
    color: theme.palette.text.main,

    '& .MuiOutlinedInput-root': {
      boxSizing: 'border-box',
      padding: '0px',
      margin: '0px',
      height: '36px',
      borderRadius: '8px',
      background: theme.palette.background.searchField,
      color: theme.palette.text.main,
      '& fieldset': {
        border: 'none',
      },
      '& input::placeholder': {
        color: theme.palette.text.main,
        fontFamily: 'Sora',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: theme?.typography.h4.fontWeight,
        lineHeight: 'normal',
        letterSpacing: '0.075px',
      },
      '& .MuiInputBase-root': {
        padding: '0px',
        margin: '0px',
      },
      '& input': {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
  }),
}

export const searchButton = (theme) => ({
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  boxSizing: 'border-box',
  padding: '0px 12px',
  height: '36px',
  background: theme.palette.button.dark,
  borderRadius:
    'var(--Basic-Forms-Input-Border-Radius, 0px) var(--Numbers-Number, 8px) var(--Number, 8px) var(--Basic-Forms-Input-Border-Radius, 0px)',
  // position: 'relative',
  boxShadow: 'none',
  fontSize: {
    xs: theme.typography.paragraph.fontSize,
    sm: theme.typography.h5.fontSize,
  },
  fontWeight: theme?.typography.h2.fontWeight,
  fontFamily: theme?.typography.h4.fontFamily,
  textTransform: 'none',
})

export const instagramPost = {
  main: {
    height: '330px',
    width: '320px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    gap: '8px',
    borderRadius: '16px',
    padding: '4px',
    boxSizing: 'border-box',
    boxShadow:
      ' 0px 6px 12px -6px rgba(0, 0, 0, 0.12), 0px 8px 24px -4px rgba(0, 0, 0, 0.08)',
  },
  cardDetail: {
    display: 'flex',
    padding: '8px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '12px',
    border: '1px solid rgba(255, 255, 255, 0.50)',
    background: 'rgba(0, 0, 0, 0.12)',

    backdropFilter: 'blur(16px)',
  },
  detailBox: {
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    background: '',
  },

  postCaption: (theme) => ({
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    color: theme.palette.customWhite.main,
    lineHeight: '20px',
    fontFamily: theme.typography.h2.fontFamily,
    fontStyle: 'normal',
    background: '',
    width: '100%',
    height: 'fit-content',
    overflow: 'hidden', // Hide overflowed text
    whiteSpace: 'nowrap', // Prevent wrapping of text
    textOverflow: 'ellipsis', // Show ellipsis when text overflows
  }),
  postDetail: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#FFF',
  },

  postTypo: (theme) => ({
    fontSize: theme.typography.paragraph.fontSize,
    color: theme.palette.customWhite.main,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
    lineHeight: '18px',
  }),
  menu_Style: (theme) => ({
    boxSizing: 'border-box',
    padding:
      'var(--Overlays-Dropdowns-List-Spacing-Y, 0px) var(--Overlays-Dropdowns-List-Spacing-X, 8px)',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '16px',
    background: theme.palette.background.light,
    border: `1px solid ${theme.palette.border.extralight}`,
    boxShadow: '0px 6px 10px 0px rgba(0,0,0,0.03)',
    // boxShadow: '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)',
  }),
  menuitem_Style: (theme, text, item) => ({
    boxSizing: 'border-box',
    lineHeight: '20px',
    letterSpacing: '0.07px',
    fontSize: theme.typography?.menuItems?.fontSize || '',
    fontWeight: theme.typography?.menuItems?.fontWeight || 'normal',
    fontFamily: theme.typography?.menuItems?.fontFamily,
    color: item === text ? 'red' : theme.palette?.text?.main,
    '&:hover': {
      backgroundColor: theme.palette?.background?.table || '#f5f5f5',
      borderRadius: 'var(--Overlays-Dropdowns-List-Item-Border-Radius, 8px)',
    },
  }),
}

export const postModel = {
  uploadBox: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    background: '',
  },
}
export const postModelButton1 = (theme) => ({
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: '9px var(--Components-Button-Spacing-X-SM, 12px)',
  fontSize: theme?.typography.menuItems.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: theme.palette.button.dark,
  fontStyle: 'normal',
  lineHeight: '20px',
  width: 'fit-content',
  textTransform: 'capitalize',
  height: '38px',
  color: 'white',
  ':hover': {
    background: theme.palette.button.dark,
  },
})

export const nextButton = (theme) => ({
  borderRadius: '8px',
  boxSizing: 'border-box',
  padding: '9px var(--Components-Button-Spacing-X-SM, 12px)',
  fontSize: theme?.typography.menuItems.fontSize,
  fontWeight: theme?.typography.h3.fontWeight,
  fontFamily: theme?.typography.h2.fontFamily,
  background: theme.palette.button.dark,
  fontStyle: 'normal',
  lineHeight: '20px',
  width: 'fit-content',
  textTransform: 'capitalize',
  color: 'white',
  ':hover': {
    background: theme.palette.button.dark,
  },
})

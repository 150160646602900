import React, { useState, useEffect, useRef } from 'react'
import { Box, Skeleton, Typography } from '@mui/material'

import backgroundImg from '../../../../Assets/Images/scard.png'
import states from '../../../../Assets/Images/states.png'
import like from '../../../../Assets/Images/like1.png'
import share from '../../../../Assets/Images/share1.png'
import message from '../../../../Assets/Images/message.png'
import { homePage, trendingPostStyle } from '../homePageStyle'
import { useTheme } from '@emotion/react'
import PostMenu from './postMenu'
import PostSkeleton from './postSkeleton'
import PostSocialIcons from './postSocialIcons'
import { Graphsvg } from '../../../PasswordStartAdornment'

const postData = [
  {
    id: 0,
    message: 'Ever felt frustrated with a website or app?',
    followers: '1.8M',
    trendInfo: 'people follow this trend.',
    postImg: backgroundImg,
    socialIcons: [
      { alt: 'Instagram' },
      { alt: 'Facebook' },
      { alt: 'TikTok' },
      { alt: 'YouTube' },
      { alt: 'Google' },
    ],
    followerList: [
      { name: '547k Impressions', icon: states },
      { name: '102k likes', icon: like },
      { name: '32k Comments', icon: message },
      { name: '2k Shares', icon: share },
    ],
  },
  {
    id: 1,
    message: 'Ever felt frustrated with a website or app?',
    followers: '1M',
    trendInfo: 'people follow this trend.',
    postImg: backgroundImg,
    socialIcons: [
      { alt: 'Instagram' },
      { alt: 'Facebook' },
      { alt: 'TikTok' },
      { alt: 'YouTube' },
    ],
    followerList: [
      { name: '547k Impressions', icon: states },
      { name: '102k likes', icon: like },
      { name: '32k Comments', icon: message },
      { name: '2k Shares', icon: share },
    ],
  },
  {
    id: 2,
    message: 'Ever felt frustrated with a website or app?',
    followers: '540k',
    trendInfo: 'people follow this trend.',
    postImg: backgroundImg,
    socialIcons: [{ alt: 'Instagram' }, { alt: 'TikTok' }, { alt: 'Facebook' }],
    followerList: [
      { name: '547k Impressions', icon: states },
      { name: '102k likes', icon: like },
      { name: '32k Comments', icon: message },
      { name: '2k Shares', icon: share },
    ],
  },
  {
    id: 3,
    message: 'Ever felt frustrated with a website or app?',
    followers: '368k',
    trendInfo: 'people follow this trend.',
    postImg: backgroundImg,
    socialIcons: [{ alt: 'Instagram' }, { alt: 'TikTok' }],
    followerList: [
      { name: '547k Impressions', icon: states },
      { name: '102k likes', icon: like },
      { name: '32k Comments', icon: message },
      { name: '2k Shares', icon: share },
    ],
  },
]

const PostList = ({ value }) => {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [activePostId, setActivePostId] = useState(null)
  const [renderedValues, setRenderedValues] = useState([])
  const bottomRef = useRef(null)

  useEffect(() => {
    if (value.text) {
      setIsLoading(true)

      const timer = setTimeout(() => {
        setRenderedValues((prev) => [...prev, value.text])
        setIsLoading(false)
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [value])

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [renderedValues])

  const handleMenuOpen = (event, postId) => {
    setAnchorEl(event.currentTarget)
    setActivePostId(postId)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setActivePostId(null)
  }

  const cleanedText = (inputText) => {
    if (typeof inputText !== 'string') {
      return ''
    }
    return inputText
      .split('\n')
      .filter((line) => line.trim() !== '')
      .join('\n')
  }

  return (
    <Box sx={homePage.main}>
      {renderedValues.map((renderedValue, idx) => (
        <Box key={idx} sx={{ marginBottom: '24px' }}>
          <Typography sx={homePage.searchTextTypo}>
            {cleanedText(renderedValue)}
          </Typography>
          <Box sx={trendingPostStyle.main}>
            {postData.map((post) => (
              <Box
                key={post.id}
                sx={{
                  ...trendingPostStyle.post,
                  background: `linear-gradient(180deg, #000 -14.42%, rgba(0, 0, 0, 0.00) 40.95%, #000 100.86%), url(${post.postImg}) lightgray 50% / cover no-repeat`,
                }}
              >
                <Box sx={trendingPostStyle.postContent}>
                  <PostSocialIcons socialIcons={post.socialIcons} />

                  <Box
                    key={post.id}
                    sx={trendingPostStyle.followerData(theme)}
                    onMouseEnter={(event) => handleMenuOpen(event, post.id)}
                  >
                    <Graphsvg sx={{ color: theme.palette.sky.light }} />
                    {post.followers}
                  </Box>
                </Box>

                <Box sx={{ width: '100%' }}>
                  <Typography sx={trendingPostStyle.detail(theme)}>
                    {post.followers} {post.trendInfo}
                  </Typography>
                  <Typography sx={trendingPostStyle.description(theme)}>
                    {post.message}
                  </Typography>
                </Box>

                <PostMenu
                  anchorEl={anchorEl}
                  activePostId={activePostId}
                  post={post}
                  handleMenuClose={handleMenuClose}
                />
              </Box>
            ))}
          </Box>
        </Box>
      ))}

      {isLoading && (
        <Box>
          <Skeleton variant='text' width='50%' height={70} />
          <Box sx={trendingPostStyle.main}>
            {Array.from(new Array(4)).map((_, index) => (
              <PostSkeleton key={index} />
            ))}
          </Box>
        </Box>
      )}

      <div ref={bottomRef}></div>
    </Box>
  )
}

export default PostList

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CloseRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import { Modal } from 'antd'
import { useDropzone } from 'react-dropzone'
import uploadicon from '../../../../../Assets/Images/ic_round-upload.png'
import youtubePostModelStyle from './youtubePostModelStyle'
import { AiIconSvgIcon } from '../../../../PasswordStartAdornment'
import FormPostModal from './FormPostModal'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
  youtubeEditVideoApi,
  youtubeGetPlaylistApi,
  youtubeGetPostCategoriesApi,
  youtubeUploadVideoApi,
} from '../../../../../API/PlatformsAPIs/YoutubeApi'
import UserContext from '../../../../../Context/userContext'
import ImageEditModelPintora from './FormPostModal/imageEditModelPintora'

const YoutubePostModel = ({ youtubeModal, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const { userAuth, postData } = useContext(UserContext)
  console.log('postData', postData)
  const [fileUplode, setFileUplode] = useState(postData?.videoUrl || false)
  const [promptField, setPromptField] = useState(postData?.title || '')
  const [descriptionField, setDescriptionField] = useState(
    postData?.description || ''
  )
  const [thumbnail, setThumbnail] = useState(postData?.thumbnailUrl || null)
  const [media, setMedia] = useState([])
  const [playListField, setPlayListField] = useState([])
  const [audience, setAudience] = useState(false)
  const [tags, setTags] = useState(postData?.tags || [])
  const [ageRestriction, setAgeRestriction] = useState(false)
  const [comments, setComments] = useState(true)
  const [category, setCategories] = useState(postData?.categoryName || [])
  const { id } = useParams()
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedPlaylist, setSelectedPlaylist] = useState('')
  const [editModal, setEditModal] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)
  const [videoVisibility, setVideoVisibility] = useState(
    postData?.videoVisibility || 'public'
  )
  console.log('media', media)
  const onDrop = useCallback((acceptedFiles) => {
    console.log('Accepted Files:', acceptedFiles)
    setFileUplode(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'video/mp4': [],
    },
  })

  const theme = useTheme()
  const { register, handleSubmit } = useForm()

  const handleEditPost = async () => {
    console.log('edit')
    setLoading(true)
    try {
      const res = await youtubeEditVideoApi(userAuth, id, {
        title: promptField,
        categoryId: selectedCategory,
        description: descriptionField,
        tags: tags,
        privacyStatus: videoVisibility,
        videoId: postData.videoId,
      })
      console.log('res', res)
    } catch (error) {
      console.log('error', error)
    }
    setLoading(false)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    console.log('initial', data)
    const formData = new FormData()
    media.forEach((mediaItem, index) => {
      console.log(`Appending file ${index}:`, mediaItem.file)
      formData.append(`media`, mediaItem.file)
    })
    try {
      if (data.title) {
        formData.append('title', data.title)
      }
      if (data.description) {
        formData.append('description', data.description)
      }
      if (thumbnail) {
        formData.append('thumbnail', thumbnail.file)
      }
      if (data.playlistId) {
        formData.append('playlistId', data.playlistId)
      }
      formData.append('privacyStatus', videoVisibility)
      formData.append('madeForKids', audience)
      formData.append('ageRestricted', ageRestriction)
      tags.forEach((tag) => formData.append('tags', tag))
      if (selectedCategory) {
        formData.append('categoryId', selectedCategory)
      }
      formData.append('disableComments', comments)

      console.log('formData', formData)
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1])
      }
      const resp = await youtubeUploadVideoApi(userAuth, id, formData)
      console.log('respUploadVideo', resp)
    } catch (error) {
      console.log('error in form', error)
    }
    setLoading(false)
  }

  const fetchCategories = async () => {
    try {
      if (userAuth && id) {
        const resp = await youtubeGetPostCategoriesApi(userAuth, id)
        if (resp && resp.items && Array.isArray(resp.items)) {
          const extractedCategories = resp.items.map((item) => ({
            id: item.id,
            title: item.snippet?.title,
          }))
          setCategories(extractedCategories)
        } else {
          console.error('Invalid response format:', resp)
        }
      }
    } catch (error) {
      console.error('Failed to fetch categories:', error.message)
    }
  }

  const fetchPlaylist = async () => {
    try {
      if (userAuth && id) {
        const resp = await youtubeGetPlaylistApi(userAuth, id)
        if (Array.isArray(resp)) {
          const extractedCategories = resp.map((item) => ({
            id: item.id,
            title: item.snippet?.title,
          }))
          setPlayListField(extractedCategories)
        } else {
          console.error('Invalid response format:', resp)
        }
      }
    } catch (error) {
      console.error('Failed to fetch PlayList:', error.message)
    }
  }

  useEffect(() => {
    const fetchinitialData = async () => {
      fetchCategories()
    }
    fetchinitialData()
    fetchPlaylist()
  }, [userAuth, id])

  return (
    <Modal
      open={youtubeModal}
      onClose={handleClose}
      footer={null}
      centered
      width='fit-content'
      maskClosable={false}
      closable={false}
    >
      <Box sx={youtubePostModelStyle.parent_Box(fileUplode, theme)}>
        {editModal ? (
          <ImageEditModelPintora
            editModal={editModal}
            setEditModal={setEditModal}
            setThumbnail={setThumbnail}
            imageSrc={imageSrc}
          />
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={youtubePostModelStyle.mainBox(fileUplode)}
          >
            {fileUplode ? (
              <FormPostModal
                handleClose={handleClose}
                file={fileUplode}
                promptField={promptField}
                setPromptField={setPromptField}
                descriptionField={descriptionField}
                setDescriptionField={setDescriptionField}
                thumbnail={thumbnail}
                setThumbnail={setThumbnail}
                playListField={playListField}
                setPlayListField={setPlayListField}
                register={register}
                setMedia={setMedia}
                audience={audience}
                setAudience={setAudience}
                tags={tags}
                setTags={setTags}
                ageRestriction={ageRestriction}
                setAgeRestriction={setAgeRestriction}
                category={category}
                setCategories={setCategories}
                comments={comments}
                setComments={setComments}
                setEditModal={setEditModal}
                setImageSrc={setImageSrc}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedPlaylist={selectedPlaylist}
                setSelectedPlaylist={setSelectedPlaylist}
                videoVisibility={videoVisibility}
                setVideoVisibility={setVideoVisibility}
                loading={loading}
                handleEditPost={handleEditPost}
              />
            ) : (
              <>
                {/* Header */}
                <Box sx={youtubePostModelStyle.Box_Header(theme)}>
                  <Typography
                    sx={youtubePostModelStyle.uploadVideos_Text(theme)}
                  >
                    Upload Videos
                  </Typography>
                  <CloseRounded
                    sx={{ cursor: 'pointer', color: theme.palette.text.main }}
                    onClick={handleClose}
                  />
                </Box>
                {/* Center */}
                <Box sx={youtubePostModelStyle.Box_Center}>
                  <img
                    src={uploadicon}
                    alt='Upload Icon'
                    style={youtubePostModelStyle.img_Style}
                  />
                  <Box sx={youtubePostModelStyle.middle_Box}>
                    <Typography
                      sx={youtubePostModelStyle.dragAndDrop_Text(theme)}
                    >
                      Drag and drop video files to upload
                    </Typography>
                    <Typography sx={youtubePostModelStyle.text_Style(theme)}>
                      Your videos will be private until you publish them.
                    </Typography>
                  </Box>
                  <Box sx={youtubePostModelStyle.button_Box}>
                    <Button
                      disabled
                      startIcon={
                        <AiIconSvgIcon
                          sx={{
                            color: theme.palette.customWhite.main,
                            '&.Mui-disabled': {
                              color: theme.palette.text.disable,
                            },
                          }}
                        />
                      }
                      sx={youtubePostModelStyle.genrate_Button(theme)}
                    >
                      Generate
                    </Button>
                    <Button
                      sx={youtubePostModelStyle.uplodee_Button(theme)}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      Upload Video
                    </Button>
                  </Box>
                </Box>
                {/* Footer */}
                <Typography sx={youtubePostModelStyle.footer_Text(theme)}>
                  By submitting your videos to YouTube, you acknowledge that you
                  agree to YouTube's{' '}
                  <span style={{ textDecoration: 'underline' }}>
                    Terms of Service
                  </span>{' '}
                  and{' '}
                  <span style={{ textDecoration: 'underline' }}>
                    Community Guidelines
                  </span>
                  . Please be sure not to violate others' copyright or privacy
                  rights.{' '}
                  <span style={{ textDecoration: 'underline' }}>
                    Learn more
                  </span>
                  .
                </Typography>
              </>
            )}
          </form>
        )}
      </Box>
    </Modal>
  )
}

export default YoutubePostModel
//dddddddd

import { Box, Button, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { ContinueBtn } from '../SignupPage/signupPageStyles'
import welcomePageStyle, {
  openEmail_Btn,
  paragraph_textStyle,
  welcome_textStyle,
} from './welcomePageStyle'
import UserContext from '../../Context/userContext'
import { useTheme } from '@emotion/react'

const WelcomeForm = ({ handleContinue }) => {
  const { currentUser } = useContext(UserContext)
  const fullName = currentUser?.fullName
  const nameParts = fullName ? fullName.split(' ') : []

  const openEmail = () => {
    window.open('https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox', '_blank')
  }
  const theme = useTheme()
  return (
    <form style={welcomePageStyle.stepOne_Form}>
      <Box sx={{ ...welcomePageStyle.welcome_Box }}>
        <Typography sx={welcome_textStyle(theme)}>
          Welcome {nameParts[0]}
        </Typography>
        <Typography sx={paragraph_textStyle(theme)}>
          Almost there, {nameParts[0]}! Verify your email to unleash Spark
          social media magic.
          <Button
            onClick={openEmail}
            variant='text'
            // color='black'
            sx={openEmail_Btn(theme)}
          >
            Open Email
          </Button>
        </Typography>
      </Box>
      <Button
        onClick={() => handleContinue()}
        variant='contained'
        sx={ContinueBtn(theme)}
      >
        Continue
      </Button>
    </form>
  )
}

export default WelcomeForm

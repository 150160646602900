import { Box, IconButton, Typography } from '@mui/material'
import { DeleteOutline } from '@mui/icons-material'
import { useTheme } from '@emotion/react'

const ReferenceMedia = ({ generateMediaButton, media, handleDelete }) => {
  console.log('media', media)
  const theme = useTheme()
  return (
    <Box sx={{ display: 'flex', gap: '8px', height: '98px' }}>
      {media ? (
        <>
          <Box
            sx={{
              display: 'flex',
              width: '167px',
              height: '98px',
              boxSizing: 'border-box',
              flexDirection: 'column',
              padding: '4px',
              borderRadius: '8px',
              visibility: generateMediaButton ? 'visible' : 'hidden',
              border: '1px dashed var(--Text-Text-50, #858585)',
              alignItems: 'start',
              gap: '2px',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '157px',
                height: '64px',
                borderRadius: '5px 5px 2px 2px',
                overflow: 'hidden',
              }}
            >
              {media ? (
                <>
                  <img
                    src={media?.url}
                    alt='uploaded'
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                  <IconButton
                    onClick={handleDelete}
                    sx={{
                      position: 'absolute',
                      top: '4px',
                      right: '4px',
                      boxSizing: 'border-box',
                      padding: '2px',
                      color: '#E34935',
                      '&:hover': { backgroundColor: 'transparent' },
                    }}
                  >
                    <DeleteOutline sx={{ height: '16px', width: '16px' }} />
                  </IconButton>
                </>
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    background: 'gray',
                    borderRadius: '5px 5px 2px 2px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: theme?.typography.h5.fontWeight,
                      fontSize: theme?.typography.h4.fontSize,
                      fontFamily: theme?.typography.h2.fontFamily,
                      color: theme.palette.text.light,
                      lineHeight: '18px',
                    }}
                  >
                    No Media
                  </Typography>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: '4px',
                      right: '4px',
                      boxSizing: 'border-box',
                      padding: '2px',
                      color: '#E34935',
                      '&:hover': { backgroundColor: 'transparent' },
                    }}
                  >
                    <DeleteOutline
                      sx={{
                        height: '16px',
                        width: '16px',
                        color: theme.palette.text.main,
                      }}
                    />
                  </IconButton>
                </Box>
              )}
            </Box>

            <Typography
              sx={{
                width: '157px',
                boxSizing: 'border-box',
                padding: '3px',
                fontSize: theme?.typography.paragraph.fontSize,
                fontWeight: theme?.typography.h5.fontWeight,
                fontFamily: theme?.typography.h2.fontFamily,
                lineHeight: '18px',
                textAlign: 'start',
                color: theme.palette.text.main,
                backgroundColor: '#EAEAEA',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {media ? media.file.name : 'Placeholder.jpg'}
            </Typography>
          </Box>
        </>
      ) : null}
    </Box>
  )
}

export default ReferenceMedia

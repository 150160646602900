const welcomePageStyle = {
  parent_Box: (theme) => ({
    width: '100%',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    boxSizing: 'border-box',
    padding: '20px',
    background: theme.palette.background.default,
  }),
  main_Box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '',
    boxSizing: 'border-box',
    paddingY: '20px',
  },
  formMainBox: {
    width: '400px',
    display: 'flex',
    height: 'fit-content',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '',
    gap: '348px',
  },
  logo_MainBox: {
    width: '100%',
    display: 'flex',
    alignItems: '',
    gap: '4px',
    background: '',
  },

  stepOne_Form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: '',
  },
  title_Box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  field_Box: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '4px',
    mt: '12px',
  },
  devider_MainBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    mt: '16px',
  },
  devider_Style: {
    flexGrow: 1,
    backgroundColor: '#C6C6CE',
  },
  text_Or_Style: {
    color: '#000',
    fontFamily: 'Sora',
    fontSize: '16px',
    fontWeight: 400,
    mx: 2,
  },
  countinue_MainBox: {
    display: 'flex',
    width: '100%',
    background: '',
    alignItems: 'center',
    gap: '4px',
    mt: '16px',
  },
  dontHave_Account: {
    fontFamily: 'Sora',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  select_divStyle: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  welcome_Box: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
    justifyContent: 'center',
    background: '',
  },
  helperText: {
    position: 'absolute',
    bottom: '-20px',
    color: 'red',
  },
  socialBtn_Box: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    mt: '16px',
  },
  buttonStyle: {
    display: 'flex',
    width: '100%',
    height: '44px',
    textTransform: 'capitalize',
    color: '#000',
    fontFamily: 'Sora',
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: '14px',
    border: '1px solid #C6C6CE',
    boxSizing: 'border-box',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    gap: '0px',
    lineHeight: '20px',
  },
  image_Style: { height: '20px', width: '20px' },
  circularProgressBox: {
    position: 'fixed',
    color: 'red',
    top: '45%',
    left: '25%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
  },
  swiper_Style: {
    width: '426px',
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  swiper_SlideStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: 'fit-content',
  },
  swiper_Img_Style: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    borderRadius: '32px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
}
export default welcomePageStyle
export const signOut_Text = (theme) => ({
  lineHeight: '24px',
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  color: theme.palette.customWhite.main,
  fontFamily: theme.typography.h2.fontFamily,
})
export const buttonStyle = (theme) => ({
  ml: 'auto',
  display: 'flex',
  background: theme.palette.button.dark,
  borderRadius: '14px',
  boxSizing: 'border-box',
  padding: '8px 18px',
  gap: '8px',
  textTransform: 'capitalize',
  ':hover': { background: theme.palette.button.dark },
})
export const welcome_textStyle = (theme) => ({
  fontSize: theme.typography.h1.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  color: theme.palette.text.main,
  fontFamily: theme.typography.h2.fontFamily,
  letterSpacing: '-1.6px',
  lineHeight: 'normal',
})
export const paragraph_textStyle = (theme) => ({
  fontStyle: 'normal',
  textAlign: 'center',
  lineHeight: '24px',
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  color: theme.palette.text.light,
  fontFamily: theme.typography.h2.fontFamily,
})
export const openEmail_Btn = (theme) => ({
  fontFamily: theme.typography.h2.fontFamily,
  color: theme.palette.text.light,
  textDecoration: 'underLine',
  boxSizing: 'border-box',
  padding: '0px',
  textTransform: 'capitalize',
  fontSize: theme.typography.h4.fontSize,
  ml: '4px',
  ':hover': {
    background: 'transparent',
    textDecoration: 'underLine',
  },
})
export const latics_Text_Style = (theme) => ({
  lineHeight: 'normal',
  fontSize: theme.typography.headingThree.fontSize,
  fontFamily: theme.typography.h2.fontFamily,
  fontWeight: theme.typography.h2.fontWeight,
  color: theme.palette.text.main,
})

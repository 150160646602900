import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  emailResolver,
  emailValidator,
  roleValidetor,
} from '../../../../Validators/authValidator'
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { AddRounded, CloseRounded } from '@mui/icons-material'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../../../Context/userContext'
import { inviteMemberAPI } from '../../../../API'
import { Loader } from '@infostacks/ui-components'
import CustomSnackbar from '../../../ReusableComponents/customSnackbar'
import styleAddMembersForm, {
  button_Style,
  commonSelectStyle,
  desc_Workspace_Text,
  emailFieldStyles,
  inviteWorkspace_Text,
  labelTextfield,
  role_MenuItem,
} from './styleAddMembersForm'

const AddMembersFormDialog = ({
  open,
  close,
  roles,
  rolesName,
  spaceData,
  renderInviteTable,
  setRenderInviteTable,
}) => {
  const { userAuth, currentUser, setButtonState } = useContext(UserContext)
  // console.log('roles AddMembersFormDialog', roles)
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState(false)
  const theme = useTheme()
  const navigate = useNavigate()
  const {
    reset,
    register,
    handleSubmit,
    formState: { touchedFields, errors },
  } = useForm()

  const onSubmit = async (data) => {
    setLoading(true)
    setApiError(false)
    try {
      const formData = {
        ...data,
        pageName: 'invitation',
        workspaceId: spaceData?._id,
        roleId: (() => {
          const found = roles.find((item) => item.roleName === data.role)
          return found ? found._id : ''
        })(),
      }
      delete formData.role
      delete formData.workspace
      console.log('formData', formData)
      const inviteMemberResponse = await inviteMemberAPI(userAuth, formData)
      // console.log('inviteMemberResponse',inviteMemberResponse);

      // setLoading(false)
      setRenderInviteTable(!renderInviteTable)
      reset()
      if (inviteMemberResponse.status === 201) {
        setApiError(inviteMemberResponse.message)
      } else setApiError(inviteMemberResponse.error)

      close()
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCreateNewRole = () => {
    setButtonState('create')
    window.sessionStorage.setItem('buttonState', 'create')
    window.sessionStorage.removeItem('manageRole')
    navigate('/dashboard/create-role')
  }

  return (
    <>
      {apiError ? <CustomSnackbar status={apiError} /> : null}
      <Modal open={open} sx={styleAddMembersForm.modal_Box(theme)}>
        {loading ? (
          <Box sx={styleAddMembersForm.loader_Box}>
            <Loader thickness={6} sx={{ color: 'blue' }} />
          </Box>
        ) : (
          <Box sx={styleAddMembersForm.parent_Box(theme)}>
            <Box sx={styleAddMembersForm.inviteWorkspace_Box}>
              <Typography sx={inviteWorkspace_Text(theme)}>
                Invite new workspace Member{' '}
              </Typography>
              <IconButton
                sx={{ height: '30px', width: '30px' }}
                onClick={close}
              >
                <CloseRounded sx={{ color: theme.palette.text.main }} />
              </IconButton>
            </Box>
            <Typography sx={desc_Workspace_Text(theme)}>
              An invitation will be sent to users to join the workspace. As an
              administrator, you have the ability to assign roles and manage
              permissions for workspace members, allowing them to perform
              specific operations.
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={styleAddMembersForm.formStyle}
            >
              <Box sx={styleAddMembersForm.textField_Main_Box}>
                <label
                  className='textfield-label'
                  style={labelTextfield(theme)}
                >
                  Email
                </label>
                <div style={{ position: 'relative' }}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    autoComplete='off'
                    {...register('email', emailValidator)}
                    sx={emailFieldStyles(errors.email, theme)}
                  />
                  <FormHelperText sx={styleAddMembersForm.helper_Text}>
                    {errors.email && touchedFields.email
                      ? emailResolver[errors.email.type]
                      : ''}
                  </FormHelperText>
                </div>
              </Box>

              <Box sx={styleAddMembersForm.textField_Main_Box}>
                <label
                  className='textfield-label'
                  style={labelTextfield(theme)}
                >
                  Role
                </label>
                <div style={{ position: 'relative' }}>
                  <Select
                    variant='outlined'
                    fullWidth
                    defaultValue={rolesName[0]}
                    // value={watch('role') || ''}
                    {...register('role', roleValidetor)}
                    sx={{
                      ...commonSelectStyle(errors.role, theme),
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          padding: '6px',
                          borderRadius: '12px',
                          boxSizing: 'border-box',
                          background: theme.palette.background.light,
                          border: `1px solid ${theme.palette.border.extralight}`,
                          '& .MuiMenuItem-root': {
                            color: theme.palette.text.main,
                            border: `1px solid ${theme.palette.border.textfield}`,
                            '&:hover': {
                              background: theme.palette.background.default,
                            },
                            '&:focus': {
                              background: theme.palette.background.default,
                            },
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem
                      sx={role_MenuItem(theme)}
                      onClick={() => handleCreateNewRole()}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '6px',
                          ml: '4px',
                        }}
                      >
                        <AddRounded />
                        Create Role
                      </Box>
                    </MenuItem>
                    {roles?.map(
                      (val, ind) =>
                        val.roleName !== 'Owner' && (
                          <MenuItem
                            sx={role_MenuItem(theme)}
                            key={ind}
                            value={val.roleName}
                          >
                            {val.roleName}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </div>
              </Box>
              <Box sx={styleAddMembersForm.dialogAction}>
                <Button onClick={close} sx={button_Style(theme)}>
                  Cancel
                </Button>
                <Button type='submit' sx={button_Style(theme)}>
                  <AddRounded
                    sx={{
                      color: theme?.palette.customWhite.main,
                      fontSize: { xs: 'medium', sm: 'large' },
                    }}
                  />
                  Invite
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Modal>
    </>
  )
}

export default AddMembersFormDialog

import { Box, Button, IconButton, Menu, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import {
  addRoundedIcon,
  allSpaceBtn,
  favourtBtn,
  seeAllBtn,
  spaceMenu,
  spaceMenuBtnsBox,
  spaceMenuMapBox,
  spaceMenuMapImage,
  spaceMenuMapSpaceName,
} from '../sidebarStyle'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import UserContext from '../../../Context/userContext'
import { useTheme } from '@emotion/react'
import { useLocation, useNavigate } from 'react-router-dom'

const ActiveSpaceMenu = ({
  drawer,
  spaceMenuClose,
  navigation,
  activeButton,
  getAllSpaces,
  getFavoriteSpaces,
  createNewWorkspace,
  spaces,
  spaceAnchorEl,
}) => {
  const {
    activeSpace,
    setActiveSpace,
    setStateCheck,
    stateCheck,
    currentUser,
  } = useContext(UserContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [activeOption, setActiveOption] = useState()
  const address = location.pathname.split('/').slice(0, 3).join('/')
  // console.log('address',address);
  const makeActiveSpace = (val) => {
    console.log('val', val)
    window.sessionStorage.setItem('spaceId', val?._id)
    setStateCheck(!stateCheck)
  }
  const theme = useTheme()
  return (
    <Menu
      id='basic-menu'
      anchorEl={spaceAnchorEl}
      open={Boolean(spaceAnchorEl)}
      onClose={spaceMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      elevation={1}
      PaperProps={{
        sx: spaceMenu(drawer, theme),
      }}
      MenuListProps={{sx:{boxSizing:'border-box',padding:'12px',width:'100%',background:''}}}
    >
      <Box sx={spaceMenuBtnsBox(drawer)}>
        <Button
          onClick={() => getAllSpaces('all')}
          sx={allSpaceBtn(activeButton, theme)}
        >
          All
        </Button>
        <Button
          onClick={() => getFavoriteSpaces('favourite')}
          sx={favourtBtn(activeButton, theme)}
        >
          Favourite
        </Button>
        <IconButton disabled={currentUser?.workspacesCount>=1} onClick={createNewWorkspace} sx={addRoundedIcon(theme)}>
          <AddRoundedIcon sx={{height:'24px',width:'24px'}}/>
        </IconButton>
      </Box>
      {spaces.map(
        (val, ind) =>
          ind < 7 && (
              <Box
              key={ind}
                onClick={() => makeActiveSpace(val)}
                sx={spaceMenuMapBox(theme, val)}
              >
                <img
                  src={val.imageUrl}
                  alt={`Active-space-log`}
                  style={spaceMenuMapImage()}
                />
                <Typography sx={spaceMenuMapSpaceName(drawer, theme,val)}>
                  {val.name}
                </Typography>
              </Box>
          )
      )}
 
    </Menu>
  )
}

export default ActiveSpaceMenu

import { Box, Typography, Grid, Avatar } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Loader } from '@infostacks/ui-components'
import UserContext from '../../../../Context/userContext'
import { getUserProfileAPI } from '../../../../API/AuthAPIs'
import CustomMenu from '../../../ReusableComponents/customMenu'
import CustomMoreVertRoundedButton from '../../../ReusableComponents/customMoreVertRoundedButton'
import { useNavigate } from 'react-router-dom'
import {
  EmailStartAdornment,
  LocationIcon,
  PhoneSvg,
} from '../../../PasswordStartAdornment'
import { useTheme } from '@emotion/react'
import profileStyle from './profileStyle'

const Profile = () => {
  const { userAuth, currentUser, isLoading } = useContext(UserContext)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [anchorEl, setAnchorE1] = useState(null)
  const navigate = useNavigate()
  const menuOpen = (event) => {
    setAnchorE1(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorE1(false)
  }

  const menuItems = [
    {
      label: 'Edit Profile',
      onClick: () => navigate('/dashboard/editprofile'),
    },
  ]
  const theme = useTheme()
  useEffect(() => {
    const getUserProfileData = async () => {
      try {
        if (userAuth && currentUser) {
          const responseOfApi = await getUserProfileAPI(
            userAuth,
            currentUser._id
          )
          const fullName = responseOfApi.user.fullName
          const displayName = fullName.split(' ')
          setFirstName(displayName[0])
          setLastName(displayName[1])
        }
      } catch (error) {
        console.log('Error fetching user profile:', error)
      }
    }
    getUserProfileData()
  }, [userAuth, currentUser])

  if (isLoading) {
    return <Loader thickness={6} sx={{ color: 'blue' }} />
  }
  return (
    <Box sx={profileStyle.parent_Box(theme)}>
      <Box sx={profileStyle.main_Box}>
        <Box sx={profileStyle.image_Box}>
          {currentUser?.imageUrl ? (
            <Avatar
              alt='user profile'
              src={currentUser?.imageUrl}
              style={profileStyle.image_Box}
            />
          ) : (
            <Box sx={profileStyle.firstWord_Box(theme)}>
              {currentUser?.fullName ? currentUser.fullName[0] : ''}
            </Box>
          )}
        </Box>
        <Box sx={profileStyle.infoormation_MainBox}>
          <Box sx={profileStyle.userName_Box}>
            <Typography sx={profileStyle.userName_TextStyle(theme)}>
              {currentUser?.fullName || `${firstName} ${lastName}`}
            </Typography>
            <Typography sx={profileStyle.category_TextStyle(theme)}>
              {currentUser?.category || 'User Category'}
            </Typography>
          </Box>
          <Box sx={profileStyle.middle_Box}>
            <Box sx={profileStyle.boxStyle}>
              <LocationIcon
                style={{
                  color: theme.palette.text.main,
                }}
              />
              <Typography sx={profileStyle.textStyle}>
                {currentUser?.country || 'Country'}
              </Typography>
            </Box>
            <Box sx={profileStyle.boxStyle}>
              <PhoneSvg
                style={{
                  color: theme.palette.text.main,
                }}
              />
              <Typography sx={profileStyle.textStyle}>
                {currentUser?.phoneNumber || 'Phone Number'}
              </Typography>
            </Box>
            <Box sx={profileStyle.boxStyle}>
              <EmailStartAdornment
                style={{
                  color: theme.palette.text.main,
                }}
              />
              <Typography sx={profileStyle.email_TextStyle(theme)}>
                {currentUser?.email || 'Email'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <CustomMoreVertRoundedButton onClick={menuOpen} />
      <CustomMenu
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={handleClose}
        menuItems={menuItems}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </Box>
  )
}

export default Profile

import { companyLogos } from '../constants'

const CompanyLogos = ({ className }) => {
  return (
    <div className={className}>
      <h5 className='tagline mb-6 text-center text-n-1/50'>
        Helping people create beautiful content at
      </h5>
      <ul className='flex'>
        {companyLogos.map((logos, i) => (
          <li
            key={i}
            className='flex items-center justify-center flex-1 h-[8.5rem]'
          >
            <div className='flex  items-center' style={{ gap: '10px' }}>
              <img
                src={logos.logo}
                width={30}
                height={30}
                alt={`Logo of ${logos.name}`}
                style={{
                  objectFit: 'contain',
                }}
              />
              <p className='text-white text-xl font-semibold'>{logos.name}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default CompanyLogos

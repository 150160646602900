const styleUserProfile = {
  main_Box: (theme) => ({
    display: 'flex',
    alignItems: 'flex',
    gap: '18px',
    flexDirection: { xs: 'column', xl: 'row' },
    background: theme.palette.background.default,
    width: '100%',
  }),
  second_Box: (theme) => ({
    height: '248px',
    display: 'flex',
    width: '465px',
    background: theme.palette.background.default,
    flexWrap: 'wrap',
    gap: '16px',
  }),
}
export default styleUserProfile
export const parent_Box = (theme) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'start',
  gap: '16px',
  boxSizing: 'border-box',
  padding: '20px',
  background: theme.palette.background.default,
})
export const recent_ActivitiesText = (theme) => ({
  fontFamily: theme.typography.h3.fontFamily,
  fontSize: theme.typography.mediumText.fontSize,
  fontWeight: theme.typography.h2.fontWeight,
  fontStyle: 'normal',
  lineHeight: '30px',
})
export const activeGoals = {
  parent_Box: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: ' var(--Numbers-Number-8, 16px)',
    border: `1px solid ${theme.palette.border.extralight}`,
    background: theme.palette.background.light,
    boxSizing: 'border-box',
    padding: ' var(--Numbers-Number-8, 16px)',
    width: '220px',
    height: '116px',
  }),
  main_Box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '84px',
    width: 'fit-content',
    background: '',
  },
  circularProgress_Box: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '56px',
    height: '56px',
  },
  circularProgress_Style: (theme) => ({
    color: theme.palette.gray.light,
    position: 'absolute',
    width: '56px',
    height: '56px',
  }),
  percentage_Progress: (theme) => ({
    color: theme.palette.primary.light,
    position: 'absolute',
    width: '56px',
    height: '56px',
  }),
  percentage_Text: (theme) => ({
    position: 'absolute',
    fontSize: theme.typography.paragraph.fontSize,
    color: theme?.palette.text.main,
    lineHeight: '18px',
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h4.fontWeight,
  }),
}
export const textStyle = (theme) => ({
  color: theme?.palette.text.light,
  fontFamily: theme.typography.h2.fontFamily,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  lineHeight: '24px',
})

export const valueTextStyle = (theme) => ({
  color: theme?.palette.text.main,
  fontFamily: theme.typography.h2.fontFamily,
  fontSize: theme.typography.h1.fontSize,
  fontWeight: theme.typography.h4.fontWeight,
  lineHeight: 'normal',
  letterSpacing: '-1.6px',
})
export const completedTaskStyle = {
  parent_Box: (theme) => ({
    border: `1px solid ${theme.palette.border.extralight}`,
    background: theme.palette.background.light,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: ' var(--Numbers-Number-8, 16px)',
    boxSizing: 'border-box',
    padding: ' var(--Numbers-Number-8, 16px);',
    width: '220px',
    height: '116px',
  }),
  main_Box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '84px',
    width: '134px',
  },
  circularProgress_Box: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '56px',
    height: '56px',
  },
  circularProgress_Style: (theme) => ({
    color: theme.palette.gray.light,
    position: 'absolute',
    width: '56px',
    height: '56px',
  }),
  percentage_Progress: (theme) => ({
    color: theme.palette.primary.light,
    position: 'absolute',
    width: '56px',
    height: '56px',
  }),
  percentage_Text: (theme) => ({
    position: 'absolute',
    fontSize: theme.typography.paragraph.fontSize,
    color: theme?.palette.text.main,
    lineHeight: '18px',
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h4.fontWeight,
  }),
}
export const DueTaskStyle = {
  parent_Box: (theme) => ({
    border: `1px solid ${theme.palette.border.extralight}`,
    background: theme.palette.background.light,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: ' var(--Numbers-Number-8, 16px)',
    boxSizing: 'border-box',
    padding: 'var(--Numbers-Number-8, 16px)',
    width: '220px',
    height: '116px',
  }),
  main_Box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '84px',
    width: 'fit-content',
  },
  circularProgress_Box: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '56px',
    height: '56px',
  },
  circularProgress_Style: (theme) => ({
    color: theme.palette.gray.light,
    position: 'absolute',
    width: '56px',
    thickness: 12,
    height: '56px',
  }),
  percentage_Progress: (theme) => ({
    color: theme.palette.primary.light,
    position: 'absolute',
    width: '56px',
    thickness: 12,
    height: '56px',
    // 'circle': {
    //   strokeLinecap: 'round', // Makes edges rounded
    // },
  }),
  percentage_Text: (theme) => ({
    position: 'absolute',
    fontSize: theme.typography.paragraph.fontSize,
    color: theme?.palette.text.main,
    lineHeight: '18px',
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h4.fontWeight,
  }),
}
export const progressStyle = {
  parent_Box: (theme) => ({
    border: `1px solid ${theme.palette.border.extralight}`,
    background: theme.palette.background.light,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: ' var(--Numbers-Number-8, 16px)',
    boxSizing: 'border-box',
    padding: ' var(--Numbers-Number-8, 16px)',
    width: '220px',
    height: '116px',
    position: 'relative',
  }),
  progress_Box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '84px',
    width: 'fit-content',
  },
  percentage_Text: (theme) => ({
    fontSize: theme.typography.h1.fontSize,
    color: theme?.palette.text.main,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h4.fontWeight,
    lineHeight: 'normal',
    letterSpacing: '-1.6px',
  }),
  progress_Text: (theme) => ({
    fontSize: theme.typography.h4.fontSize,
    color: theme?.palette.text.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
    lineHeight: '24px',
  }),
  tooltip_MainBox: {
    position: 'inherit',
    left: '50%',
    top: '-10px',
  },
  tooltip_Label: (theme) => ({
    fontSize: theme.typography.h5.fontSize,
    color: theme?.palette.text.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h5.fontWeight,
  }),
  tooltip_value_Label: (theme) => ({
    fontSize: theme.typography.h4.fontSize,
    color: theme?.palette.success.light,
    fontFamily: theme.typography.h2.fontFamily,
    fontWeight: theme.typography.h4.fontWeight,
  }),
}

import { useTheme } from '@emotion/react'
import { Box, Select, MenuItem, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../../../../../../Context/userContext'

const Category = ({
  category,
  setCategories,
  selectedCategory,
  setSelectedCategory,
  loading,
}) => {
  console.log('selectedCategory', selectedCategory)
  console.log('category',category)
  const theme = useTheme()
  useEffect(() => {
    if (category?.length && !selectedCategory) {
      setSelectedCategory(postData?.categoryId || '') // Set the first category as default
    }
  }, [category, selectedCategory, setSelectedCategory])
  const { postData } = useContext(UserContext)
  return (
    <Box
      sx={{
        width: '220px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 'var(--Numbers-Number-6, 12px)',
      }}
    >
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.main,
            fontFamily: theme?.typography?.h4?.fontFamily,
            fontSize: theme?.typography?.h4?.fontSize,
            fontWeight: theme?.typography?.h4?.fontWeight,
            fontStyle: 'normal',
            lineHeight: '24px',
          }}
        >
          Category
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.light,
            fontFamily: 'Sora',
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px',
            maxWidth: '502px',
          }}
        >
          Learn about{' '}
          <a
            href='https://support.google.com/youtube/answer/2797468?hl=en'
            target='_blank'
            rel='noopener noreferrer'
            style={{ textDecoration: 'underline' }}
          >
            Category types
          </a>
          .
        </Typography>
      </span>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: '6px',
        }}
      >
        <div style={{ position: 'relative' }}>
          <Select
            disabled={loading}
            variant='outlined'
            fullWidth
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            sx={{
              background: theme.palette.background.light,
              borderRadius: '8px',
              height: '42px',
              color: theme?.palette.text.main,
              fontFamily: theme.typography.h2.fontFamily,
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.border.extralight,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.border.extralight,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.border.extralight,
              },
              '& .MuiSelect-icon': {
                color: theme?.palette.text.main,
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  boxSizing: 'border-box',
                  padding: '6px',
                  borderRadius: '12px',
                  borderColor: theme.palette.border.extralight,
                  background: theme.palette.background.light,
                },
              },
            }}
          >
            {category?.map((val, ind) => (
              <MenuItem
                key={ind}
                value={val.id}
                sx={{
                  height: '44px',
                  mb: '6px',
                  borderRadius: '8px',
                  border: `1px solid ${theme.palette.border.extralight}`,
                  color: theme.palette.text.main,
                  fontStyle: 'normal',
                  lineHeight: '24px',
                  fontSize: theme.typography.h4.fontSize,
                  fontWeight: theme.typography.h5.fontWeight,
                  fontFamily: theme.typography.h2.fontFamily,
                  '&:hover': {
                    border: `1px solid ${theme.palette.border.extralight}`,
                    background: theme.palette.background.default,
                  },
                  '&:focus': {
                    border: `1px solid ${theme.palette.border.extralight}`,
                    background: theme.palette.background.default,
                  },
                }}
              >
                {val.title}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Box>
    </Box>
  )
}

export default Category
